import { ArrowLeftOutlined } from '@ant-design/icons';
import { Select, Table } from 'antd';
import React from 'react';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import ButtonComponent from '../../../../components/ButtonComponent';
import DateComponent from '../../../../components/DateComponent';
import ModalCustom from '../../../../components/Modal/ModalCustom';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';
import StatusComponent from '../../../../components/StatusComponent';

const MaintainUser = () => {
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const handleCancel = () => {
        setOpenModal(false);
    }
    const dataSource = [
        {
            no: 1,
            username: "annisa.lestari",
            email: "annisa@pgn.co",
            status: "active",
            auth_type:"LDAP"
        },
        {
            no: 2,
            username: "putri.warobay",
            email: "putri@pgn.co",
            status: "Expired in 30 days",
            auth_type:"LDAP"
        },
        {
            no: 3,
            username: "raul",
            email: "raul@pgn.co",
            status: "active",
            auth_type:"LOKAL"
        },
    ]

    const columns = [
        {
            title: "NO",
            dataIndex: "no",
            key: "no",
        },
        {
            title: "USERNAME",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "EMAIL",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            render: (index) => {
                return (
                    <StatusComponent color={index=== "active" ? 'green' : 'yellow'}>{index}</StatusComponent>
                )
            }
        },
        {
            title: "PHONE NUMBER",
            dataIndex: "auth_type",
            key: "auth_type",
        },
    ]
    return (
        <LayoutMenu>
            <BreadCrumb pageName={['User Management', 'User', 'Change Auth Type']} />
            <NavLink onClick={() => navigate(-1)}>
                <div className="flex align-middle gap-x-2 mb-4">
                    <ArrowLeftOutlined
                        style={{ fontSize: "24px", color: "#3C6DB2" }}
                    ></ArrowLeftOutlined>
                    <span className="text-dg-blue">Back</span>
                </div>
            </NavLink>
            <BaseContainer header={'MAINTAIN CHANGE AUTH'}>
                <div className={'w-full flex flex-col gap-4'} >
                    <div className={"w-full flex justify-end gap-2"}>
                        <span>From</span>
                        <Select defaultValue={'LOKAL'} options={[
                            { value: 'LDAP', label: 'LDAP' },
                            { value: 'LOKAL', label: 'LOKAL' }
                        ]} />
                        <span>To</span>
                        <Select defaultValue={"LDAP"} options={[
                            { value: 'LDAP', label: 'LDAP' },
                            { value: 'LOKAL', label: 'LOKAL' }
                        ]} />
                    </div>
                    <Table dataSource={dataSource} columns={columns} pagination={{position:"bottomCenter"}} />
                    <div className={'w-full flex justify-end'}>
                        <NavLink onClick={() => navigate(-1)}>
                            <ButtonComponent type={"default"}>
                                Cancel
                            </ButtonComponent>
                        </NavLink>
                        <ButtonComponent type={'submit'} onClick={()=> setOpenModal(true)}>Change Auth</ButtonComponent>
                    </div>
                </div>
            </BaseContainer>
            <ModalCustom isOpen={openModal} handleCancel={handleCancel} header={'Change Type Auth'}>
                <div className={"flex flex-col px-8 py-4"}>
                    <div className="w-full justify-center flex my-6">
                        <span className={"text-xl text-[#3C6DB2]"}>
                            {" "}
                            Are you sure want to change type auth?
                        </span>
                    </div>
                    <div className={"w-full justify-center flex"}>
                        <ButtonComponent
                            type={"default"}
                            onClick={() => setOpenModal(false)}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent type={"submit"} >
                            Change
                        </ButtonComponent>
                    </div>
                </div>
            </ModalCustom>      
        </LayoutMenu>
    );
}

export default MaintainUser;
