import { CloseCircleOutlined, DownloadOutlined, EditOutlined, PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Checkbox, PageHeader, Table } from 'antd';
import React, { useState } from 'react';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import ButtonComponent from '../../../../components/ButtonComponent';
import ModalCustom from '../../../../components/Modal/ModalCustom';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';
import StatusComponent from '../../../../components/StatusComponent';

const ApprovalHierarchyPage = () => {
    const [mode, setMode] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = (modePopUp) => {
        setMode(modePopUp);
        setOpenModal(true);
    };
    const handleCancel = () => setOpenModal(false);
    const datas = [
        {
            no: 1,
            approval_code: 'PRM_APPROVAL',
            approval_name: 'PROMO',
            desc: 'Approval for promo',
            status: 'ACTIVE',
        },
        {
            no: 2,
            approval_code: 'SA_APPROVAL',
            approval_name: 'SERVICE AGREEMENT',
            desc: 'Approval for SA',
            status: 'INACTIVE',
        }
    ]

    const columns = [
        // tableNumbering,
        {
            title: "No",
            dataIndex: "no",
            key: "no",
            align: 'center'
        },
        {
            title: "Approval Code",
            dataIndex: "approval_code",
            key: "approval_code",
            align: 'center'
        },
        {
            title: "SApproval Name",
            dataIndex: "approval_name",
            key: "approval_name",
            align: 'center'

        },
        {
            title: "Description",
            dataIndex: "desc",
            key: "desc",
            align: 'center'

        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: 'center',
            render: (index) => {
                return (
                    <StatusComponent color={`${index === 'ACTIVE' ? 'green':'red'}`}>{index }</StatusComponent>
                )
            }
        },
        {
            title: "Activate",
            dataIndex: "activate",
            key: "activate",
            align: 'center',
            render: () => {
                return (
                    <Checkbox></Checkbox>
                )
            }
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            align: 'center',
            render: () => {
                return (
                    <div className={'flex justify-center'}>
                        <ButtonComponent icon={<UnorderedListOutlined/>} onClick={()=>handleOpen('detail')} />
                        <ButtonComponent icon={<EditOutlined/>} />
                        <ButtonComponent icon={<CloseCircleOutlined />} style={{
                            color: "#FF7171",
                            fontSize: 24,
                            justifyItems: "center",
                        }} onClick={() => handleOpen('inactive')} />
                   </div>
                )
            }
        },
    ];
    const routes = [
        {
            path: '',
            breadcrumbName: 'User Management'
        },
        {
            path: '',
            breadcrumbName: 'Approval Hierarchy'
        }
    ]
    return (
        <LayoutMenu>
            <PageHeader breadcrumb={<BreadCrumb routes={routes} />} />
            <BaseContainer header={"View Approval"}>
                {/* <BreadCrumb pageName={['test','test']}/> */}
                <div className={'flex flex-col w-full'}>
                    <div className={'w-full flex justify-end'}>
                        <ButtonComponent icon={<DownloadOutlined/>} type={'submit'}>Download</ButtonComponent>
                        <ButtonComponent icon={<PlusCircleOutlined/>} type={'submit'}>Create New</ButtonComponent>
                    </div>
                    <div>
                        <Table columns={columns} dataSource={datas} />
                    </div>
                </div>
            </BaseContainer>
            <ModalCustom header={`${mode === 'inactive' ? 'INACTIVATE' :'DETAIL'} APPROVAL HIERARCHY`} isOpen={openModal} handleCancel={handleCancel}>
                <div className={"flex flex-col px-8 py-4"}>
                    {
                        mode === 'inactive' ?
                            <>
                                <div className="w-full justify-center flex my-6">
                                    <span className={"text-base text-[#3C6DB2]"}>
                                        {" "}
                                        Are you sure want to inactive approval hierarchy?
                                    </span>
                                </div>
                                <div className={"w-full justify-center flex"}>
                                    <ButtonComponent
                                        type={"default"}
                                        onClick={() => setOpenModal(false)}
                                    >
                                        Cancel
                                    </ButtonComponent>
                                    <ButtonComponent type={"submit"}>
                                        Inactive
                                    </ButtonComponent>
                                </div>
                            </>
                             :
                            <>
                                <span>
                                    ini detail
                                </span>
                            </>
                    }
                </div>
            </ModalCustom>
    </LayoutMenu>
    );
}

export default ApprovalHierarchyPage;
