import React, { useRef } from "react";
import BreadCrumb from "../../../../components/BreadCrumb";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { useSelector, useDispatch } from "react-redux";
import BaseContainer from "../../../../components/BaseContainer";
import { Spin, Table, Checkbox, Tooltip, Input } from "antd";
import ButtonComponent from "../../../../components/ButtonComponent";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import {
	DownloadOutlined,
	PlusOutlined,
	UnorderedListOutlined,
	EditOutlined,
	CloseCircleOutlined,
	FilterOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import StatusComponent from "../../../../components/StatusComponent";
import SVGIcon from "../../../../assets/Icon/index";
import { useState } from "react";
import DetailText from "../../../../components/DetailText";
import moment from "moment";
import { dateFormat } from "../../../../utils";
import Highlighter from "react-highlight-words";
const Menu = () => {
	const [modalInactive, setModalInactive] = useState(false);
	const [modalDetail, setModalDetail] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);
	const [detailRecord, setDetailRecord] = useState({});
	const [deleteRecord, setDeleteRecord] = useState({});

	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	const openDelete = (r) => {
		setDetailRecord(r);
		setModalDelete(true);
	};

	const openDetail = (r) => {
		setDetailRecord(r);
		setModalDetail(true);
	};

	const menuList = [
		{
			createdBy: "SYSTEM",
			updatedBy: "SYSTEM",
			status: "ACTIVE",
			createdDate: "2022-11-14T03:00:00.000+00:00",
			updatedDate: "2022-11-14T03:00:00.000+00:00",
			menuId: 1,
			parentId: null,
			name: "User Management",
			path: null,
			menuOrder: 1,
			iconTag: "AppleOutlined",
			isTopParent: true,
			isPage: false,
			isDeleted: false,
			links: [],
		},
		{
			createdBy: "SYSTEM",
			updatedBy: "SYSTEM",
			status: "INACTIVE",
			createdDate: "2022-11-14T03:00:00.000+00:00",
			updatedDate: "2022-11-14T03:00:00.000+00:00",
			menuId: 2,
			parentId: 1,
			name: "Employee",
			path: "user-management/employee",
			menuOrder: 1,
			iconTag: null,
			isTopParent: false,
			isPage: true,
			isDeleted: false,
			links: [],
		},
		{
			createdBy: "SYSTEM",
			updatedBy: "SYSTEM",
			status: "ACTIVE",
			createdDate: "2022-11-14T03:00:00.000+00:00",
			updatedDate: "2022-11-14T03:00:00.000+00:00",
			menuId: 3,
			parentId: 1,
			name: "User",
			path: "user-management/user",
			menuOrder: 1,
			iconTag: null,
			isTopParent: false,
			isPage: true,
			isDeleted: false,
			links: [],
		},
	];

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
			<div
				style={{
					padding: 8,
				}}
				onKeyDown={(e) => e.stopPropagation()}
			>
				<Input
					ref={searchInput}
					placeholder={`Search`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{
						marginBottom: 8,
						display: "block",
					}}
				/>
			</div>
		),
		filterIcon: (filtered) => (
			<FilterOutlined
				style={{
					color: filtered ? "#1890ff" : undefined,
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{
						backgroundColor: "#ffc069",
						padding: 0,
					}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		// tableNumbering(page),
		{
			title: "MENU NAME",
			dataIndex: "name",
			width: 150,
			...getColumnSearchProps("name"),
		},
		{
			title: "PATH",
			dataIndex: "path",
			width: 240,
		},
		{
			title: "MENU TYPE",
			dataIndex: "isTopParent",
			width: 125,
			...getColumnSearchProps("isTopParent"),
			render: (isTopParent) => (
				<div>
					{isTopParent === true && (
						<StatusComponent color="blue">{"TOP PARENT"}</StatusComponent>
					)}

					{isTopParent === false && (
						<div className="text-center font-bold">{"-"}</div>
					)}
				</div>
			),
		},
		{
			title: "PARENT",
			dataIndex: "parentId",
			width: 125,
		},
		{
			title: "IS PAGE",
			dataIndex: "isPage",
			width: 125,
			...getColumnSearchProps("isPage"),
			render: (isPage) => (
				<div>
					{isPage === true && (
						<StatusComponent color="blue">{"PAGE"}</StatusComponent>
					)}

					{isPage === false && (
						<div className="text-center font-bold">{"-"}</div>
					)}
				</div>
			),
		},
		{
			title: "ACTIVATE",
			dataIndex: "status",
			width: 100,
			render: (active, r, i) => {
				return (
					<div className="flex justify-center">
						{active === "ACTIVE" && (
							<Checkbox
								checked={true}
								// onChange={onChange}
							></Checkbox>
						)}

						{active === "INACTIVE" && (
							<Checkbox
								checked={false}
								// onChange={onChange}
							></Checkbox>
						)}
					</div>
				);
			},
		},
		{
			title: "STATUS",
			dataIndex: "status",
			width: 100,
			...getColumnSearchProps("status"),
			render: (status) => (
				<div>
					{status === "ACTIVE" && (
						<StatusComponent color="green">{status}</StatusComponent>
					)}

					{status === "INACTIVE" && (
						<StatusComponent color="red">{status}</StatusComponent>
					)}
				</div>
			),
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 125,
			render: (v, r, i) => {
				return (
					<div className="flex justify-center gap-4">
						<Tooltip title="Detail">
							<UnorderedListOutlined
								onClick={() => {
									openDetail(r);
								}}
								style={{
									color: "#0880AE",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
						<NavLink to={"/app/system-setup/menu/edit"}>
							<Tooltip title="Edit">
								<div>
									<SVGIcon
										name="IconEdit"
										width={24}
										style={{
											color: "#0880AE",
											fontSize: 24,
											justifyItems: "center",
										}}
									/>
								</div>
							</Tooltip>
						</NavLink>
						<Tooltip title="Delete">
							<CloseCircleOutlined
								onClick={() => {
									openDelete(r);
								}}
								style={{
									color: "#FF7171",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];

	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb pageName={["System Setup", "Menu"]} />
				<BaseContainer header={"MENU LIST"}>
					<div className={"w-full"}>
						<div className="flex w-full justify-end">
							<ButtonComponent
								icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
								type="submit"
							>
								Download List
							</ButtonComponent>
							<NavLink to={"/app/system-setup/menu/create"} state={{ x: 1 }}>
								<ButtonComponent
									icon={<PlusOutlined style={{ fontSize: "24px" }} />}
									type="submit"
								>
									Create New
								</ButtonComponent>
							</NavLink>
						</div>
						<Table
							dataSource={menuList}
							columns={columns}
							size="small"
							pagination={{ position: ["bottomCenter"] }}
						/>
					</div>
				</BaseContainer>
			</Spin>

			{/* MODAL ACTIVE/INACTIVE */}
			<ModalCustom
				isOpen={modalInactive}
				handleCancel={() => setModalInactive(false)}
				header={"INACTIVE MENU"}
				width={800}
			>
				<div className="flex flex-col w-full">
					<h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
						Are you sure you want to inactive menu?
					</h1>

					<div className="flex mt-4 w-full justify-center">
						<ButtonComponent
							// icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
							type={"default"}
							onClick={() => setModalInactive(false)}
						>
							Cancel
						</ButtonComponent>
						<ButtonComponent
							// onClick={() => setModalConfirmation(true)}
							type={"submit"}
						>
							Submit
						</ButtonComponent>
					</div>
				</div>
			</ModalCustom>

			{/* MODAL DETAIL */}
			<ModalCustom
				isOpen={modalDetail}
				handleCancel={() => setModalDetail(false)}
				header={"DETAIL MENU"}
				width={1000}
			>
				<div className="flex flex-col w-full gap-y-10">
					<div className="grid grid-cols-2 gap-y-2.5">
						<DetailText label={"Menu Name"}>{detailRecord.name}</DetailText>
						<DetailText label={"Created At"}>
							{moment(detailRecord.createdDate).format(dateFormat)}
						</DetailText>
						<DetailText label={"Type"}>
							{detailRecord.isTopParent + " | " + detailRecord.isPage}
						</DetailText>
						<DetailText label={"Created By"}>
							{detailRecord.createdBy}
						</DetailText>
						<DetailText label={"Parent Menu"}>
							{detailRecord.parentId}
						</DetailText>
						<DetailText label={"Latest Update"}>
							{moment(detailRecord.updatedDate).format(dateFormat)}
						</DetailText>
						<DetailText label={"Path"}>{detailRecord.path}</DetailText>
						<DetailText label={"Updated By"}>
							{detailRecord.updatedBy}
						</DetailText>
						<DetailText label={"Action"}>
							{/* {detailRecord.updatedBy} */}
						</DetailText>
					</div>
				</div>

				<div className="flex justify-end mt-8">
					<ButtonComponent
						// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
						onClick={() => setModalDetail(false)}
						type="submit"
					>
						Close
					</ButtonComponent>
				</div>
			</ModalCustom>

			{/* MODAL DELETE */}
			<ModalCustom
				isOpen={modalDelete}
				handleCancel={() => setModalDelete(false)}
				header={"DELETE MENU"}
				width={800}
			>
				<div className="flex flex-col w-full">
					<h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
						Are you sure you want to delete menu?
					</h1>

					<div className="flex mt-4 w-full justify-center">
						<ButtonComponent
							// icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
							type={"default"}
							onClick={() => setModalDelete(false)}
						>
							Cancel
						</ButtonComponent>
						<ButtonComponent
							// onClick={() => setModalConfirmation(true)}
							type={"submit"}
						>
							Delete
						</ButtonComponent>
					</div>
				</div>
			</ModalCustom>
		</LayoutMenu>
	);
};

export default Menu;
