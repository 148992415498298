import React from 'react';

const NotFound = () => {
    return (
        <div>
            <hi> Not Found Page</hi>
        </div>
    );
}

export default NotFound;
