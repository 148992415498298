import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ButtonComponent from "../components/ButtonComponent";
import { DownloadOutlined } from "@ant-design/icons";


export const ExportToExcel = ({ apiData, fileName }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <ButtonComponent type={'submit'} icon={<DownloadOutlined />} onClick={(e) => exportToCSV(apiData, fileName)}>Downlaod</ButtonComponent>
    );
};