import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth";
import customerReducer from "../slices/customer";
import messageReducer from "../slices/message";
import entityReducer from "../slices/entity";
import menusReducer from "../slices/menus";
import userReducer from "../slices/user";
import employeeReducer from "../slices/employee";
import generatePasswordReducer from "../slices/generate_password";
import hirarchyReducer from "../slices/hierarchySlice";

const reducer = {
	auth: authReducer,
	customer: customerReducer,
	message: messageReducer,
	entity: entityReducer,
	menus: menusReducer,
	user: userReducer,
	employee: employeeReducer,
	// generate_password : generatePasswordReducer,
	// hierarchy_slice : hirarchyReducer
};

const store = configureStore({
	reducer: reducer,
	devTools: true,
});

export default store;
