import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { tokenHeader } from "../../utils/tokenHeader";
import { configApp } from "../../constants/configApp";


const getUsers = async () => { 
    try {
        const response = await axios.get(`${configApp.USER_MANAGEMENT_SERVICE}/v1/dbs/api/mu/getAll`, { headers: tokenHeader() })
        // console.log(response, ' = success response service')
        return response;
    } catch (error) {
        // console.log(error, ' = error service')
        return error;
    }
}

const getDetailUser =async (id) => {
    try {
        const response = await axios.get(`${configApp.USER_MANAGEMENT_SERVICE}/v1/dbs/api/mu/getUserById/${id}`, { headers: tokenHeader() });
        console.log(id, ' ini idnya')
        return response;
    } catch (error) {
        // console.log(error, ' = error service')
        return error;
    }
};

const inactiveUser = async(id) => { 
    try {
        const response = await axios.delete(configApp.USER_MANAGEMENT_SERVICE + `/v1/dbs/api/mu/inactivateUser/${id}`, { headers: tokenHeader() });
        return response.data;
    } catch (error) {
        // console.log(error, ' = error service')
        return error;
    }
};
const createUser = async (body) => {
    try {
        const response = await axios.post(configApp.USER_MANAGEMENT_SERVICE + "/v1/dbs/api/mu/createNewUser", body, { headers: tokenHeader() });
        console.log(body, ' = body-nya');
        return response.data;
    } catch (error) {
        
        console.log(body, ' = error body ')
        console.log(error, ' = error create ')
        return error;
    }
 };
const deleteUser = () => { };
const updateUser = async (id, body) => { 
    try {
        const response = await axios.put(configApp.USER_MANAGEMENT_SERVICE + `/v1/dbs/api/mu/updateSelectUser/${id}`, body,{ headers: tokenHeader() });
        console.log(response.data, ' = update user service');
        return response.data;
    } catch (error) {
        console.log(error, ' = error update');
        return error
    }
};
const filterUser = () => { };
const downloadExcel = async() => { 
    try {
        const response = await axios.get(configApp.USER_MANAGEMENT_SERVICE + '/v1/dbs/api/mu/downloadExcel', { headers: tokenHeader() });
        return response;
    } catch (error) {
        return error;
    }
};

const getAllUserPaginate = async(page, pageSize) => {
    try {
        const response =await axios.get(configApp.USER_MANAGEMENT_SERVICE + `/v1/dbs/api/mu/viewPaging?page=${page}&size=${pageSize}`, { headers: tokenHeader() });
        // console.log(pageSize, ' =page sizenya')
        return response.data;
    } catch (error) {
        console.log(error, ' = error service');
        return error;
    }
};

const uploadUser = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    try {
        const token = JSON.parse(localStorage.getItem('token')) || JSON.parse(window.sessionStorage.getItem('token'))
        const response = await axios.post(configApp.USER_MANAGEMENT_SERVICE + `/v1/dbs/api/mu/uploadExcel`, formData,  {
            headers: {
                "Authorization": token?.token?.access_token,
                "Content-Type":"multipart/form-data"
            },
        });
        console.log(response, " = success upload service");
        return response;
    } catch (e) {
        console.log(e, ' = error upload service')
        return e;
    }
}
const userService = {
    getUsers,
    getDetailUser,
    createUser,
    deleteUser,
    updateUser,
    filterUser,
    inactiveUser,
    downloadExcel,
    getAllUserPaginate,
    uploadUser
}


export default userService;

