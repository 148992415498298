const BASE_URL = process.env.REACT_APP_AGIT_SERVER || process.env.REACT_APP_PGN_SERVER;
// const BASE_URL = process.env.REACT_APP_LOCAL

// export constant app service
export const configApp = {
	// USER_MANAGEMENT_SERVICE: `${BASE_URL}:8903`,
	// PRODUCT_SERVICE: `${BASE_URL}:8901`,
	// RATING_BILLING_SERVICE: `${BASE_URL}:8906`,
	// ACCOUNT_SERVICE: `${BASE_URL}:8900`,
	// INVOICE_PACKAGE_SERVICE:`${BASE_URL}:8901`,
	// WORKFLOW_SERVICE: `${BASE_URL}:8908`,
	// SYSTEM_SERVICE: `${BASE_URL}:8907`,
	// FAKTUR_SERVICE: `${BASE_URL}:8906`,
	// URL_PDF_SERVICE: `${BASE_URL}:10098`,
	// UPLOAD_DOCUMENT_SERVICE: `${BASE_URL}:8908`,
	// PAYMENT_SERVICE: `${BASE_URL}:8911`,
	// INTEGRATION_SERVICE: `${BASE_URL}:8912`,
	// JP_SERVICE:`${BASE_URL}:8909`,

	// using nginx
	// USER_MANAGEMENT_SERVICE: `/service/usermanagement`,
	// USER_MANAGEMENT_SERVICE: `/um/um`,
	USER_MANAGEMENT_SERVICE: `/um`,
	// USER_MANAGEMENT_SERVICE: `${BASE_URL}:8903/um`,
	PRODUCT_SERVICE: `/service/product`,
	RATING_BILLING_SERVICE: `/service/rating`,
	ACCOUNT_SERVICE: `/service/account`,
	INVOICE_PACKAGE_SERVICE: `/service/invoice`,
	WORKFLOW_SERVICE: `/service/workflow`,
	SYSTEM_SERVICE: `/service/system`,
	FAKTUR_SERVICE: `/service/faktur`,
	URL_PDF_SERVICE: `/service/pdf`,
	UPLOAD_DOCUMENT_SERVICE: `/service/upload`,
	PAYMENT_SERVICE: `/service/payment`,
	INTEGRATION_SERVICE: `/service/integration`,
	JP_SERVICE: `/service/jp`,
};
