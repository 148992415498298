import { Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import { pgnLogo, pgnLogoKecil } from "../../../assets/img";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getListPosition, logout } from "../../../redux/slices/auth";
import ButtonComponent from "../../../components/ButtonComponent";
import { clearMessage } from "../../../redux/slices/message";
import ModalCustom from "../../../components/Modal/ModalCustom";
import { useDispatch, useSelector } from "react-redux";
import SVGPosition from "../../../assets/Icon/index";
import { USER_ROUTES } from "../../../routes/user_management/user_routes";

const Position = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { positions, user, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getListPosition());
  }, []);

  console.log("List Position: ", positions);
  console.log("User : ", user);
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  // handle logout
  const handleLogoutModal = () => {
    setOpenModal(true);
  };

  const handleCancel = () => {
    setOpenModal(false);
    // setIsTimedout(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      window.location.reload();
      return navigate("/");
    } catch (error) {
      return console.log("uhuy");
    }
  };

  const positionList = [
    {
      positionName: "CM Bogor",
    },
    {
      positionName: "CM Tangerang",
    },
    {
      positionName: "CM Bandung",
    },
    {
      positionName: "CM Makasar",
    },
    {
      positionName: "CM Jakarta",
    },
    {
      positionName: "CM Lampung",
    },
    {
      positionName: "CM Lampung",
    },
  ];

  const successSend = () => {
    navigate("/dashboard");
    // try something
  };
  return (
    // <div className={'text-2xl text-blue-800 font-bold'}>
    //     <span> Hello Test Page</span>
    // </div>
    <div className="w-screen h-screen overflow-hidden bg-dg-blue">
      <div className="flex justify-start items-start p-8">
        <NavLink
          onClick={() => {
            handleLogoutModal();
          }}
        >
          <div className="flex align-middle gap-x-2 mb-4">
            <ArrowLeftOutlined
              style={{ fontSize: "24px", color: "#FFFFFF" }}
            ></ArrowLeftOutlined>
            <span className="text-white">Logout</span>
          </div>
        </NavLink>
      </div>

      <div className="flex flex-col items-center justify-center ">
        <h1 className="text-white text-5xl text-center py-8 ">
          Choose Position
        </h1>
        <div className="flex items-center  justify-center">
          <div className="snap-x snap-start flex overflow-auto p-4 w-7/12">
            <Space>
              <div className="snap-center scroll-auto flex space-x-12">
                {positionList.map((x) => (
                  <Card style={{ width: 250, borderRadius: "30px" }}>
                    <div className={"w-full"}>
                      {/* header card */}
                      <div className="flex flex-col justify-center items-center pt-8">
                        <SVGPosition
                          name="IconSwapPosition"
                          width={81}
                          style={{
                            //  color: "#0880AE",
                            fontSize: 24,
                            justifyItems: "center",
                          }}
                        />
                      </div>

                      <div
                        className={
                          "flex flex-col justify-center items-center w-full px-11 mt-8"
                        }
                      >
                        <h2 className="text-base tracking-tight dark:text-[#3C6DB2] ">
                          {x.positionName}
                        </h2>
                      </div>

                      {/* footer card*/}
                      <div className="flex items-center justify-center px-4 py-4">
                        <button
                          type="submit"
                          onClick={successSend}
                          className={
                            "bg-[#3C6DB2] hover:bg-[#3663a2] text-white text-sm px-4 py-4 border rounded-lg w-auto border-none cursor-pointer"
                          }
                        >
                          <span>Choose</span>
                        </button>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </Space>
          </div>
        </div>
      </div>
      <ModalCustom
        isOpen={openModal}
        handleCancel={handleCancel}
        header={"LOGOUT"}
        handleOk={handleLogout}
        isAlert={true}
      >
        <div className={"flex flex-col px-8 py-4"}>
          <div className="w-full justify-center flex my-6">
            <span className={"text-xl text-[#3C6DB2]"}>
              {" "}
              Are you sure want to log out?
            </span>
          </div>
          <div className={"w-full justify-center flex"}>
            <ButtonComponent
              type={"default"}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent type={"submit"} onClick={handleLogout}>
              Logout
            </ButtonComponent>
          </div>
        </div>
      </ModalCustom>
    </div>
  );
};

export default Position;
