
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import ButtonComponent from '../../../../components/ButtonComponent';
import DetailText from '../../../../components/DetailText';
import InputComponent from '../../../../components/InputComponent';
import ModalCustom from '../../../../components/Modal/ModalCustom';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';
import { createUser, getDetailUser, updateUser } from '../../../../redux/slices/user';
import { USER_ROUTES } from '../../../../routes/user_management/user_routes';

const UserForm = (props) => {
    const { id, type } = props;
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.user);
    const [openModal, setOpenModal] = useState(false);
    const dataSource = data?.data;
    const [employeeId, setEmployeeId] = useState('EMP00005');
    const [username, setUsername] = useState('');
    const [userType, setUserType] = useState(1);
    const [email, setEmail] = useState('');
    const [authType, setAuthType] = useState(2);
    const [firstName, setFirstName] = useState('Bambang');
    const [lastName, setLastName] = useState('Sudjarno');
    const [password, setPassword] = useState('123');
    const [phone, setPhone] = useState('');
    const [startDateUser, setStartDateUser] = useState(moment().format("2022-12-24 HH:mm:ss"));
    const [endDateUser, setEndDateUser] = useState(moment().format("2022-12-30 HH:mm:ss"));
    const [gaId, setGaId] = useState(1);
    const [startDateUserGA, setStartDateUserGA] = useState(moment().format("2022-12-24 HH:mm:ss"));
    const [endDateUserGA, setEndDateUserGA] = useState(moment().format("2022-12-30 HH:mm:ss"));

    useEffect(() => {
        if (type === "update") {
            dispatch(getDetailUser(location.state.id));
        }
    }, []);

    console.log(dataSource, ' = ini data source')
    const handleCancel = () => {
        setOpenModal(false)
    }

    const routes = [
        {
            path: "",
            breadcrumbName: "User Management"
        },
        {
            path: USER_ROUTES.VIEW_USER,
            breadcrumbName: "List User"
        },
        {
            path: "",
            breadcrumbName: type === "update" ? "Update User" : "Create User"
        },
    ]

    const handleSave = async() => {
        const body = {
            "employeeId": employeeId,
            "username": username,
            "userType": userType,
            "email": email,
            "phone": phone,
            "authType": authType,
            "firstName": firstName,
            "lastName": lastName,
            "password": password,
            "startDateUser": startDateUser,
            "endDateUser": endDateUser,
            "gaId": gaId,
            "startDateUserGA":startDateUserGA,
            "endDateUserGA": endDateUserGA
        }
        try {
            // await alert('blabla')
            if (type === "update") {
                await dispatch(updateUser(location.state.id, body))
            } else {
                await dispatch(createUser(body));
                navigate(USER_ROUTES.VIEW_USER)
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <LayoutMenu>
            <div className={"flex flex-col "}>
                <BreadCrumb routes={routes} />
                <NavLink onClick={() => navigate(-1)}>
                    <div className="flex align-middle gap-x-2 mb-4">
                        <ArrowLeftOutlined
                            style={{ fontSize: "24px", color: "#3C6DB2" }}
                        ></ArrowLeftOutlined>
                        <span className="text-dg-blue">Back</span>
                    </div>
                </NavLink>
                <Form layout={'vertical'}>
                    <BaseContainer header={type === 'update' ? 'UPDATE USER' : 'CREATE USER'}>
                        <div className='w-full flex flex-col'>
                            <div className={'w-full flex flex-col gap-4'}>
                                {type !== 'update' &&
                                    <div className={"w-1/4"}>
                                        <Form.Item label={'Create From'}>
                                            <Select onChange={(e) => console.log(e.value)}>
                                                <Select.Option value="demo">Demo </Select.Option>
                                                <Select.Option value="demo2">Demo2</Select.Option>
                                                <Select.Option value="demo3">Demo 3</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                }
                                <div className={'flex w-full gap-5'}>
                                    <div className='flex flex-col w-full'>
                                        <Form.Item label={'Employee'}>
                                            <Select>
                                                <Select.Option value="demo">Demo </Select.Option>
                                                <Select.Option value="demo2">Demo2</Select.Option>
                                                <Select.Option value="demo3">Demo 3</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label={'Email'}>
                                            <InputComponent mandatory onChange={(e) => setEmail(e.target.value)} />
                                        </Form.Item>
                                        <Form.Item label={'Start Date'}>
                                            <InputComponent mandatory />
                                        </Form.Item>

                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <Form.Item label={'Username'} rules={[{ required: true }]}>
                                            <InputComponent mandatory onChange={(e) => setUsername(`62${e.target.value}`)} />
                                        </Form.Item>
                                        <Form.Item label={'Phone Number'}>
                                            <InputComponent mandatory onChange={(e) => setPhone(`62${e.target.value}`)} />
                                        </Form.Item>
                                        <Form.Item label={'End Date'}>
                                            <InputComponent mandatory />
                                        </Form.Item>
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <Form.Item label={'User Level'}>
                                            <Select>
                                                <Select.Option value="demo">Demo </Select.Option>
                                                <Select.Option value="demo2">Demo2</Select.Option>
                                                <Select.Option value="demo3">Demo 3</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label={'Auth Type'}>
                                            <InputComponent mandatory />
                                        </Form.Item>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </BaseContainer>
                    <BaseContainer header={'GROUP ACCESS USER'}>
                        <div className='w-full flex flex-col'>
                            <div className={'w-full flex flex-col gap-4'}>
                                <div className={"w-1/4"}>
                                    <Form.Item label={'Group Access'}>
                                        <Select>
                                            <Select.Option value="demo">Demo </Select.Option>
                                            <Select.Option value="demo2">Demo2</Select.Option>
                                            <Select.Option value="demo3">Demo 3</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className={'w-2/4 flex gap-3'}>
                                    <Form.Item label={'Employee'} className={'w-full'}>
                                        <Select>
                                            <Select.Option value="demo">Demo </Select.Option>
                                            <Select.Option value="demo2">Demo2</Select.Option>
                                            <Select.Option value="demo3">Demo 3</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'Email'} className={'w-full'}>
                                        <InputComponent mandatory />
                                    </Form.Item>
                                </div>
                                <div className={'w-full justify-end flex'}>
                                    <ButtonComponent icon={<DeleteOutlined />}> Delete</ButtonComponent>
                                    <ButtonComponent type={'submit'} onClick={() => setOpenModal(true)}> Save</ButtonComponent>
                                </div>
                            </div>
                        </div>
                    </BaseContainer>
                </Form>
            </div>
            <ModalCustom isOpen={openModal} handleCancel={handleCancel} header={'CONFIRMATION'} width={900}>
                <div className={'w-full flex flex-col'}>
                    <div className={'w-full flex flex-col h-[20vh] flex-wrap gap-y-3'}>
                        <DetailText label={'Employee Id'}>{employeeId }</DetailText>
                        <DetailText label={'Username'}>{username }</DetailText>
                        <DetailText label={'User Type'}>{userType }</DetailText>
                        <DetailText label={'Email'}>{email }</DetailText>
                        <DetailText label={'Phone'}>{phone }</DetailText>
                        <DetailText label={'Auth Type'}>{authType }</DetailText>
                        <DetailText label={'First Name'}>{firstName }</DetailText>
                        <DetailText label={'Last Name'}>{lastName }</DetailText>
                        <DetailText label={'Password'}>{password }</DetailText>
                        <DetailText label={'Start Date User'}>{endDateUser }</DetailText>
                        <DetailText label={'Start Date User'}>{startDateUser }</DetailText>
                        <DetailText label={'GA Id'}>{gaId }</DetailText>
                        <DetailText label={'Start Date User GA'}>{startDateUserGA }</DetailText>
                        <DetailText label={'End Date User GA'}>{endDateUserGA }</DetailText>
                    </div>
                    <div className={'flex w-full justify-end'}>
                        <ButtonComponent>Cancel</ButtonComponent>
                        <ButtonComponent type={'submit'} onClick={handleSave}>Submit</ButtonComponent>
                    </div>
                </div>
            </ModalCustom>
        </LayoutMenu>
    );
}

export default UserForm;
