import {
  Dropdown,
  Menu,
  Tag,
  Tooltip,
  Table,
  Radio,
  Select,
  Input,
  Alert,
  Carousel,
} from "antd";
import React, { useEffect } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import LayoutMenu from "../../components/SidebarMenu/LayoutMenu";
import { useDispatch, useSelector } from "react-redux";
import { getListCustomer } from "../../redux/slices/customer";
import { useState } from "react";
import { openNotification, tableNumbering } from "../../utils";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  MoreOutlined,
  PlusCircleFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../components/ButtonComponent";
import RadioTabs from "../../components/RadioTabs";
import InputComponent from "../../components/InputComponent";
import BaseContainer from "../../components/BaseContainer";
import SelectComponent from "../../components/SelectComponent";
import SVG from "../../assets/Icon/index";
import { NumericFormat } from "react-number-format";
import { SketchPicker } from "react-color";
import moment from "moment";
import { dateFormat } from "../../utils/index";
import { NavLink } from "react-router-dom";
import { USER_ROUTES } from "../../routes/user_management/user_routes";
import ModalCustom from "../../components/Modal/ModalCustom";
import CardComponent from "../../components/Card/CardComponent";
import IconButtonReset from "../../assets/Icon/IconButtonReset";
import DetailText from "../../components/DetailText";
import StatusComponent from "../../components/StatusComponent";

const Dashboard = () => {
  const { list } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [name, setName] = useState("John Doe");
  const [remark, setRemark] = useState("Remark");
  const [number, setNumber] = useState(1000);
  const [select, setSelect] = useState(1);

  // const [cc, setCC] = useState("#fff");

  // const [modal, setModal] = useState(false);
  // const [data, setData] = useState([]);
  // const [totalData, setTotalData] = useState(50);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [filteredInfo, setFilteredInfo] = useState({});
  // const [sortedInfo, setSortedInfo] = useState({});
  // const handleChange = (pagination, filters, sorter) => {
  //   console.log("Various parameters", pagination, filters, sorter);
  //   setFilteredInfo(filters);
  //   setSortedInfo(sorter);
  // };
  // const onSelectChange = (newSelectedRowKeys) => {
  //   console.log("selectedRowKeys changed: ", newSelectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  // const changeColor = (x) => {
  //   document.querySelector(":root").style.setProperty("--primary", cc);
  // };
  // const resetColor = () => {
  //   document.querySelector(":root").style.setProperty("--primary", "#2F87A5");
  // };
  // const hasSelected = selectedRowKeys.length > 0;
  // const menu = (
  //   <Menu>
  //     <Menu.Item>
  //       <div className="flex gap-x-2">
  //         <UnorderedListOutlined
  //           style={{
  //             color: "#0880AE",
  //             fontSize: 16,
  //             justifyItems: "center",
  //           }}
  //         />
  //         <p className="text-xs">See Detail</p>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="flex gap-x-2">
  //         <SVG
  //           name="IconEdit"
  //           width={16}
  //           style={{
  //             color: "#0880AE",
  //             fontSize: 16,
  //             justifyItems: "center",
  //           }}
  //         />
  //         <p className="text-xs">Edit</p>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="flex gap-x-2">
  //         <SVG
  //           name="IconLogHistory"
  //           width={16}
  //           style={{
  //             color: "#0880AE",
  //             fontSize: 16,
  //             justifyItems: "center",
  //           }}
  //         />
  //         <p className="text-xs">Log History</p>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="flex gap-x-2">
  //         <SVG
  //           name="IconCheck"
  //           width={16}
  //           style={{
  //             color: "#0880AE",
  //             fontSize: 16,
  //             justifyItems: "center",
  //           }}
  //         />
  //         <p className="text-xs">Request Approval</p>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="flex gap-x-2">
  //         <CloseCircleOutlined
  //           // onClick={() => {
  //           // 	setModalInactive(true);
  //           // }}
  //           style={{
  //             color: "#FF7171",
  //             fontSize: 16,
  //             justifyItems: "center",
  //           }}
  //         />
  //         <p className="text-xs">Inactive</p>
  //       </div>
  //     </Menu.Item>
  //   </Menu>
  // );
  useEffect(() => {
    let array = [];
    for (let i = 0; i < totalData; i++) {
      const record = {
        key: i + 1,
        name: `Herminia_${i + 1}`,
        email: "Herminia47@hotmail.com",
        date: "2022-12-23T07:13:12.647+00:00",
        rupiah: 1300.69,
        salary: 150000.25,
        status: "Current",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      };
      array.push(record);
    }
    setData(array);
  }, []);
  const [cc, setCC] = useState("#fff");
  const [openAlert, setOpenAlert] = useState(false);
  const [modal, setModal] = useState(false);
  const [x, setX] = useState("");

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(50);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const changeColor = (x) => {
    document.querySelector(":root").style.setProperty("--primary", cc);
  };
  const resetColor = () => {
    document.querySelector(":root").style.setProperty("--primary", "#2F87A5");
  };
  const hasSelected = selectedRowKeys.length > 0;
  const menu = (
    <Menu>
      <Menu.Item>
        <div className="flex gap-x-2">
          <UnorderedListOutlined
            style={{
              color: "#0880AE",
              fontSize: 16,
              justifyItems: "center",
            }}
          />
          <p className="text-xs">See Detail</p>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex gap-x-2">
          <SVG
            name="IconEdit"
            width={16}
            style={{
              color: "#0880AE",
              fontSize: 16,
              justifyItems: "center",
            }}
          />
          <p className="text-xs">Edit</p>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex gap-x-2">
          <SVG
            name="IconLogHistory"
            width={16}
            style={{
              color: "#0880AE",
              fontSize: 16,
              justifyItems: "center",
            }}
          />
          <p className="text-xs">Log History</p>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex gap-x-2">
          <SVG
            name="IconCheck"
            width={16}
            style={{
              color: "#0880AE",
              fontSize: 16,
              justifyItems: "center",
            }}
          />
          <p className="text-xs">Request Approval</p>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex gap-x-2">
          <CloseCircleOutlined
            // onClick={() => {
            // 	setModalInactive(true);
            // }}
            style={{
              color: "#FF7171",
              fontSize: 16,
              justifyItems: "center",
            }}
          />
          <p className="text-xs">Inactive</p>
        </div>
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    let array = [];
    for (let i = 0; i < totalData; i++) {
      const record = {
        key: i + 1,
        name: `Herminia_${i + 1}`,
        email: "Herminia47@hotmail.com",
        date: "2022-12-23T07:13:12.647+00:00",
        rupiah: 1300.69,
        salary: 150000.25,
        status: "Current",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      };
      array.push(record);
    }
    setData(array);
  }, []);

  const TABLE = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      filters: [
        {
          text: "10",
          value: "10",
        },
        {
          text: "16",
          value: "16",
        },
      ],
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      filterSearch: true,
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "DATE",
      dataIndex: "date",
      align: "center",
      render: (v) => (v ? moment(v).format(dateFormat) : v),
    },
    {
      title: "SALARY",
      align: "right",
      dataIndex: "salary",
      render: (v) => (
        <NumericFormat
          value={v}
          prefix={"$"}
          displayType="text"
          thousandSeparator=","
        ></NumericFormat>
      ),
    },
    {
      title: "STATUS",
      align: "center",
      dataIndex: "status",
      render: (v) => (
        <Tag
          color="#C2DEF0"
          style={{
            borderRadius: "4px",
          }}
        >
          {v}
        </Tag>
      ),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
    },
    {
      title: "ACTIONS",
      width: 100,
      render: () => (
        <div className="flex justify-center gap-x-2">
          <Dropdown overlay={menu}>
            <a>
              <MoreOutlined
                style={{
                  color: "#0880AE",
                  fontSize: 22,
                  justifyItems: "center",
                }}
              ></MoreOutlined>
            </a>
          </Dropdown>
          <DeleteOutlined
            style={{
              color: "#CE4955",
              fontSize: 24,
              justifyItems: "center",
            }}
          ></DeleteOutlined>
        </div>
      ),
    },
  ];

  const TABLE_CHECKBOX = [...TABLE];

  const TABLE_INLINE = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      filters: [
        {
          text: "10",
          value: "10",
        },
        {
          text: "16",
          value: "16",
        },
      ],
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      filterSearch: true,
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      render: (v) => <InputComponent type="text" value={v}></InputComponent>,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      render: (v) => <InputComponent type="text" value={v}></InputComponent>,
    },
    {
      title: "DATE",
      dataIndex: "date",
      align: "center",
      render: (v) => <InputComponent type="text" value={v}></InputComponent>,
    },
    {
      title: "SALARY",
      dataIndex: "salary",
      render: (v) => <InputComponent type="number" value={v}></InputComponent>,
    },
    {
      title: "STATUS",
      align: "center",
      dataIndex: "status",
      render: (v) => (
        <Tag
          color="#C2DEF0"
          style={{
            borderRadius: "4px",
          }}
        >
          {v}
        </Tag>
      ),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      render: (v) => (
        <InputComponent type="textarea" value={v}></InputComponent>
      ),
    },
    {
      title: "ACTIONS",
      render: () => (
        <div className="flex justify-center gap-x-2">
          <Dropdown overlay={menu}>
            <a>
              <MoreOutlined
                style={{
                  color: "#0880AE",
                  fontSize: 22,
                  justifyItems: "center",
                }}
              ></MoreOutlined>
            </a>
          </Dropdown>
          <Tooltip title="Delete">
            <DeleteOutlined
              style={{
                color: "#CE4955",
                fontSize: 24,
                justifyItems: "center",
              }}
            ></DeleteOutlined>
          </Tooltip>
        </div>
      ),
    },
  ];

  const TABLE_SCROLLING = [...TABLE];

  const TABLE_INVOICE = [
    {
      title: "TOTAL",
      dataIndex: "total",
      key: "total",
      align: "right",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "ISSUED DATE",
      dataIndex: "issued_date",
      key: "issued_date",
      align: "center",
      sorter: (a, b) => a.issued_date - b.issued_date,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: () => {
        return (
          <div className="flex justify-center gap-x-2">
            <Dropdown overlay={menu}>
              <a>
                <MoreOutlined
                  style={{
                    color: "#0880AE",
                    fontSize: 22,
                    justifyItems: "center",
                  }}
                ></MoreOutlined>
              </a>
            </Dropdown>
            <Tooltip title="Delete">
              <DeleteOutlined
                style={{
                  color: "#FF7171",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              ></DeleteOutlined>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const TABLE_CHILDREN = [
    {
      title: "MINIMUM",
      dataIndex: "minimum",
      key: "minimum",
      align: "center",
    },
    {
      title: "MAXIMUM",
      dataIndex: "maximum",
      key: "maximum",
      align: "center",
    },
    {
      title: "PRICE CODE",
      dataIndex: "price_code",
      key: "price_code",
      align: "center",
    },
    {
      title: "PRICE DETAIL",
      children: [
        {
          title: "VALUE",
          dataIndex: "value",
          key: "value",
          align: "center",
        },
        {
          title: "CURRENCY",
          dataIndex: "currency",
          key: "currency",
          align: "center",
        },
        {
          title: "UOM",
          dataIndex: "uom",
          key: "uom",
          align: "center",
        },
      ],
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: () => {
        return (
          <div className="flex justify-center gap-x-2">
            <Dropdown overlay={menu}>
              <a>
                <MoreOutlined
                  style={{
                    color: "#0880AE",
                    fontSize: 22,
                    justifyItems: "center",
                  }}
                ></MoreOutlined>
              </a>
            </Dropdown>
            <Tooltip title="Delete">
              <DeleteOutlined
                style={{
                  color: "#CE4955",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              ></DeleteOutlined>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const dataInvoice = [
    {
      key: 1,
      total: 100000,
      issued_date: "12 Jan 2022 23:11:09",
    },
    {
      key: 2,
      total: 250000,
      issued_date: "12 Jan 2022 23:11:09",
    },
    {
      key: 3,
      total: 50000,
      issued_date: "12 Jan 2022 23:11:09",
    },
  ];

  const dataChildren = [
    {
      minimum: 0,
      maximum: 3,
      price_code: "PC001",
      value: "RP 4250",
      currency: "IDR",
      uom: "M3",
      description: "Blablablabla dsadadasd",
    },
    {
      minimum: 2,
      maximum: 3,
      price_code: "PC002",
      value: "RP 4250",
      currency: "IDR",
      uom: "M3",
      description: "Blablablabla udang udang udang",
    },
    {
      minimum: 1,
      maximum: 10,
      price_code: "PC003",
      value: "RP 4250",
      currency: "IDR",
      uom: "M3",
      description: "Transaksi Gelap",
    },
    {
      minimum: 0,
      maximum: 10,
      price_code: "PC004",
      value: "RP 4250",
      currency: "IDR",
      uom: "M3",
      description: "Ga ada apa2",
    },
  ];

  const optionsWithDisabled = [
    { label: "Active", value: "active" },
    { label: "Longer nav link ", value: "longer" },
    { label: "Links", value: "links" },
    { label: "Disabled", value: "disabled", disabled: true },
  ];

  const [value1, setValue1] = useState("active");

  return (
    <LayoutMenu>
      <BreadCrumb pageName={["testing", "testing"]} />
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        onClick={() => setModal(true)}
        type="submit"
      >
        Open Modal
      </ButtonComponent>
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        onClick={() => openNotification()}
        type="submit"
      >
        Notification
      </ButtonComponent>
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        onClick={() => setModal(true)}
        type="reject"
      >
        Reject
      </ButtonComponent>
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        onClick={() => setModal(true)}
        type="approve"
      >
        Approve
      </ButtonComponent>
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        onClick={() => setModal(true)}
        type="submit"
      >
        Submit
      </ButtonComponent>
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        onClick={() => setModal(true)}
        type="default"
      >
        Default
      </ButtonComponent>
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        icon={<IconButtonReset></IconButtonReset>}
        onClick={() => setModal(true)}
        type="submit"
      >
        Reset
      </ButtonComponent>
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        onClick={() => setOpenAlert(true)}
        type="submit"
      >
        Open Alert
      </ButtonComponent>
      <ButtonComponent
        // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
        onClick={() => openNotification()}
        type="submit"
      >
        Notification
      </ButtonComponent>

      <RadioTabs
        options={optionsWithDisabled}
        value={value1}
        onChange={(e) => setValue1(e.target.value)}
      ></RadioTabs>
      <div className="grid gap-y-4">
        {openAlert && (
          <Alert
            message="Success! This is a Success alert — check it out!"
            type="success"
            closable
            afterClose={() => setOpenAlert(false)}
          />
        )}

        {/* <SelectComponent
					placeholder="Select Form"
					value={x ? x : undefined}
					onChange={(v) => setX(v)}
				>
					<Select.Option value="1">Selection 1</Select.Option>
					<Select.Option value="2">Selection 2</Select.Option>
					<Select.Option value="3">Selection 3</Select.Option>
				</SelectComponent> */}

        {/* <Alert
					message="Warning! This is a Warning alert — check it out!"
					type="warning"
					closable
				/>
				<Alert
					message="Danger! This is a Danger alert — check it out!"
					type="error"
					closable
				/> */}
      </div>

      {/* <div className="grid gap-y-4">
				<StatusComponent color="active">Active</StatusComponent>
				<StatusComponent color="inactive">Inactive</StatusComponent>
				<StatusComponent color="waiting">Waiting to release</StatusComponent>
				<StatusComponent color="draft">Draft</StatusComponent>
				<StatusComponent color="approved">Approved</StatusComponent>
				<StatusComponent color="expire">Expire today</StatusComponent>
				<StatusComponent color="expire30">Expire in 30 days</StatusComponent>
				<StatusComponent color="expire10">Expire in 10 days</StatusComponent>
				<StatusComponent color="pending">Pending</StatusComponent>
			</div> */}

      <ModalCustom
        header={"LIST PRICING ADJUSTMENT DETAIL"}
        isOpen={modal}
        handleCancel={() => setModal(false)}
      >
        <CardComponent header={"PRICE CODE INFORMATION"}>
          <DetailText label="Price Code">PC0001</DetailText>
        </CardComponent>

        <div className="flex justify-end">
          <ButtonComponent
            // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
            onClick={() => setModal(false)}
            type="submit"
          >
            Back
          </ButtonComponent>
        </div>
      </ModalCustom>

      {/* <SVG
				name="IconHome"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconAccount"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconProduct"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconRating"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconGas"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconInvoice"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconPayment"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconNational"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconUser"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/>

			<SVG
				name="IconReport"
				width={24}
				style={{
					color: "#0880AE",
					fontSize: 24,
					justifyItems: "center",
				}}
			/> */}
      {/* <RadioTabs
				defaultValue="Main"
				onChange={(e) => console.log(e.target.value)}
			>
				<Radio.Button value="Main">Main</Radio.Button>
				<Radio.Button value="Add On">Add On</Radio.Button>
				<Radio.Button value="Bundling">Bundling</Radio.Button>
			</RadioTabs> */}
      {/* <ButtonComponent
				icon={<DeleteOutlined style={{ fontSize: "16px" }} />}
				onClick={alert}
				type="default"
			>
				Clear
			</ButtonComponent> */}
      {/* <BaseContainer header={"DASHBOARD MENU"}> */}
      {/* CONTOH KOMPONEN INPUT */}
      {/* <InputComponent
					type="text"
					// label="Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					mandatory
					suffix={<DeleteOutlined />}
				></InputComponent>

				<InputComponent
					type="textarea"
					label="Remark"
					value={remark}
					onChange={(e) => setRemark(e.target.value)}
					mandatory
				></InputComponent>

				<InputComponent
					type="number"
					label="Number"
					value={number}
					onChange={(e) => console.log(e.floatValue)}
					mandatory
				></InputComponent>
				{console.log(number)} */}
      {/* CONTOH KOMPONEN SELECT */}
      {/* <SelectComponent
					label="Selection"
					mandatory
					// options={data.map((e) => ({
					// 	label: e.name,
					// 	value: e.value,
					// }))}
					value={select}
					onChange={(e) => setSelect(e)}
				>
					<Select.Option value={1}> TExt</Select.Option>
				</SelectComponent> */}
      {/* </BaseContainer> */}
      {/* <div className="flex">
				<SketchPicker
					color={cc}
					onChangeComplete={(e) => setCC(e.hex)}
				></SketchPicker>
				<ButtonComponent onClick={() => changeColor()} type="submit">
					CHANGE COLOR
				</ButtonComponent>
				<ButtonComponent onClick={() => resetColor()} type="submit">
					RESET COLOR
				</ButtonComponent>
			</div> */}
      {/* <BaseContainer header={"TABLE"}>
				<div className="w-full">
					<Table
						columns={TABLE}
						dataSource={data}
						size="small"
						onChange={handleChange}
						pagination={{
							position: ["topRight"],
							total: totalData,
							showTotal: (total, range) =>
								`Showing ${range[0]} to ${range[1]} of ${total} records`,
							defaultPageSize: 10,
							defaultCurrent: 1,
							showSizeChanger: true,
							pageSizeOptions: [5, 10, 20, 50],
						}}
					></Table>
				</div>
			</BaseContainer> */}
      {/* <BaseContainer header={"TABLE CHECKBOX"}>
				<div className="w-full">
					<Table
						rowSelection={rowSelection}
						columns={TABLE_CHECKBOX}
						dataSource={data}
						size="small"
						onChange={handleChange}
						pagination={{
							position: ["topRight"],
							total: totalData,
							showTotal: (total, range) =>
								`Showing ${range[0]} to ${range[1]} of ${total} records`,
							defaultPageSize: 10,
							defaultCurrent: 1,
							showSizeChanger: true,
							pageSizeOptions: [5, 10, 20, 50],
						}}
					></Table>
				</div>
			</BaseContainer> */}
      {/* <BaseContainer header={"TABLE INLINE COLUMN"}>
				<div className="w-full">
					<Table
						columns={TABLE_INLINE}
						dataSource={data}
						size="small"
						onChange={handleChange}
						pagination={{
							position: ["topRight"],
							total: totalData,
							showTotal: (total, range) =>
								`Showing ${range[0]} to ${range[1]} of ${total} records`,
							defaultPageSize: 10,
							defaultCurrent: 1,
							showSizeChanger: true,
							pageSizeOptions: [5, 10, 20, 50],
						}}
					></Table>
				</div>
			</BaseContainer> */}
      {/* <BaseContainer header={"TABLE SCROLLING"}>
				<div className="grid grid-cols-2 w-full">
					<div className="col-span-2">
						<Table
							columns={TABLE_SCROLLING}
							dataSource={data}
							size="small"
							onChange={handleChange}
							pagination={{
								position: ["topRight"],
								total: totalData,
								showTotal: (total, range) =>
									`Showing ${range[0]} to ${range[1]} of ${total} records`,
								defaultPageSize: 10,
								defaultCurrent: 1,
								showSizeChanger: true,
								pageSizeOptions: [5, 10, 20, 50],
							}}
							scroll={{
								x: 2000,
							}}
						></Table>
					</div>
				</div>
			</BaseContainer> */}
      {/* Table Invoice */}
      {/* <BaseContainer header={"TABLE INVOICE"}>
				<div className="grid grid-cols-2 w-full">
					<div className="col-span-2">
						<Table
							columns={TABLE_INVOICE}
							dataSource={dataInvoice}
							size="small"
							onChange={handleChange}
							pagination={false}
							// scroll={{
							// 	x: 2000,
							// }}
						></Table>
					</div>
				</div>
			</BaseContainer> */}
      {/* Table Children */}
      {/* <BaseContainer header={"TABLE CHILDREN"}>
				<div className="grid grid-cols-2 w-full">
					<div className="col-span-2">
						<Table
							className="table-striped-rows"
							columns={TABLE_CHILDREN}
							dataSource={dataChildren}
							size="small"
							onChange={handleChange}
							pagination={{
								position: ["topRight"],
								total: totalData,
								showTotal: (total, range) =>
									`Showing ${range[0]} to ${range[1]} of ${total} entries`,
								defaultPageSize: 10,
								defaultCurrent: 1,
								showSizeChanger: true,
								pageSizeOptions: [5, 10, 20, 50],
							}}
							// scroll={{
							// 	x: 2000,
							// }}
						></Table>
					</div>
				</div>
			</BaseContainer> */}
    </LayoutMenu>
  );
};

export default Dashboard;
