import { CloseCircleOutlined, DownloadOutlined, FilterOutlined, PlusOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Checkbox, Input, Spin, Table, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import Highlighter from "react-highlight-words";
import { NavLink } from "react-router-dom";
import BaseContainer from "../../../../../components/BaseContainer";
import BreadCrumb from "../../../../../components/BreadCrumb";
import ButtonComponent from "../../../../../components/ButtonComponent";
import DetailText from "../../../../../components/DetailText";
import ModalCustom from "../../../../../components/Modal/ModalCustom";
import LayoutMenu from "../../../../../components/SidebarMenu/LayoutMenu";
import StatusComponent from "../../../../../components/StatusComponent";
import { dateFormat } from "../../../../../utils";
// import SVGIcon from "../../../../assets/Icon/index";
import SVGIcon from "../../../../../assets/Icon/index";

const Job = () => {
    const [modalInactive, setModalInactive] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [detailRecord, setDetailRecord] = useState({});

    const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const openDelete = (r) => {
    setDetailRecord(r);
    setModalDelete(true);
  };

  const openDetail = (r) => {
    setDetailRecord(r);
    setModalDetail(true);
  };

  const jobList = [
    {
      createdBy: "Annisa",
      updatedBy: "Annisa",
      status: "INACTIVE",
      createdDate: "2022-11-14T03:00:00.000+00:00",
      updatedDate: "2022-11-14T03:00:00.000+00:00",
      name: "Manager",
      description: "Manage Team",
      isDeleted: false,
    },
    {
      createdBy: "Annisa",
      updatedBy: "Annisa",
      status: "ACTIVE",
      createdDate: "2022-11-14T03:00:00.000+00:00",
      updatedDate: "2022-11-14T03:00:00.000+00:00",
      name: "Staff",
      description: "Staff",
      isDeleted: false,
    },
  
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    // tableNumbering(page),
    {
      title: "JOB NAME",
      dataIndex: "name",
      width: 100,
      ...getColumnSearchProps("name"),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      width: 240,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: 100,
      ...getColumnSearchProps("status"),
      render: (status) => (
        <div>
          {status === "ACTIVE" && (
            <StatusComponent color="green">{status}</StatusComponent>
          )}

          {status === "INACTIVE" && (
            <StatusComponent color="red">{status}</StatusComponent>
          )}
        </div>
      ),
    },
    {
      title: "ACTIVATE",
      dataIndex: "status",
      width: 100,
      render: (active, r, i) => {
        return (
          <div className="flex justify-center">
            {active === "ACTIVE" && (
              <Checkbox
                checked={true}
              // onChange={onChange}
              ></Checkbox>
            )}

            {active === "INACTIVE" && (
              <Checkbox
                checked={false}
              // onChange={onChange}
              ></Checkbox>
            )}
          </div>
        );
      },
    },
    {
      title: "ACTIONS",
      align: "center",
      width: 100,
      render: (v, r, i) => {
        return (
          <div className="flex justify-center gap-4">
            <Tooltip title="Detail">
              <UnorderedListOutlined
                onClick={() => {
                  openDetail(r);
                }}
                style={{
                  color: "#0880AE",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
            <NavLink to={"/app/system-setup/job/edit"}>
              <Tooltip title="Edit">
                <div>
                  <SVGIcon
                    name="IconEdit"
                    width={24}
                    style={{
                      color: "#0880AE",
                      fontSize: 24,
                      justifyItems: "center",
                    }}
                  />
                </div>
              </Tooltip>
            </NavLink>
            <Tooltip title="Delete">
              <CloseCircleOutlined
                onClick={() => {
                  openDelete(r)
                }}
                style={{
                  color: "#FF7171",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
          </div>
        );
      },
      key: "action",
    },
  ];
    return (
      <LayoutMenu>
      <Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
        <BreadCrumb pageName={["System Setup", "Master Data", "Job"]} />
        <BaseContainer header={"JOB LIST"}>
          {" "}
          <div className={"w-full"}>
            <div className="flex w-full justify-end">
              <ButtonComponent
                icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
                type="submit"
              >
                Download List
              </ButtonComponent>
              <NavLink to={"/app/system-setup/job/create"} state={{ x: 1 }}>
                <ButtonComponent
                  icon={<PlusOutlined style={{ fontSize: "24px" }} />}
                  type="submit"
                >
                  Create New
                </ButtonComponent>
              </NavLink>
            </div>
            <Table
              dataSource={jobList}
              columns={columns}
              size="small"
              pagination={{ position: ["bottomCenter"] }}
            />
          </div>
        </BaseContainer>
      </Spin>

      {/* MODAL ACTIVE/INACTIVE */}
      <ModalCustom
        isOpen={modalInactive}
        handleCancel={() => setModalInactive(false)}
        header={"INACTIVE JOB"}
        width={800}
      >
        <div className="flex flex-col w-full">
          <h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
            Are you sure you want to inactive job?
          </h1>

          <div className="flex mt-4 w-full justify-center">
            <ButtonComponent
              // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
              type={"default"}
              onClick={() => setModalInactive(false)}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              // onClick={() => setModalConfirmation(true)}
              type={"submit"}
            >
              Submit
            </ButtonComponent>
          </div>
        </div>
      </ModalCustom>

      {/* MODAL DETAIL */}
      <ModalCustom
        isOpen={modalDetail}
        handleCancel={() => setModalDetail(false)}
        header={"DETAIL JOB"}
        width={1000}
      >
        <div className="flex flex-col w-full gap-y-10">
          <div className="grid grid-cols-2 gap-y-2.5">
            <DetailText label={"Job Name"}>{detailRecord.name}</DetailText>
            <DetailText label={"Created At"}>
              {moment(detailRecord.createdDate).format(dateFormat)}
            </DetailText>
            <DetailText label={"Description"}>{detailRecord.description}</DetailText>
            <DetailText label={"Created By"}>
              {detailRecord.createdBy}
            </DetailText>
            <div></div>
            <DetailText label={"Latest Update"}>
              {moment(detailRecord.updatedDate).format(dateFormat)}
            </DetailText>
            <div></div>
            <DetailText label={"Updated By"}>
              {detailRecord.updatedBy}
            </DetailText>
          </div>
        </div>

        <div className="flex justify-end mt-8">
          <ButtonComponent
            // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
            onClick={() => setModalDetail(false)}
            type="submit"
          >
            Close
          </ButtonComponent>
        </div>
      </ModalCustom>

       {/* MODAL DELETE */}
       <ModalCustom
        isOpen={modalDelete}
        handleCancel={() => setModalDelete(false)}
        header={"DELETE JOB"}
        width={800}
      >
        <div className="flex flex-col w-full">
          <h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
            Are you sure you want to delete job?
          </h1>

          <div className="flex mt-4 w-full justify-center">
            <ButtonComponent
              // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
              type={"default"}
              onClick={() => setModalDelete(false)}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              // onClick={() => setModalConfirmation(true)}
              type={"submit"}
            >
              Delete
            </ButtonComponent>
          </div>
        </div>
      </ModalCustom>
    </LayoutMenu>
    );
}

export default Job;