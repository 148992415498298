import { SYSTEM_SETUP_ELEMENTS } from "./setup_elements";
import { SYSTEM_SETUP_ROUTES } from "./setup_routes";

export const system_setup = [
	// MENU
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_MENU,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_MENU_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_MENU,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_MENU_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_MENU,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_MENU_PAGE,
	},
	// global type
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_GLOBAL_TYPE,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_GLOBAL_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_GLOBAL_TYPE,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_GLOBAL_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.DETAIL_GLOBAL_TYPE,
		element: SYSTEM_SETUP_ELEMENTS.DETAIL_GLOBAL_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_GLOBAL_TYPE,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_GLOBAL_PAGE,
	},

	// GLOBAL PROPERTIES
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_GLOBAL_PROPERTIES,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_GLOBAL_PROPERTIES_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_GLOBAL_PROPERTIES,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_GLOBAL_PROPERTIES_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_GLOBAL_PROPERTIES,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_GLOBAL_PROPERTIES_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.DETAIL_GLOBAL_PROPERTIES,
		element: SYSTEM_SETUP_ELEMENTS.DETAIL_GLOBAL_PROPERTIES_PAGE,
	},

	// ACTION
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_ACTION,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_ACTION_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_ACTION,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_ACTION_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_ACTION,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_ACTION_PAGE,
	},

	// ENTITY
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_ENTITY,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_ENTITY_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_ENTITY,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_ENTITY_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_ENTITY,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_ENTITY_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.DETAIL_ENTITY,
		element: SYSTEM_SETUP_ELEMENTS.DETAIL_ENTITY_PAGE,
	},

	// COST CENTER
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_COST_CENTER,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_COST_CENTER_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_HIERARCHY,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_HIERARCHY_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_HIERARCHY,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_HIERARCHY_PAGE,
	},

	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_HIERARCHY,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_HIERARCHY_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.DETAIL_HIERARCHY,
		element: SYSTEM_SETUP_ELEMENTS.DETAIL_HIERARCHY_PAGE,
	},

	// GROUP ACCESS
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_GROUP_ACCESS,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_GROUP_ACCESS_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_GROUP_ACCESS,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_GROUP_ACCESS_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_GROUP_ACCESS,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_GROUP_ACCESS_PAGE,
	},

	// ANNOUNCEMENT
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_ANNOUNCEMENT,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_ANNOUNCEMENT_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_ANNOUNCEMENT,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_ANNOUNCEMENT_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_ANNOUNCEMENT,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_ANNOUNCEMENT_PAGE,
	},

	// JOB
	{
		path: SYSTEM_SETUP_ROUTES.VIEW_JOB,
		element: SYSTEM_SETUP_ELEMENTS.VIEW_JOB_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.CREATE_JOB,
		element: SYSTEM_SETUP_ELEMENTS.CREATE_JOB_PAGE,
	},
	{
		path: SYSTEM_SETUP_ROUTES.UPDATE_JOB,
		element: SYSTEM_SETUP_ELEMENTS.UPDATE_JOB_PAGE,
	},
];
