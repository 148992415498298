// folder utils buat bikin library-library custome sendiri

import { InfoCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

export const tableNumbering = () => {
	const n = {
		title: "NO.",
		render: (v, r, i) => `${i + 1}.`,
		width: 50,
	};
	return n;
};

export const openNotification = () => {
	notification.open({
		message: "Notification Title",
		description: "This is the content of the notification",
		onClick: () => {
			console.log("Notification Clicked!");
		},
		icon: (
			<div className="pb-1.5 px-1.5 rounded-md bg-[#E6F1F9]">
				<InfoCircleOutlined
					style={{
						fontSize: "16px",
						color: "#2E8ECB",
					}}
				></InfoCircleOutlined>
			</div>
		),
	});
};

export const dateFormat = "DD-MMM-YYYY HH:MM:SS";
