import React from "react";

const DetailText = (props) => {
	const { label, children } = props;
	return (
		<div className="">
			<label className="text-xs font-semibold">{label}</label>
			<p className="text-xs">{children ? children : "-"}</p>
		</div>
	);
};

export default DetailText;
