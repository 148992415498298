import axios from "axios";
import { configApp } from "../../constants/configApp";
import { tokenHeader } from "../../utils/tokenHeader";

const getEntity = async () => {
	try {
		const response = await axios.get(
			configApp.USER_MANAGEMENT_SERVICE + "/api/entity/get",
			{ headers: tokenHeader() }
		);
		// console.log(response, ' datas');
		return response.data;
	} catch (error) {
		console.log(error, " apa ini");
	}
};
const getAllEntityPaginate = async (page, pageSize) => {
	try {
		const response = await axios.get(
			configApp.USER_MANAGEMENT_SERVICE +
				`/v1/dbs/api/entity/paging?page=${page}&size=${pageSize}`,
			{ headers: tokenHeader() }
		);
		return response.data;
	} catch (error) {
		return error;
	}
};
const createEntity = async (body) => {
	try {
		const response = await axios.post(
			configApp.USER_MANAGEMENT_SERVICE + "/v1/dbs/api/entity/",
			body,
			{ headers: tokenHeader() }
		);
		console.log(body, " = body-nya");
		return response.data;
	} catch (error) {
		console.log(body, " = error body ");
		console.log(error, " = error create ");
		return error;
	}
};
const inactiveEntity = async (id) => {
	try {
		const response = await axios.delete(
			configApp.USER_MANAGEMENT_SERVICE + `/v1/dbs/api/entity/${id}/inactivate`,
			{ headers: tokenHeader() }
		);
		return response.data;
	} catch (error) {
		return error;
	}
};
const getDetailEntity = async (id) => {
	try {
		const response = await axios.get(
			`${configApp.USER_MANAGEMENT_SERVICE}/v1/dbs/api/entity/${id}/detail`,
			{ headers: tokenHeader() }
		);
		console.log(id, " ini idnya");
		return response;
	} catch (error) {
		// console.log(error, ' = error service')
		return error;
	}
};
const deleteEntity = async (id) => {
	try {
		const response = await axios.delete(
			`${configApp.USER_MANAGEMENT_SERVICE}/v1/dbs/api/entity/${id}/delete`,
			{ headers: tokenHeader() }
		);
		console.log(id, " ini idnya");
		return response;
	} catch (error) {
		// console.log(error, ' = error service')
		return error;
	}
};
const updateEntity = async (body) => {
	try {
		const response = await axios.put(
			configApp.USER_MANAGEMENT_SERVICE + "/v1/dbs/api/entity/update",
			body,
			{ headers: tokenHeader() }
		);
		console.log(body, " = body-nya");
		return response.data;
	} catch (error) {
		console.log(body, " = error body ");
		console.log(error, " = error create ");
		return error;
	}
};
const downloadExcel = async () => {
	try {
		const response = await axios.get(
			configApp.USER_MANAGEMENT_SERVICE + "/v1/dbs/api/entity/download",
			{ headers: tokenHeader() }
		);
		// console.log(response, ' datas');
		return response.data;
	} catch (error) {
		console.log(error, " apa ini");
	}
};
const entityService = {
	getEntity,
	getAllEntityPaginate,
	createEntity,
	inactiveEntity,
	getDetailEntity,
	deleteEntity,
	updateEntity,
	downloadExcel,
};

export default entityService;
