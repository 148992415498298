import React, { useCallback, useState } from "react";
import { Spin } from "antd";
import LayoutMenu from "../../../../../components/SidebarMenu/LayoutMenu";
import BreadCrumb from "../../../../../components/BreadCrumb";
import BaseContainer from "../../../../../components/BaseContainer";
import Tree from "react-d3-tree";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";

const ViewHierarchy = () => {
  const navigate = useNavigate();
  const nodeSize = { x: 150, y: 400 };

  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -70,
    y: 8,
  };
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 30 });
    }
  }, []);

  const datas = [
    {
      name: "Muhammad",
      department: "CEO",
      children: [
        {
          name: "Budi",
          department: "Production",
          children: [
            {
              name: "John",
              department: "Fabrication",
              children: [
                {
                  name: "Binar",
                  department: "Fabrication",
                },
              ],
            },
            {
              name: "Nina",
              department: "Assembly",
              children: [
                {
                  name: "Johan",
                  department: "Assembly",
                },
              ],
            },
          ],
        },
        {
          name: "Ibas",
          department: "Marketing",
          children: [
            {
              name: "Surya",
              department: "Marketing",
              children: [
                {
                  name: "Hadi",
                  department: "Marketing",
                },
              ],
            },
            {
              name: "Gayatri",
              department: "Marketing",
              children: [
                {
                  name: "RIma",
                  department: "Marketing",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const containerStyles = {
    width: "100%",
    height: "80vh",
  };

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
  }) => (
    <g onClick={toggleNode}>
      <circle r={5} className={"bg-black"}></circle>
      <foreignObject {...foreignObjectProps}>
        <div className="flex bg-[#CEDBEC] justify-center rounded-lg m-2">
          <div className="flex-col items-center my-5">
            <div className={"flex gap-3 justify-center mx-auto w-full"}>
              {/* <Avatar icon={<UserOutlined />} /> */}
              <div className="flex-col items-center justify-center">
                <p className="text-black leading-none text-[12px] text-center">
                  {nodeDatum.name}
                </p>
                <p className="text-gray-400 text-[12px] text-center">
                  {nodeDatum.department}
                </p>
              </div>
            </div>
          </div>
        </div>
      </foreignObject>
    </g>
  );

  return (
    <LayoutMenu>
      <Spin spinning={false} className="w-full top-20" tip="Loading">
        <BreadCrumb
          pageName={[
            "System Setup",
            "Master Data",
            "Cost Center",
            "View Hierarchy",
          ]}
        />

        <NavLink onClick={() => navigate(-1)}>
          <div className="flex align-middle gap-x-2 mb-4">
            <ArrowLeftOutlined
              style={{ fontSize: "24px", color: "#3C6DB2" }}
            ></ArrowLeftOutlined>
            <span className="text-[#3C6DB2]">Back</span>
          </div>
        </NavLink>

        <BaseContainer header={"VIEW HIERARCHY"}>
          <div style={containerStyles} ref={containerRef}>
            <Tree
              data={datas}
              collapsible={true}
              translate={translate}
              pathFunc="elbow"
              orientation="vertical"
              renderCustomNodeElement={(rd3tProps) =>
                renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
              }
              zoomable={true}
            />
          </div>
        </BaseContainer>
      </Spin>
    </LayoutMenu>
  );
};

export default ViewHierarchy;
