export const USER_ROUTES = {
  VIEW_USER: "/user-management/user",
  CREATE_USER: "/user-management/user/create",
  // DETAIL_USER: "/user-management/user/detail/:id",
  DETAIL_USER: `/user-management/user/detail/`,
  CHANGE_AUTH: "/user-management/user/change-auth",
  // CHANGE_AUTH: `/user-management/user/change-auth/${id}`,
  GENERATE_PASSWORD: "/user-management/user/generate-password",
  // GENERATE_PASSWORD: `/user-management/user/generate-password/${id}`,
  UPLOAD_USER: "/user-management/user/upload",
  UPDATE_USER: "/user-management/user/update",

  // position hierarchy
  VIEW_POSITION: "/user-management/hierarchy/position",
  DETAIL_POSITION: "/user-management/hierarchy/position/detail",
  // DETAIL_POSITION: '/user-management/hierarchy/position/detail/${id}',
  CREATE_POSITION: "/user-management/hierarchy/position/create",
  UPDATE_POSITION: "/user-management/hierarchy/position/update",
  // UPDATE_POSITION: `/user-management/position/update/${id}`

  // employee
  VIEW_EMPLOYEE: "/user-management/employee",
  DETAIL_EMPLOYEE: "/user-management/employee/detail",
  CREATE_EMPLOYEE: "/user-management/employee/create",
  UPDATE_EMPLOYEE: "/user-management/employee/update",
  UPLOAD_EMPLOYEE: "/user-management/employee/upload",

  // DELEGATION
  VIEW_DELEGATION: "/user-management/delegation",
  CREATE_DELEGATION: "/user-management/delegation/create",
  UPDATE_DELEGATION: "/user-management/delegation/update",
  
  // approval hierarchy
  VIEW_APPROVAL_HIERARCHY: "/user-management/hierarchy/approval",
  DETAIL_APPROVAL_HIERARCHY: "/user-management/hierarchy/approval/detail",
  // DETAIL_APPROVAL_HIERARCHY: '/user-management/hierarchy/approval/detail/${id}',
  CREATE_APPROVAL_HIERARCHY: "/user-management/hierarchy/approval/create",
  UPDATE_APPROVAL_HIERARCHY: "/user-management/hierarchy/approval/update",
	// UPDATE_APPROVAL_HIERARCHY: '/system-setup/hierarchy/approval/update/${id}',
};
