import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/BreadCrumb";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { useSelector, useDispatch } from "react-redux";
import BaseContainer from "../../../../components/BaseContainer";
import {
	deleteEntity,
	getAllEntityPaginate,
	getListEntity,
	inactiveEntity,
} from "../../../../redux/slices/entity";
import {
	Spin,
	Table,
	Checkbox,
	Tooltip,
	Pagination,
	Select,
	Popover,
	Alert,
} from "antd";
import ButtonComponent from "../../../../components/ButtonComponent";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import {
	DownloadOutlined,
	PlusOutlined,
	UnorderedListOutlined,
	EditOutlined,
	CloseCircleOutlined,
	MoreOutlined,
	DeleteOutlined,
	WarningOutlined,
	InfoCircleOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Link, NavLink } from "react-router-dom";
import StatusComponent from "../../../../components/StatusComponent";
import SVGIcon from "../../../../assets/Icon/index";
import { SYSTEM_SETUP_ROUTES } from "../../../../routes/system_setup/setup_routes";
import { ModalConfirm } from "../../../../components/Modal/ModalPopUp";

const EntityPage = () => {
	const { data, loading } = useSelector((state) => state.entity);
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const [modalDI, setModalDI] = useState(false);
	const [status, setStatus] = useState("");
	const [entityId, setEntityId] = useState("");
	const [typeModal, setTypeModal] = useState("");

	const dataSource = data?.result;
	console.log(dataSource);

	useEffect(() => {
		dispatch(getAllEntityPaginate({ page, pageSize }));
	}, []);

	const columns = [
		{
			title: "NO",
			width: 60,
			align: "center",
			render: (text, object, index) => (page - 1) * pageSize + index + 1,
		},
		{
			title: "NAME",
			dataIndex: "entityName",
		},
		{
			title: "ENTITY CODE",
			dataIndex: "entityCode",
			align: "center",
		},
		{
			title: "EMAIL",
			dataIndex: "email",
			align: "center",
		},
		{
			title: "PHONE NUMBER",
			dataIndex: "phone",
			align: "right",
		},
		{
			title: "TAX IDENTIFIER",
			dataIndex: "taxIdentifier",
			align: "right",
		},
		{
			title: "DESCRIPTION",
			dataIndex: "description",
		},
		{
			title: "STATUS",
			dataIndex: "status",
			key: "status",
			render: (status) => (
				<StatusComponent color={status === "ACTIVE" ? "active" : "inactive"}>
					{status}
				</StatusComponent>
			),
			width: 175,
		},
		{
			title: "ACTIVATE",
			align: "center",
			render: (v, r) => {
				return (
					<div className="flex justify-center">
						<Checkbox
							onClick={() => {
								setEntityId(r?.entityId);
								setStatus(r?.status);
								setTypeModal("ai");
								setModalDI(true);
							}}
							checked={r.status === "ACTIVE" ? true : false}
						></Checkbox>
					</div>
				);
			},
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 100,
			// fixed: "right",
			render: (v, r) => {
				return (
					<div className="flex justify-center align-middle">
						<Popover
							content={
								<div>
									<Link
										to={SYSTEM_SETUP_ROUTES.DETAIL_ENTITY}
										state={{ id: r?.entityId }}
									>
										<ButtonComponent
											icon={<SVGIcon name="IconDetail" width={24} />}
											border={false}
										>
											<span className={"text-black"}> Detail</span>
										</ButtonComponent>
									</Link>
									<Link
										to={SYSTEM_SETUP_ROUTES.UPDATE_ENTITY}
										state={{ id: r?.entityId }}
									>
										<ButtonComponent icon={<EditOutlined />} border={false}>
											<span className={"text-black"}> Update Entity</span>
										</ButtonComponent>
									</Link>
								</div>
							}
							trigger={"click"}
							placement="bottomRight"
						>
							<ButtonComponent icon={<MoreOutlined />} border={false} />
						</Popover>
						<Tooltip title="Delete">
							{/* <ButtonComponent
								border={false}
								icon={<SVGIcon name="IconDelete" width={24} />}

							></ButtonComponent> */}

							<ButtonComponent
								onClick={() => {
									setEntityId(r?.entityId);
									setModalDI(true);
								}}
								border={false}
							>
								<span className="text-red-500">Delete</span>
							</ButtonComponent>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];

	const [displayColumn, setDisplayColumn] = useState(columns);
	const [optionSelectedCol, setOptionSelectedCol] = useState([]);
	const handleDisplayColumn = (value) => {
		setDisplayColumn(() =>
			columns.filter((col) => {
				return value.includes(col.title);
			})
		);
		setOptionSelectedCol(value);
	};

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const handleChange = (page) => {
		setPage(page);
		dispatch(getAllEntityPaginate({ page, pageSize }));
	};
	const handleChangeSize = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
		dispatch(getAllEntityPaginate({ page, pageSize }));
	};
	const handleInactive = async () => {
		try {
			await dispatch(inactiveEntity(entityId));
			await dispatch(getAllEntityPaginate({ page, pageSize }));
		} catch (error) {
			console.log(error);
		}
		setModalDI(false);
	};
	const handleDelete = async () => {
		try {
			await dispatch(deleteEntity(entityId));
			await dispatch(getAllEntityPaginate({ page, pageSize }));
		} catch (error) {
			console.log(error);
		}
		setModalDI(false);
	};

	const routes = [
		{
			path: "",
			breadcrumbName: "System Setup",
		},
		{
			path: SYSTEM_SETUP_ROUTES.VIEW_ENTITY,
			breadcrumbName: "Entity",
		},
	];

	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb routes={routes} />

				<div className="flex w-full justify-end gap-x-2">
					<ButtonComponent
						icon={<SVGIcon name="IconButtonDownload" width={24} />}
						type="submit"
					>
						Download List
					</ButtonComponent>
					<NavLink to={SYSTEM_SETUP_ROUTES.CREATE_ENTITY}>
						<ButtonComponent
							icon={<SVGIcon name="IconButtonCreate" width={24} />}
							type="submit"
						>
							Create New
						</ButtonComponent>
					</NavLink>
				</div>
				<BaseContainer header={"ENTITY LIST"}>
					<div className={"w-full"}>
						<div className={"w-full flex mb-5 gap-2 justify-between"}>
							<Select
								mode="multiple"
								placeholder="Show All Column"
								className={"w-2/6"}
								maxTagCount={3}
								onChange={handleDisplayColumn}
							>
								{columns.map((col) => (
									<Select.Option
										key={col.title}
										value={col.title}
										disabled={
											optionSelectedCol.length > 3
												? optionSelectedCol.includes(col.title)
													? false
													: true
												: false
										}
									>
										{col.title}
									</Select.Option>
								))}
							</Select>
							<Pagination
								total={data?.page?.totalElements}
								className={"pr-1"}
								showSizeChanger
								current={page}
								pageSize={pageSize}
								onChange={handleChange}
								onShowSizeChange={handleChangeSize}
								showTotal={(total, range) =>
									`Showing ${range[0]} to ${range[1]} of ${total} records`
								}
							/>
						</div>
						<Table
							scroll={{ x: 1800 }}
							loading={loading}
							dataSource={dataSource}
							columns={displayColumn}
							size="small"
							pagination={false}
						/>
					</div>
				</BaseContainer>
			</Spin>

			<ModalConfirm
				isOpen={modalDI}
				handleCancel={() => setModalDI(false)}
				handleOk={() =>
					typeModal === "ai" ? handleInactive(entityId) : handleDelete(entityId)
				}
			>
				<div className="w-full justify-center items-center flex my-2">
					<div className={"mr-4 text-[#3C6DB2] text-2xl"}>
						<ExclamationCircleOutlined />
					</div>
					<span className={"text-[1.3rem] text-black text-bold"}>
						{`Are you sure want to ${
							status === "ACTIVATE" ? "inactivation" : "activation"
						} user?`}
					</span>
				</div>
				<div className={"w-full justify-center my-4 flex text-sm"}>
					{typeModal === "ai" ? (
						<Alert
							message={
								status === "ACTIVE"
									? "If you activate this data, it will be inactivate again."
									: "If you inactive this data, it will be activate again."
							}
							type={status === "ACTIVE" ? "error" : "success"}
							icon={<WarningOutlined />}
							showIcon
						/>
					) : (
						<Alert
							message={"If you delete this data, it will be permanently."}
							type={"error"}
							icon={<WarningOutlined />}
							showIcon
						/>
					)}
				</div>
			</ModalConfirm>
		</LayoutMenu>
	);
};

export default EntityPage;
