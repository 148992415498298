import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.18247 13.1134C3.11966 12.6343 3.45718 12.1949 3.93633 12.1321C4.41548 12.0693 4.85482 12.4068 4.91763 12.886L3.18247 13.1134ZM5.36294 9.32347C5.18412 9.77242 4.67522 9.9914 4.22627 9.81258C3.77732 9.63376 3.55834 9.12486 3.73716 8.67591L5.36294 9.32347ZM3.17505 3.99963C3.17505 3.51638 3.5668 3.12463 4.05005 3.12463C4.5333 3.12463 4.92505 3.51638 4.92505 3.99963H3.17505ZM4.05005 8.99963V9.87463C3.5668 9.87463 3.17505 9.48288 3.17505 8.99963H4.05005ZM9.05005 8.12463C9.5333 8.12463 9.92505 8.51639 9.92505 8.99963C9.92505 9.48288 9.5333 9.87463 9.05005 9.87463V8.12463ZM4.91763 12.886C5.38358 16.4408 8.41833 19.0956 12.0035 19.0849L12.0087 20.8349C7.54299 20.8483 3.76287 17.5413 3.18247 13.1134L4.91763 12.886ZM12.0035 19.0849C15.5887 19.0742 18.6075 16.4012 19.0522 12.8437L20.7887 13.0608C20.2348 17.492 16.4745 20.8216 12.0087 20.8349L12.0035 19.0849ZM19.0522 12.8437C19.4969 9.2862 17.2289 5.95236 13.7567 5.05947L14.1925 3.36461C18.5176 4.47681 21.3426 8.62949 20.7887 13.0608L19.0522 12.8437ZM13.7567 5.05947C10.2845 4.16657 6.68958 5.99277 5.36294 9.32347L3.73716 8.67591C5.38964 4.52714 9.86748 2.25241 14.1925 3.36461L13.7567 5.05947ZM4.92505 3.99963V8.99963H3.17505V3.99963H4.92505ZM4.05005 8.12463H9.05005V9.87463H4.05005V8.12463Z"
      fill="white"
    />
  </svg>
);

export default SVG;
