import React, { useRef, useState } from "react";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { Spin, Select, Popover } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import BreadCrumb from "../../../../components/BreadCrumb";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import BaseContainer from "../../../../components/BaseContainer";
import InputComponent from "../../../../components/InputComponent";
import SelectComponent from "../../../../components/SelectComponent";
import ButtonComponent from "../../../../components/ButtonComponent";
import DetailText from "../../../../components/DetailText";
import ModalCustom from "../../../../components/Modal/ModalCustom";

const OPTIONS = ["Apples", "Nails", "Bananas", "Helicopters"];

const EditGlobalType = () => {
  const navigate = useNavigate();
  const searchInput = useRef(null);

  const [modalConfirmation, setModalConfirmation] = useState(false);

  const [groupName, SetGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [sortBy, setSortBy] = useState([]);

  const filteredOptions = OPTIONS.filter((o) => !sortBy.includes(o));

  const content = (
    <span className="text-[11px] font-bold  text-[#3C6DB2]">
      Please fill mandatory form
    </span>
  );

  const clearForm = () => {
    SetGroupName("");
    setDescription("");
    setSortBy([]);
  };

  const submitForm = () => {
    const body = {
      groupName: groupName,
      description: description,
      sortBy: sortBy,
    };
    console.log(body);
    setModalConfirmation(false);
    // setOpenModal(true);
  };
  return (
    <LayoutMenu>
      <Spin spinning={false} className="w-full top-20" tip="Loading">
        <BreadCrumb
          pageName={["System Setup ", "Global Type", "Edit Global Type"]}
        />

        <NavLink onClick={() => navigate(-1)}>
          <div className="flex align-middle gap-x-2 mb-4">
            <ArrowLeftOutlined
              style={{ fontSize: "24px", color: "#3C6DB2" }}
            ></ArrowLeftOutlined>
            <span className="text-[#3C6DB2]">Back</span>
          </div>
        </NavLink>

        <BaseContainer header={"UPDATE GLOBAL TYPE"}>
          <div className="grid grid-cols-1 w-full">
            <div className="grid grid-cols-1 w-full gap-8">
              <InputComponent
                type="text"
                label={"Group Name"}
                mandatory
                onChange={(e) => SetGroupName(e.target.value)}
                value={groupName}
              ></InputComponent>
              <InputComponent
                type="textarea"
                label={"Description"}
                mandatory
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              ></InputComponent>
              <SelectComponent
                type="number"
                label={"Sort By"}
                mandatory
                value={sortBy}
                onChange={(e) => setSortBy(e)}
              >
                {filteredOptions.map((e) => (
                  <Select.Option value={e}>{e}</Select.Option>
                ))}
              </SelectComponent>
            </div>

            <div className="flex mt-4 w-full justify-end">
              <ButtonComponent
                icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"default"}
                onClick={() => {
                  clearForm();
                }}
              >
                Clear
              </ButtonComponent>
              {groupName && description && sortBy ? (
                <ButtonComponent
                  onClick={() => setModalConfirmation(true)}
                  type={"submit"}
                >
                  Save
                </ButtonComponent>
              ) : (
                <Popover placement="top" content={content} trigger="hover">
                  <div>
                    <ButtonComponent
                      onClick={() => setModalConfirmation(true)}
                      type={"submit"}
                      disabled
                    >
                      Save
                    </ButtonComponent>
                  </div>
                </Popover>
              )}
            </div>
          </div>
        </BaseContainer>

        <ModalCustom
          isOpen={modalConfirmation}
          handleCancel={() => setModalConfirmation(false)}
          header={"CONFIRMATION"}
          width={500}
        >
          <div className="flex flex-col w-full gap-y-10">
            <div className="grid grid-cols-1 gap-y-2.5">
              <DetailText label={"Group Name"}>{groupName}</DetailText>
              <DetailText label={"Description"}>{description}</DetailText>
              <DetailText label={"Sort By"}>{sortBy}</DetailText>
            </div>

            <div className="flex mt-4 w-full justify-end">
              <ButtonComponent
                // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"default"}
                onClick={() => setModalConfirmation(false)}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent onClick={() => submitForm()} type={"submit"}>
                Submit
              </ButtonComponent>
            </div>
          </div>
        </ModalCustom>
      </Spin>
    </LayoutMenu>
  );
};

export default EditGlobalType;
