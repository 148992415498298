export const SYSTEM_SETUP_ROUTES = {
	// menus routes
	VIEW_MENU: "/system-setup/menu",
	CREATE_MENU: "/system-setup/menu/create",
	UPDATE_MENU: "/system-setup/menu/edit",


	// global type
	VIEW_GLOBAL_TYPE: "/system-setup/global-type",
	CREATE_GLOBAL_TYPE: "/system-setup/global-type/create",
	DETAIL_GLOBAL_TYPE: "/system-setup/global-type/detail",
	// DETAIL_GLOBAL_TYPE:'/system-setup/global-type/detail/&{id}',
	UPDATE_GLOBAL_TYPE: "/system-setup/global-type/edit",
	// UPDATE_GLOBAL_TYPE:'/system-setup/global-type/edit/${id}',

	// global properties
	VIEW_GLOBAL_PROPERTIES: "/system-setup/global-properties",
	DETAIL_GLOBAL_PROPERTIES: "/system-setup/global-properties/detail",
	CREATE_GLOBAL_PROPERTIES: "/system-setup/global-properties/create",
	UPDATE_GLOBAL_PROPERTIES: "/system-setup/global-properties/update",

	// action
	VIEW_ACTION: "/system-setup/action",
	CREATE_ACTION: "/system-setup/action/create",
	UPDATE_ACTION: "system-setup/action/edit",
	// UPDATE_ACTION:'system-setup/action/edit/${id}'

	// entity
	VIEW_ENTITY: "/system-setup/entity",
	CREATE_ENTITY: "/system-setup/entity/create",
	UPDATE_ENTITY: "/system-setup/entity/edit",
	DETAIL_ENTITY: "/system-setup/entity/detail",

	// cost center
	VIEW_COST_CENTER: "/system-setup/master-data/cost-center",
	CREATE_HIERARCHY: "/system-setup/master-data/cost-center/create",
	VIEW_HIERARCHY: "/system-setup/master-data/cost-center/view-hierarchy",
	UPDATE_HIERARCHY: "/system-setup/master-data/cost-center/update",
	DETAIL_HIERARCHY: "/system-setup/master-data/cost-center/detail",

	// group access
	VIEW_GROUP_ACCESS: "/system-setup/group-access",
	CREATE_GROUP_ACCESS: "/system-setup/group-access/create",
	UPDATE_GROUP_ACCESS: "/system-setup/group-access/update",

	// announcement
	VIEW_ANNOUNCEMENT: "/system-setup/announcement",
	CREATE_ANNOUNCEMENT: "/system-setup/announcement/create",
	UPDATE_ANNOUNCEMENT: "/system-setup/announcement/update",

	// JOB
	VIEW_JOB: "/system-setup/job",
	CREATE_JOB: "/system-setup/job/create",
	UPDATE_JOB: "/system-setup/job/edit",
};
