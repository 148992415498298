import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.18247 13.1138C3.11966 12.6346 3.45718 12.1953 3.93633 12.1325C4.41548 12.0697 4.85482 12.4072 4.91763 12.8863L3.18247 13.1138ZM5.36294 9.32384C5.18412 9.77279 4.67522 9.99177 4.22627 9.81295C3.77732 9.63413 3.55834 9.12523 3.73716 8.67628L5.36294 9.32384ZM3.17505 4C3.17505 3.51675 3.5668 3.125 4.05005 3.125C4.5333 3.125 4.92505 3.51675 4.92505 4H3.17505ZM4.05005 9V9.875C3.5668 9.875 3.17505 9.48325 3.17505 9H4.05005ZM9.05005 8.125C9.5333 8.125 9.92505 8.51675 9.92505 9C9.92505 9.48325 9.5333 9.875 9.05005 9.875V8.125ZM4.91763 12.8863C5.38358 16.4411 8.41833 19.096 12.0035 19.0853L12.0087 20.8353C7.54299 20.8486 3.76287 17.5417 3.18247 13.1138L4.91763 12.8863ZM12.0035 19.0853C15.5887 19.0746 18.6075 16.4016 19.0522 12.8441L20.7887 13.0611C20.2348 17.4924 16.4745 20.8219 12.0087 20.8353L12.0035 19.0853ZM19.0522 12.8441C19.4969 9.28657 17.2289 5.95273 13.7567 5.05983L14.1925 3.36497C18.5176 4.47718 21.3426 8.62986 20.7887 13.0611L19.0522 12.8441ZM13.7567 5.05983C10.2845 4.16694 6.68958 5.99313 5.36294 9.32384L3.73716 8.67628C5.38964 4.5275 9.86748 2.25277 14.1925 3.36497L13.7567 5.05983ZM4.92505 4V9H3.17505V4H4.92505ZM4.05005 8.125H9.05005V9.875H4.05005V8.125Z"
      fill="#4B465C"
    />
  </svg>
);

export default SVG;
