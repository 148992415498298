import { Spin, Table, Tooltip } from "antd";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import ButtonComponent from "../../../../components/ButtonComponent";
import { NavLink } from "react-router-dom";
import {
	CloseCircleOutlined,
	DownloadOutlined,
	PlusOutlined,
	UnorderedListOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import SVGIcon from "../../../../assets/Icon/index";
import { getAllEmployeePaginate } from "../../../../redux/slices/employee";
import { useDispatch, useSelector } from "react-redux";

const Employee = () => {
	const dispatch = useDispatch();
	const { data, loading } = useSelector((state) => state.user);
	const dataSource = data?.result;
	const [modalInactive, setModalInactive] = useState(false);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);

	useEffect(() => {
		dispatch(getAllEmployeePaginate({ page, pageSize }));
	}, []);

	console.log(data);
	const columns = [
		{
			title: "EMPLOYEE NUMBER",
			dataIndex: "employeeNumber",
			width: 160,
		},
		{
			title: "FIRST NAME",
			dataIndex: "firstName",
			width: 160,
		},
		{
			title: "LAST NAME",
			dataIndex: "lastName",
			width: 160,
		},
		{
			title: "PHONE NUMBER",
			dataIndex: "phone",
			width: 160,
		},
		{
			title: "EMAIL",
			dataIndex: "email",
			width: 160,
		},
		{
			title: "START DATE",
			dataIndex: "startDate",
			width: 160,
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
			width: 160,
		},
		{
			title: "JOB",
			dataIndex: "job",
			width: 160,
		},
		{
			title: "POSITION",
			dataIndex: "position",
			width: 160,
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 160,
			render: (v, r, i) => {
				return (
					<div className="flex justify-center gap-4">
						<NavLink
							to={"/app/user-management/employee/detail"}
							state={{ employeeNumber: r.employeeNumber }}
						>
							<Tooltip title="Detail">
								<UnorderedListOutlined
									style={{
										color: "#0880AE",
										fontSize: 24,
										justifyItems: "center",
									}}
								/>
							</Tooltip>
						</NavLink>
						<NavLink to={"/app/user-management/employee/edit"}>
							<Tooltip title="Edit">
								<div>
									<SVGIcon
										name="IconEdit"
										width={24}
										style={{
											color: "#0880AE",
											fontSize: 24,
											justifyItems: "center",
										}}
									/>
								</div>
							</Tooltip>
						</NavLink>
						<Tooltip title="Delete">
							<CloseCircleOutlined
								onClick={() => {
									setModalInactive(true);
								}}
								style={{
									color: "#FF7171",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb pageName={["User Management", "Employee"]} />
				<BaseContainer header={"EMPLOYEE LIST"}>
					<div className={"w-full"}>
						<div className="flex w-full justify-end">
							<ButtonComponent
								icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
								type="submit"
							>
								Download List
							</ButtonComponent>
							<NavLink
								to={"/app/user-management/employee/upload"}
								state={{ x: 1 }}
							>
								<ButtonComponent
									icon={<UploadOutlined style={{ fontSize: "24px" }} />}
									type="submit"
								>
									Upload
								</ButtonComponent>
							</NavLink>
							<NavLink
								to={"/app/user-management/employee/create"}
								state={{ x: 1 }}
							>
								<ButtonComponent
									icon={<PlusOutlined style={{ fontSize: "24px" }} />}
									type="submit"
								>
									Create New
								</ButtonComponent>
							</NavLink>
						</div>
						<Table
							dataSource={[]}
							columns={columns}
							size="small"
							pagination={{ position: ["bottomCenter"] }}
						/>
					</div>
				</BaseContainer>
			</Spin>

			<ModalCustom
				isOpen={modalInactive}
				handleCancel={() => setModalInactive(false)}
				header={"INACTIVE ENTITY"}
				width={800}
			>
				<div className="flex flex-col w-full">
					<h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
						Are you sure you want to inactive Entity?
					</h1>

					<div className="flex mt-4 w-full justify-center">
						<ButtonComponent
							// icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
							type={"default"}
							onClick={() => setModalInactive(false)}
						>
							Cancel
						</ButtonComponent>
						<ButtonComponent
							// onClick={() => setModalConfirmation(true)}
							type={"submit"}
						>
							Submit
						</ButtonComponent>
					</div>
				</div>
			</ModalCustom>
		</LayoutMenu>
	);
};

export default Employee;
