import * as Icons from "@ant-design/icons";
import { Menu } from "antd";
import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getListMenus } from "../../redux/slices/menus";
import SVGIcon from "../../../src/assets/Icon/index"
const Icon = ({ type, ...rest }) => {
	const icons = require(`@ant-design/icons`);
	const Component = icons[type];
	return <Component {...rest} />;
};

const SideMenu = ({ isCollapsed }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { datas, loading } = useSelector((state) => state.menus);

	useEffect(() => {
		dispatch(getListMenus());
	}, [dispatch]);

	// console.log(isCollapsed, " = isCollapsed");
	let activeStyleParent = {
		background: `var(--primary)`,
		color: "white",
	};

	let activeStylePChildren = {
		background: `var(--primary)`,
		color: "white",
		top: 0,
		width: "100%",
		right: 0,
		borderRadius: '5px',
		paddingLeft: "5.5vh",
		position: "absolute",
		zIndex: 1,
	};

	const menus = datas
		? datas.map((index, key) => {
				return {
					label: (
						<NavLink
							to={index?.path}
							style={
								location.pathname.includes(index?.path) && isCollapsed === false
									? activeStyleParent
									: undefined
							}
						>
							{index?.name}
						</NavLink>
					),
					key: index?.path,
					// icon: <Icon type={index?.icon} />,
					icon: <SVGIcon
						name={index?.icon}
						width={20}
						style={{
						marginRight: isCollapsed === true ? '80px' : '12px',
						marginLeft: isCollapsed === true ? '-5px' : '',
						marginTop: isCollapsed === true ? '10px' : '',
					}} />,
					children:
						index?.children !== null &&
						index?.children.map((children, key) => {
							return {
								label: (
									<NavLink
										to={children?.path}
										style={
											location.pathname.includes(children?.path) &&
											isCollapsed === false
												? activeStylePChildren
												: { paddingLeft: "0vh" }
										}
									>
										{children?.name}
									</NavLink>
								),
								key: children?.path,
							};
						}),
				};
		  })
		: [];

	return (
		<div>
			<Menu
				theme="light"
				selectedKeys={[location.pathname]}
				defaultOpenKeys={[location.pathname]}
				mode="inline"
				items={menus}
				className={"mb-6"}
			/>
		</div>
	);
};

export default SideMenu;
