import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7753 11.6394C14.6845 11.7552 14.6204 11.8967 14.6204 11.9997C14.6204 12.1027 14.6845 12.2442 14.7753 12.36L14.9303 12.5466H17.8256H20.7156L20.8438 12.3858C21.0468 12.1477 21.0415 11.8195 20.8385 11.5943L20.7049 11.4528H17.8203H14.9303L14.7753 11.6394Z"
      fill="#4B465C"
      stroke="#4B465C"
      stroke-width="0.2"
    />
    <path
      d="M14.771 13.8271C14.6052 14.0266 14.5785 14.2067 14.6801 14.432C14.8137 14.7344 14.7496 14.7279 17.8073 14.7344H20.7047L20.8384 14.5864C21.0415 14.3676 21.0469 14.0395 20.8437 13.8014L20.7154 13.6405H17.8234H14.926L14.771 13.8271Z"
      fill="#4B465C"
      stroke="#4B465C"
      stroke-width="0.2"
    />
    <path
      d="M14.7754 16.0149C14.6899 16.1178 14.6204 16.2658 14.6204 16.343C14.6204 16.5296 14.7594 16.832 14.861 16.8835C14.9091 16.9028 16.2455 16.9221 17.8278 16.9221H20.7091L20.8428 16.7741C20.9925 16.6197 21.0245 16.4653 20.9657 16.2015C20.8856 15.8283 20.8802 15.8283 17.7744 15.8283H14.9305L14.7754 16.0149Z"
      fill="#4B465C"
      stroke="#4B465C"
      stroke-width="0.2"
    />
    <path
      d="M21.9906 6.44833H13.9494V2.92609C13.9494 1.31812 13.0534 0.00548594 11.9475 0.00548594L2.00187 1.70298e-05C0.899719 -0.00545227 0 1.30718 0 2.91516V12.2075C0 13.8155 0.89597 15.1281 2.00187 15.1281H2.37301V16.7689C2.37301 17.0588 2.48172 17.3213 2.65792 17.458C2.74412 17.5255 2.84125 17.5576 2.93896 17.5508C3.03667 17.5441 3.13129 17.4988 3.21275 17.4198L5.56326 15.1281H10.0469V18.6558C10.0469 20.2638 10.9428 21.571 12.045 21.571H18.433L20.7835 23.8681C20.865 23.9471 20.9596 23.9924 21.0573 23.9991C21.155 24.0059 21.2521 23.9738 21.3383 23.9063C21.5145 23.7696 21.6232 23.5071 21.6232 23.2172V21.5764H21.9944C23.1003 21.5764 23.9963 20.2693 24 18.6613V9.37441C23.9925 7.76096 23.0928 6.44833 21.9906 6.44833ZM10.5979 13.5584H5.39456C5.2896 13.5584 5.18463 13.6022 5.09466 13.6897L3.44517 15.2977V14.3405C3.44517 13.9085 3.20525 13.5584 2.90909 13.5584H2.00187C1.49203 13.5584 1.07591 12.9513 1.07591 12.2075V2.91516C1.07591 2.17133 1.49203 1.56424 2.00187 1.56424L11.9475 1.56971C12.4574 1.56971 12.8735 2.1768 12.8735 2.92063V12.2075C12.8735 12.9513 12.4574 13.5584 11.9475 13.5584H10.5979ZM22.9166 18.6613C22.9166 19.4051 22.5005 20.0122 21.9869 20.0122H21.0797C20.7835 20.0122 20.5436 20.3622 20.5436 20.7943V21.7514L18.8941 20.138C18.8041 20.0505 18.7029 20.0067 18.5942 20.0067H12.0412C11.5314 20.0067 11.1153 19.3996 11.1153 18.6558V15.1281H11.9438C13.0459 15.1281 13.9456 13.821 13.9456 12.2075V8.00708H21.9869C22.2335 8.00708 22.4699 8.14998 22.6443 8.40436C22.8186 8.65873 22.9166 9.00373 22.9166 9.36347V18.6613Z"
      fill="#4B465C"
    />
    <path
      d="M8.114 9.37394V10.9399H6.476V9.37394H8.114ZM5.0495 5.89994C5.2445 5.39294 5.5415 5.00744 5.9405 4.74344C6.3425 4.47644 6.833 4.34294 7.412 4.34294C7.853 4.34294 8.2385 4.41344 8.5685 4.55444C8.8985 4.69244 9.155 4.87694 9.338 5.10794C9.521 5.33594 9.6125 5.58644 9.6125 5.85944C9.6125 6.14744 9.554 6.39044 9.437 6.58844C9.323 6.78644 9.1805 6.96344 9.0095 7.11944C8.8415 7.27244 8.672 7.42394 8.501 7.57394C8.333 7.72094 8.1905 7.88744 8.0735 8.07344C7.9595 8.25644 7.9025 8.47994 7.9025 8.74394H6.692C6.692 8.41694 6.7385 8.13644 6.8315 7.90244C6.9275 7.66544 7.046 7.45994 7.187 7.28594C7.328 7.10894 7.469 6.94994 7.61 6.80894C7.751 6.66794 7.8695 6.52994 7.9655 6.39494C8.0615 6.25694 8.1095 6.10694 8.1095 5.94494C8.1095 5.76494 8.039 5.62394 7.898 5.52194C7.76 5.41694 7.592 5.36444 7.394 5.36444C7.247 5.36444 7.1045 5.39744 6.9665 5.46344C6.8315 5.52944 6.7085 5.62544 6.5975 5.75144C6.4895 5.87444 6.4025 6.02144 6.3365 6.19244L5.0495 5.89994Z"
      fill="#4B465C"
    />
  </svg>
);

export default SVG;
