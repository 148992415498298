import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Checkbox, Select, Spin } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import ButtonComponent from "../../../../components/ButtonComponent";
import InputComponent from "../../../../components/InputComponent";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import SelectComponent from "../../../../components/SelectComponent";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import DetailText from "../../../../components/DetailText";

const GlobalPropertiesEdit = () => {
	const [propertiesName, setPropertiesName] = useState("");
	const [type, setType] = useState("");
	const [description, setDescription] = useState("");
	const [itemValue, setItemValue] = useState([0]);
	const [propertiesItem, setPropertiesItem] = useState([
		{
			key: "",
			isEncrypt: false,
			value: "",
		},
	]);
	const [previewGP, setPreviewGP] = useState(false);
	const [reset, setReset] = useState(false);
	const navigate = useNavigate();

	const dummy = {
		type: "Type 1",
		description: "Description",
		propertiesName: "Name 1",
		propertiesItem: [
			{
				key: "Key 1",
				isEncrypt: false,
				value: "133.4231.11",
			},
			{
				key: "Key 2",
				isEncrypt: true,
				value: "PGN 123",
			},
		],
	};
	useEffect(() => {
		setValueGP(dummy);
	}, []);
	useEffect(() => {
		if (reset) {
			if (dummy) {
				setValueGP(dummy);
			}
		}
		setReset(false);
	}, [reset]);

	console.log(propertiesItem);
	const setValueGP = (response) => {
		setDescription(response.description);
		setType(response.type);
		setPropertiesName(response.propertiesName);
		setPropertiesItem(response.propertiesItem);
		let x = [];
		dummy.propertiesItem.map(() => x.push(0));
		setItemValue(x);
	};
	const addNewProperties = () => {
		// const x = [...itemValue, itemValue[itemValue.length - 1] + 1];
		const x = [...itemValue, 0];
		propertiesItem.push({
			key: "",
			isEncrypt: false,
			value: "",
		});
		setItemValue(x);
	};
	const removeValueRow = (index) => {
		const a = itemValue.filter((e, i) => i !== index);
		const b = propertiesItem.filter((e, i) => i !== index);
		setItemValue(a);
		setPropertiesItem(b);
	};
	const previewGlobalProperties = () => {
		setPreviewGP(true);
	};
	const handleChangeProperties = (att, value, indexRow) => {
		const newRecord = {
			...propertiesItem[indexRow],
			[att]: value,
		};
		setPropertiesItem((pre) => {
			return pre.map((d, ix) => {
				if (indexRow === ix) {
					return newRecord;
				} else {
					return d;
				}
			});
		});
	};
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb
					pageName={["System Setup", "Global Properties", "Update"]}
				/>
				<NavLink onClick={() => navigate(-1)}>
					<div className="flex align-middle gap-x-2 mb-4">
						<ArrowLeftOutlined
							style={{ fontSize: "24px", color: "#3C6DB2" }}
						></ArrowLeftOutlined>
						<span className="text-dg-blue">Back</span>
					</div>
				</NavLink>
				<BaseContainer header={"CREATE GLOBAL PROPERTIES"}>
					<div>
						<div className="grid grid-cols-3 w-full gap-x-10">
							<div className="flex flex-col gap-y-4 col-span-1">
								<SelectComponent
									label={"Type"}
									mandatory
									value={type}
									onChange={(e) => setType(e)}
								>
									<Select.Option value={"Type 1"}>{"TYPE"}</Select.Option>
								</SelectComponent>
								<InputComponent
									type="text"
									value={propertiesName}
									mandatory
									label={"Properties Name"}
									onChange={(e) => setPropertiesName(e.target.value)}
								></InputComponent>
								<InputComponent
									type="textarea"
									value={description}
									label={"Description"}
									onChange={(e) => setDescription(e.target.value)}
								></InputComponent>
							</div>
							<div className="flex flex-col gap-y-4">
								<p className="text-dg-blue text-sm font-bold">
									Properties Item
								</p>
								{[...itemValue].map((e, i) => (
									<div className="flex w-full gap-x-4">
										<InputComponent
											type="text"
											value={propertiesItem[i].key}
											mandatory
											label={"Key"}
											onChange={(e) =>
												handleChangeProperties("key", e.target.value, i)
											}
										></InputComponent>
										{console.log(propertiesItem)}
										<div className="flex flex-col gap-y-2">
											<InputComponent
												type="text"
												value={propertiesItem[i].value}
												mandatory
												label={"Value"}
												onChange={(e) =>
													handleChangeProperties("value", e.target.value, i)
												}
											></InputComponent>
											<Checkbox
												onChange={(e) =>
													handleChangeProperties(
														"isEncrypt",
														e.target.checked,
														i
													)
												}
											>
												<p className="text-xs text-dg-grey-dark">Encrypt</p>
											</Checkbox>
										</div>
										{i !== 0 && (
											<div className="flex flex-col justify-center pb-5">
												<DeleteOutlined
													onClick={(e) => removeValueRow(i)}
													style={{
														fontSize: "24px",
														background: "#FF7171",
														color: "#FFFFFF",
														padding: "5px",
														borderRadius: "4px",
													}}
												/>
											</div>
										)}
									</div>
								))}
								<div className="-m-2">
									<ButtonComponent
										// icon={<PlusOutlined style={{ fontSize: "24px" }} />}
										type="submit"
										onClick={() => addNewProperties()}
									>
										Add New
									</ButtonComponent>
								</div>
							</div>
						</div>

						<div className="flex justify-end">
							<ButtonComponent
								icon={<DeleteOutlined style={{ fontSize: "16px" }} />}
								onClick={() => setReset(true)}
								type="default"
							>
								Clear
							</ButtonComponent>
							<ButtonComponent
								// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
								onClick={() => previewGlobalProperties()}
								type="submit"
							>
								Save
							</ButtonComponent>
						</div>
					</div>
				</BaseContainer>

				<ModalCustom
					isOpen={previewGP}
					handleCancel={() => setPreviewGP(false)}
					header={"CONFIRMATION"}
					width={800}
				>
					<div className="flex flex-col w-full gap-y-10">
						<div className="grid grid-cols-2">
							<div className="flex flex-col gap-y-2.5">
								<DetailText label={"Type"}>{type}</DetailText>
								<DetailText label={"Properties Name"}>
									{propertiesName}
								</DetailText>
								<DetailText label={"Description"}>{description}</DetailText>
							</div>
							<div className="col-span-1">
								<p className="text-dg-blue text-sm font-bold">
									Properties Item
								</p>
								{propertiesItem.map((e) => (
									<div className="grid grid-cols-3 gap-0 text-xs">
										<p>{e.key}</p>
										<p>:</p>
										<p>{e.value}</p>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="flex justify-end">
						<ButtonComponent onClick={() => setPreviewGP(false)} type="default">
							Cancel
						</ButtonComponent>
						<ButtonComponent
							// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
							onClick={() => console.log("")}
							type="submit"
						>
							Save
						</ButtonComponent>
					</div>
				</ModalCustom>
			</Spin>
		</LayoutMenu>
	);
};

export default GlobalPropertiesEdit;
