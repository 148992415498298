import axios from "axios";
import { configApp } from "../../constants/configApp";
import { tokenHeader } from "../../utils/tokenHeader";

const getAllEmployeePaginate = async (page, pageSize) => {
	try {
		const response = await axios.get(
			configApp.USER_MANAGEMENT_SERVICE +
				`/v1/dbs/api/employees/viewPaging?page=${page}&size=${pageSize}`,
			{ headers: tokenHeader() }
		);
		console.log(pageSize, " = page sizenya");
		return response.data;
	} catch (error) {
		console.log(error, " = error service");
		return error;
	}
};

const getlAllEmployee = async () => {
	try {
		const response = await axios.get(configApp.USER_MANAGEMENT_SERVICE + "//v1/dbs/api/employees/findAllEmployee", { headers: tokenHeader() });
		return response.data;
	} catch (error) {
		console.log(error, ' = error get all employee service');
		return error;
	}
};

const employeeService = {
	getAllEmployeePaginate,
	getlAllEmployee
};

export default employeeService;
