import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 3L21 21"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.584 10.5869C9.80248 11.3678 9.80203 12.6344 10.583 13.4159C11.3639 14.1974 12.6305 14.1978 13.412 13.4169"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.12238 4.5238C8.65777 4.65671 8.38887 5.1411 8.52178 5.60571C8.65469 6.07033 9.13908 6.33922 9.60369 6.20631L9.12238 4.5238ZM12 5.00006L11.9969 5.87506H12V5.00006ZM22 12.0001L22.7597 12.4343C22.9135 12.1653 22.9135 11.835 22.7597 11.5659L22 12.0001ZM18.8545 14.8941C18.5265 15.249 18.5482 15.8026 18.9031 16.1306C19.258 16.4586 19.8116 16.4369 20.1396 16.082L18.8545 14.8941ZM17.8463 18.0745C18.2469 17.8043 18.3526 17.2604 18.0824 16.8598C17.8122 16.4592 17.2684 16.3534 16.8677 16.6236L17.8463 18.0745ZM2 12.0001L1.24035 11.5658C1.08657 11.8349 1.08655 12.1652 1.2403 12.4342L2 12.0001ZM7.12318 7.38319C7.52311 7.11192 7.6274 6.5678 7.35613 6.16788C7.08486 5.76795 6.54075 5.66365 6.14082 5.93493L7.12318 7.38319ZM9.60369 6.20631C10.3819 5.98371 11.1876 5.87219 11.9969 5.87505L12.0031 4.12506C11.0289 4.12161 10.0591 4.25585 9.12238 4.5238L9.60369 6.20631ZM12 5.87506C15.58 5.87506 18.6682 7.93323 21.2403 12.4342L22.7597 11.5659C19.9979 6.73289 16.42 4.12506 12 4.12506V5.87506ZM21.2404 11.5658C20.489 12.8803 19.6927 13.9873 18.8545 14.8941L20.1396 16.082C21.0834 15.0608 21.9551 13.8418 22.7597 12.4343L21.2404 11.5658ZM16.8677 16.6236C15.3788 17.6278 13.7647 18.1251 12 18.1251V19.8751C14.1193 19.8751 16.0732 19.2703 17.8463 18.0745L16.8677 16.6236ZM12 18.1251C8.42001 18.1251 5.33182 16.0669 2.7597 11.5659L1.2403 12.4342C4.00218 17.2672 7.57999 19.8751 12 19.8751V18.1251ZM2.75965 12.4343C4.08316 10.1189 5.54462 8.45393 7.12318 7.38319L6.14082 5.93493C4.28138 7.19619 2.65484 9.09124 1.24035 11.5658L2.75965 12.4343Z"
      fill="#4B465C"
    />
  </svg>
);

export default SVG;
