import { PRODUCT_PROMO_ELEMENTS } from "./pp_elements";
import { PRODUCT_PROMO_ROUTES } from "./pp_routes";

export const product_promo = [
	// product
	{
		path: PRODUCT_PROMO_ROUTES.VIEW_PRODUCT,
		element: PRODUCT_PROMO_ELEMENTS.VIEW_PRODUCT_PAGE,
	},
	{
		path: PRODUCT_PROMO_ROUTES.CREATE_PRODUCT,
		element: PRODUCT_PROMO_ELEMENTS.CREATE_PRODUCT_PAGE,
	},
];
