import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import 'antd/dist/antd.min.css'
import "./app.less";
import "./tailwind.css";
import AppRoutes from "./routes/appRoutes";

const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};
export default App;
