import React from "react";
import IconAccountManagement from "./IconAccountManagement";
import IconActionCreate from "./IconActionCreate";
import IconActionDropdown from "./IconActionDropdown";
import IconAlertCicrle from "./IconAlertCircle";
import IconAlertTriangle from "./IconAlertTriangle";
import IconApprove from "./IconApprove";
import IconArrowNarrowLeft from "./IconArrowNarrowLeft";
import IconBilling from "./IconBilling";
import IconCalendarEvent from "./IconCalendarEvent";
import IconClear from "./IconClear";
import IconCross from "./IconCross";
import IconDelete from "./IconDelete";
import IconDetail from "./IconDetail";
import IconDownload from "./IconDownload";
import IconDropdown from "./IconDropdown";
import IconEdit from "./IconEdit";
import IconEye from "./IconEye";
import IconEyeOff from "./IconEyeOff";
import IconFilter from "./IconFilter";
import IconHome from "./IconHome";
import IconInactive from "./IconInactive";
import IconInvoice from "./IconInvoice";
import IconLock from "./IconLock";
import IconLogHistory from "./IconLogHistory";
import IconPayment from "./IconPayment";
import IconProduct from "./IconProduct";
import IconRating from "./IconRating";
import IconReject from "./IconReject";
import IconReport from "./IconReport";
import IconRequestApproval from "./IconRequestApproval";
import IconSorting from "./IconSorting";
import IconSuccess from "./IconSuccess";
import IconSupport from "./IconSupport";
import IconSystemSetup from "./IconSystemSetup";
import IconUndo from "./IconUndo";
import IconUnlock from "./IconUnlock";
import IconUpload from "./IconUpload";
import IconUserManagement from "./IconUserManagement";
import IconButtonBack from "./IconButtonBack";
import IconButtonClear from "./IconButtonClear";
import IconButtonCreate from "./IconButtonCreate";
import IconButtonDownload from "./IconButtonDownload";
import IconButtonReset from "./IconButtonReset";
import IconSwapPosition from "./IconSwapPosition";

const Icon = (props) => {
  switch (props.name) {
    case "IconAccountManagement":
      return <IconAccountManagement {...props} />;
    case "IconActionCreate":
      return <IconActionCreate {...props} />;
    case "IconActionDropdown":
      return <IconActionDropdown {...props} />;
    case "IconAlertCicrle":
      return <IconAlertCicrle {...props} />;
    case "IconAlertTriangle":
      return <IconAlertTriangle {...props} />;
    case "IconApprove":
      return <IconApprove {...props} />;
    case "IconArrowNarrowLeft":
      return <IconArrowNarrowLeft {...props} />;
    case "IconBilling":
      return <IconBilling {...props} />;
    case "IconCalendarEvent":
      return <IconCalendarEvent {...props} />;
    case "IconClear":
      return <IconClear {...props} />;
    case "IconCross":
      return <IconCross {...props} />;
    case "IconDelete":
      return <IconDelete {...props} />;
    case "IconDetail":
      return <IconDetail {...props} />;
    case "IconDownload":
      return <IconDownload {...props} />;
    case "IconDropdown":
      return <IconDropdown {...props} />;
    case "IconEdit":
      return <IconEdit {...props} />;
    case "IconEye":
      return <IconEye {...props} />;
    case "IconEyeOff":
      return <IconEyeOff {...props} />;
    case "IconFilter":
      return <IconFilter {...props} />;
    case "IconHome":
      return <IconHome {...props} />;
    case "IconInactive":
      return <IconInactive {...props} />;
    case "IconInvoice":
      return <IconInvoice {...props} />;
    case "IconLock":
      return <IconLock {...props} />;
    case "IconLogHistory":
      return <IconLogHistory {...props} />;
    case "IconPayment":
      return <IconPayment {...props} />;
    case "IconProduct":
      return <IconProduct {...props} />;
    case "IconRating":
      return <IconRating {...props} />;
    case "IconReject":
      return <IconReject {...props} />;
    case "IconReport":
      return <IconReport {...props} />;
    case "IconRequestApproval":
      return <IconRequestApproval {...props} />;
    case "IconSorting":
      return <IconSorting {...props} />;
    case "IconSuccess":
      return <IconSuccess {...props} />;
    case "IconSupport":
      return <IconSupport {...props} />;
    case "IconSystemSetup":
      return <IconSystemSetup {...props} />;
    case "IconUndo":
      return <IconUndo {...props} />;
    case "IconUnlock":
      return <IconUnlock {...props} />;
    case "IconUpload":
      return <IconUpload {...props} />;
    case "IconUserManagement":
      return <IconUserManagement {...props} />;
    case "IconButtonBack":
      return <IconButtonBack {...props} />;
    case "IconButtonClear":
      return <IconButtonClear {...props} />;
    case "IconButtonCreate":
      return <IconButtonCreate {...props} />;
    case "IconButtonDownload":
      return <IconButtonDownload {...props} />;
    case "IconButtonReset":
      return <IconButtonReset {...props} />;
    case "IconSwapPosition":
      return <IconSwapPosition {...props} />;
    default:
      return;
  }
};

export default Icon;
