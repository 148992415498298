import axios from "axios";
import { configApp } from "../../constants/configApp";
import bcrypt from "bcryptjs"

const salt = bcrypt.genSaltSync(10)
const register = (username, email, password) => {
  const hashedPassword = bcrypt.hashSync(password, salt)
  return axios.post(configApp.USER_MANAGEMENT_SERVICE + "/api/authenticate", {
    username,
    email,
    // password,
    hashedPassword,
  });
};

const login = async (username, password, remember) => {
  const hashedPassword = bcrypt.hashSync(password, salt)
  const response = await axios.post(
    // configApp.USER_MANAGEMENT_SERVICE + "/api/authenticate",
    configApp.USER_MANAGEMENT_SERVICE + "/v1/dbs/api/auth/login",
    {
      username,
      password,
      // hashedPassword
      // mode:'cors'
    }
  );
  if (response.data.result) {
    if (remember === true) {
      localStorage.setItem("token", JSON.stringify(response.data));
    } else {
      window.sessionStorage.setItem("token", JSON.stringify(response.data));
    }
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem("token") || window.sessionStorage.removeItem("token");
};

const getListPosition = (username, password) => {
  const hashedPassword = bcrypt.hashSync(password, salt)
  return axios.post(configApp.USER_MANAGEMENT_SERVICE + "/v1/dbs/api/auth/login", {
    username,
    password,
    // hashedPassword,
  });
};

const authService = {
  register,
  login,
  logout,
  getListPosition,
};

export default authService;
