import customerService from "../services/customer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
const initialState = {
	list: {},
};

export const getListCustomer = createAsyncThunk(
	"GET_LIST_CUSTOMER",
	async (_, thunkAPI) => {
		try {
			const response = await customerService.listCustomer();
			thunkAPI.dispatch(setMessage(response.data.message));
			// console.log(response.data.result, "lbldas");
			return response.data;
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			thunkAPI.dispatch(setMessage(message));
			return thunkAPI.rejectWithValue();
		}
	}
);

const customerSlice = createSlice({
	name: "customer",
	initialState,
	extraReducers: {
		[getListCustomer.fulfilled]: (state, action) => {
			state.list = action.payload.result;
		},
		[getListCustomer.rejected]: (state, action) => {
			state.list = null;
		},
	},
});

const { reducer } = customerSlice;
export default reducer;
