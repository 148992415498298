import { PageHeader, Table } from 'antd';
import React, { useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import DetailText from '../../../../components/DetailText';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';
import { ArrowLeftOutlined, UnorderedListOutlined, EditOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import ButtonComponent from '../../../../components/ButtonComponent';
import ModalCustom from '../../../../components/Modal/ModalCustom';
import SelectComponent from '../../../../components/SelectComponent';
import HierarchyComponent from '../../../../components/HierarchyComponent';
import { USER_ROUTES } from '../../../../routes/user_management/user_routes';




const PositionHierarchyForm = (props) => {
    const { type, id } = props;
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [siblings, setSiblings] = useState([]);

    const handleOpen = () => { };
    const handleCancel = () => {
        setOpenModal(false)
    }

    const detailText = [
        {
            label: 'Hierarchy Name',
            text: 'Hierarchy 2023'
        },
        {
            label: 'Status',
            text: 'Draft'
        },
        {
            label: 'Remark',
            text: 'Versi Awal'
        },
        {
            label: 'Start Date',
            text: '1 Januari 2022'
        },
        {
            label: 'End Date',
            text: '1 Januari 2024'
        },
        {
            label: 'Created At',
            text: '20 Desember 2021'
        },
        {
            label: 'Latest Date',
            text: '22 Desember 2021'
        },
        {
            label: 'Updated By',
            text: 'Annisa'
        },
        {
            label: 'Created By',
            text: 'Putri'
        },
    ]
    const data = [
        {
            name: 'CM2 Bogor',
            on_assignment: '-',
            parent: 'CCR Bogor',
            status: 'Available',
        },
        {
            name: 'CM2 Bogor',
            on_assignment: '-',
            parent: 'CCR Bogor',
            status: 'Available',
        },
        {
            name: 'CM2 Bogor',
            on_assignment: '-',
            parent: 'CCR Bogor',
            status: 'Available',
        },
        {
            name: 'CM2 Bogor',
            on_assignment: '-',
            parent: 'CCR Bogor',
            status: 'Available',
        },
        {
            name: 'CM2 Bogor',
            on_assignment: '-',
            parent: 'CCR Bogor',
            status: 'Available',
        },
    ]
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: 'center'
        },
        {
            title: "On Assignment",
            dataIndex: "on_assignment",
            key: "on_assignment",
            align: 'center'
        },
        {
            title: "Parent",
            dataIndex: "parent",
            key: "parent",
            align: 'center'
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: 'center'
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            align: 'center',
            width :'10%',
            render: (r) => {
                return (
                    <div className={'flex'}>
                        {type === "update" &&
                            <NavLink to={USER_ROUTES.DETAIL_POSITION}>
                                <ButtonComponent icon={<UnorderedListOutlined />} />
                            </NavLink>
                        }
                        <NavLink to={USER_ROUTES.UPDATE_POSITION}>
                            <ButtonComponent icon={<EditOutlined />} />
                        </NavLink>
                        <ButtonComponent icon={<CloseCircleOutlined style={{
                            color: "#FF7171",
                            fontSize: 24,
                            justifyItems: "center",
                        }} />} onClick={() => setOpenModal(true)}/>
                            
                    </div>
                )
            }
        },
    ]
    const datas = [
        {
            name: "Muhammad",
            division: "CEO",
            children: [
                {
                    name: "Budi",
                    division: "Production",
                    children: [
                        {
                            name: "John",
                            division: "Fabrication",
                            children: [
                                {
                                    name: "Binar",
                                    division: "Fabrication",
                                },
                            ],
                        },
                        {
                            name: "Nina",
                            division: "Assembly",
                            children: [
                                {
                                    name: "Johan",
                                    division: "Assembly",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "Ibas",
                    division: "Marketing",
                    children: [
                        {
                            name: "Surya",
                            division: "Marketing",
                            children: [
                                {
                                    name: "Hadi",
                                    division: "Marketing",
                                },
                            ],
                        },
                        {
                            name: "Gayatri",
                            division: "Marketing",
                            children: [
                                {
                                    name: "RIma",
                                    division: "Marketing",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];

    // to render custom node
    const renderCustomNode = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps,

    }) => (
        <g onClick={toggleNode}>
            <circle r={5} className={"bg-black"}></circle>
            <foreignObject {...foreignObjectProps}>
                <div className="flex bg-[#CEDBEC] justify-center rounded-lg m-2">
                    <div className="flex-col items-center my-5">
                        <div className={"flex gap-3 justify-center mx-auto w-full"}>
                            {/* <Avatar icon={<UserOutlined />} /> */}
                            <div className="flex-col items-center justify-center">
                                <p className="text-black leading-none text-[12px] text-center">
                                    {nodeDatum.name}
                                </p>
                                <p className="text-gray-400 text-[12px] text-center">
                                    {nodeDatum.division}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </foreignObject>
        </g>
    );

    const breadCrumbRoutes = [
        {
            path: '',
            breadcrumbName: 'User Management'
        },
        {
            path: USER_ROUTES.VIEW_POSITION,
            breadcrumbName: 'Position Hierarchy'
        },
        {
            path: type === "update" ? USER_ROUTES.UPDATE_POSITION : USER_ROUTES.CREATE_POSITION,
            breadcrumbName: type === "update" ? "Update Position" : "Create Position"
        },
    ]
    return (
        <LayoutMenu>
            <PageHeader breadcrumb={<BreadCrumb routes={breadCrumbRoutes} />} />
            <NavLink onClick={() => navigate(-1)}>
                <div className="flex align-middle gap-x-2 mb-4">
                    <ArrowLeftOutlined
                        style={{ fontSize: "24px", color: "#3C6DB2" }}
                    ></ArrowLeftOutlined>
                    <span className="text-dg-blue">Back</span>
                </div>
            </NavLink>
            {type === "update" &&
                <BaseContainer header={'POSITION HIERARCHY INFORMATION'}>
                    <div className={'h-[60px] w-full flex flex-col flex-wrap'}>
                        {detailText.map((index, key) => {
                            return (
                                <DetailText label={index.label}>{index.text}</DetailText>
                            )
                        })}
                    </div>
                </BaseContainer>
            }
            <BaseContainer header={'POSITION HIERARCHY'}>
                <div className={'w-full flex'}>
                    <div className={'w-2/4 flex flex-col gap-5 mx-8 justify-center'}> 
                        <div>
                            <SelectComponent
                                type="number"
                                label={"Position Name"}
                                mandatory
                            // options={{label:}}
                            ></SelectComponent>
                            <SelectComponent type="number" label={"Parent Position"}></SelectComponent>
                            <SelectComponent type="number" label={"Remark"}></SelectComponent>
                        </div>
                        <div className={'w-full flex justify-end'}>
                            <ButtonComponent icon={<DeleteOutlined/>} type={'default'}>Clear</ButtonComponent>
                            <ButtonComponent type={'submit'}>Save</ButtonComponent>
                        </div>
                        
                    </div>
                    <div className={'w-full'}>
                            <HierarchyComponent
                            data={datas}
                            nodeWidth={150}
                            nodeHeight={400}
                            collapse={true}
                            zoomable={true}
                            containerHeight={'50vh'}
                            renderCustomNode={renderCustomNode}
                            />
                    </div>
                </div>
            </BaseContainer>
            <BaseContainer header={'POSITION LIST'}>
                <div className={'w-full'}>
                    <Table columns={columns} dataSource={data} />
                </div>
            </BaseContainer>

            <ModalCustom header={"INACTIVATE HIERARCHY"} isOpen={openModal} handleCancel={handleCancel}>
                <div className={"flex flex-col px-8 py-4"}>
                    <div className="w-full justify-center flex my-6">
                        <span className={"text-xl text-[#3C6DB2]"}>
                            {" "}
                            Are you sure want to delete position?
                        </span>
                    </div>
                    <div className={"w-full justify-center flex"}>
                        <ButtonComponent
                            type={"default"}
                            onClick={() => setOpenModal(false)}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent type={"submit"}>
                            Delete
                        </ButtonComponent>
                    </div>
                </div>
            </ModalCustom>
        </LayoutMenu>
    );
}

export default PositionHierarchyForm;
