import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../app/NotFound';
import LogIn from '../app/pages/Authentication/LogIn';
import ForgotPassword from "../app/pages/Authentication/ForgotPassword"
import PrivateRoute from '../components/PrivateRoute';
import NewPassword from '../app/pages/Authentication/NewPassword';
import UnderConstruction from '../app/UnderConstruction';
import { index } from './routes';
import Position from '../app/pages/Authentication/Position';
import Profile from '../app/Profile';
import UpdateProfile from '../app/pages/UpdateProfile';
import ChangePassword from '../app/pages/ChangePassword';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Route */}
      <Route path="/" element={<LogIn/>} />
      <Route path="/forgot-password" element={<ForgotPassword/>} />
      <Route path="/new-password" element={<NewPassword/>} />
      <Route path="/maintenance" element={<UnderConstruction/>} />
      <Route path="/profile" element={<Profile/>} />
      <Route path="/update-profile" element={<UpdateProfile/>} />
      <Route path="/change-password" element={<ChangePassword/>} />
     

      {/* Protected Route */}
      <Route element={<PrivateRoute />}>
        {
          index.map((menus, key) => {
          return (
              <Route path={menus.path} key={key} element={menus.element} />
            )
          })
        }
      </Route>

      {/* Not Found  */}
      <Route path="*" element={<NotFound />} />
      {/* mode maintenancenya true maka tampilin ini, dan block tampilan yang lain */}
      <Route path="/under-construction" element={<UnderConstruction />} />
    </Routes>
  );
}

export default AppRoutes;
