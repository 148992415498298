import React from "react";
import { Button } from "antd";

const ButtonComponent = (props) => {
	const { disabled, children, icon, onClick = () => {}, type, border } = props;

	let style = "flex justify-center";
	// if (type === "submit") style = " submit-button flex justify-center"; // SUBMIT
	// if (type === "default") style = " submit-button flex justify-center";
	// if (type === "reject") style = " submit-button flex justify-center";
	// if (type === "approve") style = " submit-button flex justify-center";
	return (
		<div>
			<Button
				onClick={onClick}
				icon={icon ? icon : null}
				className={`m-0.5 ${style}`}
				type={type}
				disabled={disabled}
				style={{
					borderColor: `${border === false ? "#0075bf00" : "var(--primary)"}`,
				}}
			>
				<div className={children ? `mx-2` : ``}>{children}</div>
			</Button>
		</div>
	);
};

export default ButtonComponent;
