import { Select } from "antd";
import React from "react";
import InputLabel from "./InputLabel";

const SelectComponent = (props) => {
	const {
		label,
		mandatory,
		onChange = () => {},
		value,
		children,
		options,
		placeholder,
		mode,
		tagRender,
	} = props;
	const wrapper = "flex flex-col";
	const style = {
		width: "auto",
		borderRadius: "5px",
		boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
	};

	return (
		<div className={wrapper}>
			<InputLabel text={label} mandatory={mandatory}></InputLabel>
			<Select
				value={value ? value : undefined}
				style={style}
				placeholder={placeholder}
				onChange={onChange}
				mode={mode}
				tagRender={tagRender}
			>
				{children}
			</Select>
		</div>
	);
};

export default SelectComponent;
