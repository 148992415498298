import { Modal } from "antd";
import React from "react";

const ModalCustom = (props) => {
	const {
		isOpen,
		handleCancel = () => {},
		handleOk = () => {},
		header,
		children,
		width,
	} = props;

	return (
		<Modal
			open={isOpen}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={false}
			className={"modal-custom"}
			centered={true}
			width={width}
		>
			<div className={"flex flex-col w-full p-4"}>
				{/* header section */}
				<div className={"rounded-tl-[5px] rounded-tr-[5px]"}>
					<div className={"flex gap-x-1.5 items-center"}>
						<div className="p-2.5 modal-header-box rounded-sm"></div>
						<span className="text-primary">{header}</span>
					</div>
				</div>

				{/* content section */}
				<div className="">{children}</div>
			</div>
		</Modal>
	);
};

export default ModalCustom;
