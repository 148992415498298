import React from "react";

const BaseContainer = ({ header, children }) => {
	return (
		<div className="drop-shadow-lg bg-white rounded-lg my-4">
			<div className="p-4">
				<div className="text-primary text-xs font-bold">{header}</div>
			</div>
			<div className="flex gap-x-2 p-4 rounded-b">{children}</div>
		</div>
	);
};

export default BaseContainer;
