import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8159_107975)">
      <path
        d="M10.875 22.53H1.67249C1.37624 22.53 1.13624 22.29 1.13624 21.9937V0.536206C1.13249 0.239956 1.37249 -4.39435e-05 1.67249 -4.39435e-05H11.5837C11.7262 -4.39435e-05 12.3987 -0.10125 12.5 0L18.21 6.40496C18.3112 6.50621 18.3675 6.64121 18.3675 6.78371V15.1875C18.3675 15.4837 18.1275 15.7237 17.8312 15.7237C17.535 15.7237 17.295 15.4837 17.295 15.1875V7.00496L11.3625 1.07621H2.20874V21.4575H10.875C11.1712 21.4575 11.4112 21.6975 11.4112 21.9937C11.4112 22.29 11.1712 22.53 10.875 22.53Z"
        fill="#4B465C"
        stroke="#4B465C"
        stroke-width="0.75"
      />
      <path
        d="M11.4187 7.38C11.2762 7.38 11.1412 7.32375 11.04 7.22625C10.9387 7.125 10.8825 6.98625 10.8825 6.84375V0.53625C10.8825 0.24 11.1225 0 11.4187 0C11.715 0 11.955 0.24 11.955 0.53625V6.3H17.335C17.62 6.30375 17.8312 6.5475 17.835 6.84375C17.8387 7.14 17.9662 7.31625 17.67 7.32L11.4225 7.38H11.4187ZM8.46 6.41625H3.79875C3.5025 6.41625 3.2625 6.17625 3.2625 5.88C3.2625 5.58375 3.5025 5.34375 3.79875 5.34375H8.46C8.75625 5.34375 8.99625 5.58375 8.99625 5.88C8.99625 6.17625 8.75625 6.41625 8.46 6.41625ZM9.8175 12.8888H3.79875C3.5025 12.8888 3.2625 12.6487 3.2625 12.3525C3.2625 12.0562 3.5025 11.8162 3.79875 11.8162H9.8175C10.1137 11.8162 10.3537 12.0562 10.3537 12.3525C10.3537 12.6487 10.1175 12.8888 9.8175 12.8888ZM11.085 9.81H6.42375C6.1275 9.81 5.8875 9.57 5.8875 9.27375C5.8875 8.9775 6.1275 8.7375 6.42375 8.7375H11.085C11.3812 8.7375 11.6212 8.9775 11.6212 9.27375C11.6212 9.57 11.3812 9.81 11.085 9.81Z"
        fill="#4B465C"
        stroke="#4B465C"
        stroke-width="0.75"
      />
      <path
        d="M22.065 23.605C21.9263 23.605 21.7913 23.5525 21.6863 23.4475L12.1575 13.9187C11.9475 13.7087 11.9475 13.3675 12.1575 13.1575C12.3675 12.9475 12.7088 12.9475 12.9188 13.1575L22.4475 22.6862C22.6575 22.8962 22.6575 23.2375 22.4475 23.4475C22.3425 23.5525 22.2038 23.605 22.065 23.605Z"
        fill="#FF0000"
        stroke="#FF0000"
        stroke-width="0.5"
      />
      <path
        d="M12.5362 23.605C12.3975 23.605 12.2625 23.5525 12.1575 23.4475C11.9475 23.2375 11.9475 22.8962 12.1575 22.6862L21.6862 13.1575C21.8962 12.9475 22.2375 12.9475 22.4475 13.1575C22.6575 13.3675 22.6575 13.7087 22.4475 13.9187L12.915 23.4475C12.81 23.5525 12.6712 23.605 12.5362 23.605Z"
        fill="#FF0000"
        stroke="#FF0000"
        stroke-width="0.75"
      />
    </g>
    <defs>
      <clipPath id="clip0_8159_107975">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
