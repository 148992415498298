import axios from "axios";
import { configApp } from "../../constants/configApp";
import { tokenHeader } from "../../utils/tokenHeader";

const listCustomer = async () => {
	const response = await axios.post(
		configApp.ACCOUNT_SERVICE + "/api/customers/list",
		{
			page: "1",
			row: "99999999",
		},
		{
			headers: tokenHeader(),
		}
	);
	return response;
};

const customerService = {
	listCustomer,
};

export default customerService;
