import React, { useRef, useState } from "react";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { Spin, Table, Input, Checkbox, Tooltip, Popover } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import BreadCrumb from "../../../../components/BreadCrumb";
import {
  ArrowLeftOutlined,
  FilterOutlined,
  UnorderedListOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import BaseContainer from "../../../../components/BaseContainer";
import DetailText from "../../../../components/DetailText";
import ButtonComponent from "../../../../components/ButtonComponent";
import StatusComponent from "../../../../components/StatusComponent";
import SVGIcon from "../../../../assets/Icon/index";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import InputComponent from "../../../../components/InputComponent";
import SelectComponent from "../../../../components/SelectComponent";

const DetailGlobalType = () => {
  const navigate = useNavigate();
  const searchInput = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [modalInactive, setModalInactive] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);

  const [displayText, setDisplayText] = useState("");
  const [value, setValue] = useState("");
  const [order, setOrder] = useState(0);

  const content = (
    <span className="text-[11px] font-bold  text-[#3C6DB2]">
      Please fill mandatory form
    </span>
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const dataSource = [
    {
      key: "1",
      displayText: "LDAP",
      value: "ldap",
      order: 1,
      status: "ACTIVE",
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      align: "center",
      key: "name",
    },
    {
      title: "DISPLAY TEXT",
      dataIndex: "displayText",
      align: "center",
      key: "displayText",
      ...getColumnSearchProps("displayText"),
    },
    {
      title: "VALUE",
      dataIndex: "value",
      align: "center",
      key: "value",
      ...getColumnSearchProps("value"),
    },
    {
      title: "ORDER",
      dataIndex: "order",
      align: "center",
      key: "order",
      ...getColumnSearchProps("order"),
    },
    {
      title: "ACTIVATE",
      dataIndex: "address",
      align: "center",
      key: "address",
      ...getColumnSearchProps("address"),
      render: () => {
        return (
          <div className="flex justify-center">
            <Checkbox
            // onChange={onChange}
            ></Checkbox>
          </div>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (status) => (
        <div>
          {status === "ACTIVE" && (
            <StatusComponent color="green">{status}</StatusComponent>
          )}

          {status === "INACTIVE" && (
            <StatusComponent color="red">{status}</StatusComponent>
          )}
        </div>
      ),
    },
    {
      title: "ACTIONS",
      dataIndex: "address",
      render: () => {
        return (
          <div className="flex justify-center gap-4">
            <Tooltip title="Detail">
              <UnorderedListOutlined
                style={{
                  color: "#0880AE",
                  fontSize: 24,
                  justifyItems: "center",
                }}
                onClick={() => setModalDetail(true)}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <div>
                <SVGIcon
                  name="IconEdit"
                  width={24}
                  style={{
                    color: "#0880AE",
                    fontSize: 24,
                    justifyItems: "center",
                  }}
                  onClick={() => setModalUpdate(true)}
                />
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <CloseCircleOutlined
                onClick={() => {
                  // info(id);
                  setModalInactive(true);
                  // console.log(id);
                }}
                style={{
                  color: "#FF7171",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
          </div>
        );
      },
      key: "action",
    },
  ];

  const clearForm = () => {
    setDisplayText("");
    setValue("");
    setOrder(0);
  };

  const submitForm = () => {
    const body = {
      displayText: displayText,
      value: value,
      order: order,
    };
    console.log(body);
    setModalConfirmation(false);
    // setOpenModal(true);
  };

  return (
    <LayoutMenu>
      <Spin spinning={false} className="w-full top-20" tip="Loading">
        <BreadCrumb
          pageName={["System Setup ", "Global Type", "Detail Global Type"]}
        />

        <NavLink onClick={() => navigate(-1)}>
          <div className="flex align-middle gap-x-2 mb-4">
            <ArrowLeftOutlined
              style={{ fontSize: "24px", color: "#3C6DB2" }}
            ></ArrowLeftOutlined>
            <span className="text-[#3C6DB2]">Back</span>
          </div>
        </NavLink>

        <BaseContainer header={"DETAIL GLOBAL TYPE"}>
          <div className="flex flex-col w-full gap-y-8">
            <div className="grid grid-cols-2 gap-y-2.5">
              <DetailText label={"Group Name"}></DetailText>
              <DetailText label={"Created At"}></DetailText>
              <DetailText label={"Sort By"}></DetailText>
              <DetailText label={"Created By"}></DetailText>
              <DetailText label={"Status"}></DetailText>
              <DetailText label={"Latest Update"}></DetailText>
              <DetailText label={"Description"}></DetailText>
              <DetailText label={"Update By"}></DetailText>
            </div>

            <div className="flex mt-4 w-full justify-end">
              <ButtonComponent
                onClick={() => setModalAdd(true)}
                type={"submit"}
              >
                New
              </ButtonComponent>
            </div>

            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{
                position: ["bottomCenter"],
              }}
              size="small"
              total={50}
              showSizeChanger
            />
          </div>
        </BaseContainer>

        <ModalCustom
          isOpen={modalInactive}
          handleCancel={() => setModalInactive(false)}
          header={"INACTIVE GLOBAL TYPE"}
          width={800}
        >
          <div className="flex flex-col w-full">
            <h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
              Are you sure you want to inactive global type value
            </h1>

            <div className="flex mt-4 w-full justify-center">
              <ButtonComponent
                // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"default"}
                onClick={() => setModalInactive(false)}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                // onClick={() => setModalConfirmation(true)}
                type={"submit"}
              >
                Submit
              </ButtonComponent>
            </div>
          </div>
        </ModalCustom>

        <ModalCustom
          isOpen={modalAdd}
          handleCancel={() => setModalAdd(false)}
          header={"GLOBAL TYPE VALUE"}
          width={500}
        >
          <div className="grid grid-cols-1 w-full">
            <div className="grid grid-cols-1 w-full gap-8">
              <DetailText label={"Group Name"}>{}</DetailText>
              <InputComponent
                type="text"
                label={"Display Text"}
                mandatory
                onChange={(e) => setDisplayText(e.target.value)}
                value={displayText}
              ></InputComponent>
              <InputComponent
                type="text"
                label={"Value"}
                mandatory
                onChange={(e) => setValue(e.target.value)}
                value={value}
              ></InputComponent>
              <InputComponent
                type="number"
                label={"Order"}
                mandatory
                onChange={(e) => setOrder(e.target.value)}
                value={order}
              ></InputComponent>
            </div>

            <div className="flex mt-4 w-full justify-end">
              <ButtonComponent
                icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"default"}
                onClick={() => {
                  clearForm();
                }}
              >
                Clear
              </ButtonComponent>
              {displayText && value && order ? (
                <ButtonComponent
                  onClick={() => {
                    setModalConfirmation(true);
                    setModalAdd(false);
                  }}
                  type={"submit"}
                >
                  Save
                </ButtonComponent>
              ) : (
                <Popover placement="top" content={content} trigger="hover">
                  <div>
                    <ButtonComponent
                      onClick={() => {
                        setModalConfirmation(true);
                        setModalAdd(false);
                      }}
                      type={"submit"}
                      disabled
                    >
                      Save
                    </ButtonComponent>
                  </div>
                </Popover>
              )}
            </div>
          </div>
        </ModalCustom>

        <ModalCustom
          isOpen={modalConfirmation}
          handleCancel={() => setModalConfirmation(false)}
          header={"CONFIRMATION"}
          width={500}
        >
          <div className="flex flex-col w-full gap-y-10">
            <div className="grid grid-cols-1 gap-y-2.5">
              <DetailText label={"Display Text"}>{displayText}</DetailText>
              <DetailText label={"Value"}>{value}</DetailText>
              <DetailText label={"Order"}>{order}</DetailText>
            </div>

            <div className="flex mt-4 w-full justify-end">
              <ButtonComponent
                // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"default"}
                onClick={() => {
                  setModalConfirmation(false);
                  setModalAdd(true);
                }}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent onClick={() => submitForm()} type={"submit"}>
                Submit
              </ButtonComponent>
            </div>
          </div>
        </ModalCustom>

        <ModalCustom
          isOpen={modalDetail}
          handleCancel={() => setModalDetail(false)}
          header={"GLOBAL TYPE VALUE"}
          width={500}
        >
          <div className="flex flex-col w-full gap-y-10">
            <div className="grid grid-cols-1 gap-y-2.5">
              <DetailText label={"Group Name"}>{}</DetailText>
              <DetailText label={"Text"}>{}</DetailText>
              <DetailText label={"Value"}>{}</DetailText>
              <DetailText label={"Order"}>{}</DetailText>
              <DetailText label={"Status"}>{}</DetailText>
              <DetailText label={"Created At"}>{}</DetailText>
              <DetailText label={"Created By"}>{}</DetailText>
              <DetailText label={"Latest Update"}>{}</DetailText>
              <DetailText label={"Updated By"}>{}</DetailText>
            </div>

            <div className="flex mt-4 w-full justify-end">
              <ButtonComponent
                // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"submit"}
                onClick={() => {
                  setModalDetail(false);
                }}
              >
                Close
              </ButtonComponent>
            </div>
          </div>
        </ModalCustom>

        <ModalCustom
          isOpen={modalUpdate}
          handleCancel={() => setModalUpdate(false)}
          header={"UPDATE GLOBAL TYPE VALUE"}
          width={500}
        >
          <div className="grid grid-cols-1 w-full">
            <div className="grid grid-cols-1 w-full gap-8">
              <DetailText label={"Group Name"}>{}</DetailText>
              <InputComponent
                type="text"
                label={"Display Text"}
                mandatory
                onChange={(e) => setDisplayText(e.target.value)}
                value={displayText}
              ></InputComponent>
              <InputComponent
                type="text"
                label={"Value"}
                mandatory
                onChange={(e) => setValue(e.target.value)}
                value={value}
              ></InputComponent>
              <InputComponent
                type="number"
                label={"Order"}
                mandatory
                onChange={(e) => setOrder(e.target.value)}
                value={order}
              ></InputComponent>
            </div>

            <div className="flex mt-4 w-full justify-end">
              <ButtonComponent
                icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"default"}
                onClick={() => {
                  clearForm();
                }}
              >
                Clear
              </ButtonComponent>
              {displayText && value && order ? (
                <ButtonComponent
                  onClick={() => {
                    setModalConfirmation(true);
                    setModalAdd(false);
                  }}
                  type={"submit"}
                >
                  Save
                </ButtonComponent>
              ) : (
                <Popover placement="top" content={content} trigger="hover">
                  <div>
                    <ButtonComponent
                      onClick={() => {
                        setModalConfirmation(true);
                        setModalAdd(false);
                      }}
                      type={"submit"}
                      disabled
                    >
                      Save
                    </ButtonComponent>
                  </div>
                </Popover>
              )}
            </div>
          </div>
        </ModalCustom>
      </Spin>
    </LayoutMenu>
  );
};

export default DetailGlobalType;
