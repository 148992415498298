import React from "react";

const CardComponent = (props) => {
	const { header, children, cols } = props;
	return (
		<div className="bg-detail p-4 my-4 rounded-md">
			<div className="text-primary">{header}</div>
			<div className={`grid grid-cols-${cols} gap-y-2.5 py-1`}>{children}</div>
		</div>
	);
};

export default CardComponent;
