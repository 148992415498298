import React, { useState, useEffect } from "react";
import { Checkbox, Form, Input, Card } from "antd";
import {
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { bgLogin, pgnLogo } from "../../../assets/img";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/slices/auth";
import { clearMessage } from "../../../redux/slices/message";
import { LoadCanvasTemplate, loadCaptchaEnginge } from "react-simple-captcha";
import ButtonComponent from "../../../components/ButtonComponent";
import { ModalError } from "../../../components/Modal/ModalPopUp";

const LogIn = () => {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    !isLoggedIn && loadCaptchaEnginge(8);
  }, []);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleLogin = (formValue) => {
    const { username, password, remember } = formValue;
    setLoading(true);
    dispatch(login({ username, password, remember }))
      .unwrap()
      .then(() => {
        navigate("/position");
        console.log("User: ", user);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        setOpenModal(true);
        console.log("1");
        // ModalError();
        // <ModalFailed/>
      });
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  console.log(isLoggedIn);
  return (
    <>
      {isLoggedIn ? (
        <Navigate to={"/dashboard"} state={{ from: location }} replace />
      ) : (
        <div className="w-screen h-screen grid grid-cols-12 dark:bg-dark-blue">
          <div
            className="col-span-12 sm:flex bg-no-repeat bg-cover w-full justify-center items-center"
            style={{
              backgroundImage: `url(${bgLogin})`,
            }}
          >
            <Card style={{ width: 539, borderRadius: "30px" }}>
              <div className={"w-full"}>
                {/* header card */}
                <div className={"flex flex-col gap-8 "}>
                  <img
                    className="mx-auto mt-7 h-12 w-auto"
                    src={pgnLogo}
                    alt="Your Company"
                  />
                  <h2 className="text-center text-base tracking-tight dark:text-[#3C6DB2]">
                    Please login with your registered account
                  </h2>
                </div>
                {/* form */}
                <div
                  className={
                    "flex flex-col justify-center items-center w-full px-11 mt-[3.62rem]"
                  }
                >
                  <Form
                    name="normal_login"
                    layout="vertical"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={handleLogin}
                    className={"w-full"}
                  >
                    <Form.Item
                      label={<span>Enter your username or email address</span>}
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        // prefix={<IconsUser />}
                        placeholder="Username"
                        className="bg-transparent w-full"
                        style={{ borderRadius: "9px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span>Enter your Password</span>}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      className={"mt-9"}
                    >
                      <Input.Password
                        type="password"
                        size="large"
                        // prefix={<IconsLock />}
                        placeholder="Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        className="bg-transparent mb-2 !text-base"
                        style={{ borderRadius: "9px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Security Text"
                      style={{ marginBottom: 0 }}
                      className={"mt-9"}
                    >
                      <div
                        className={
                          "flex justify-between mt-4 w-full  item-center"
                        }
                      >
                        <div className={"flex flex-col"}>
                          <Form.Item
                            name="please input captcha"
                            rules={[{ required: true }]}
                          >
                            <Input
                              placeholder="Enter the shown text"
                              className="bg-transparent text-base"
                              style={{ borderRadius: "9px" }}
                              size="large"
                            />
                          </Form.Item>
                          <Form.Item
                            name={"remember"}
                            valuePropName={"checked"}
                          >
                            <Checkbox> Remember Me</Checkbox>
                          </Form.Item>
                        </div>
                        <div className="flex justify-end ">
                          <LoadCanvasTemplate reloadText="Reload" cla />
                        </div>
                      </div>
                    </Form.Item>
                    <div
                      className={
                        "w-full flex flex-col items-center justify-center mt-5"
                      }
                    >
                      <Form.Item className={"w-full"}>
                        <button
                          type="submit"
                          className={
                            "bg-[#3C6DB2] hover:bg-[#3663a2] text-white text-sm px-4 py-4 border rounded-lg w-full border-none cursor-pointer"
                          }
                        >
                          <span> Sign In</span>
                        </button>
                      </Form.Item>
                      <Link
                        to="/forgot-password"
                        className=" dark:text-[#3C6DB2] cursor-pointer mb-7"
                      >
                        Forget Password?
                      </Link>
                    </div>
                  </Form>
                </div>
                {/* footer card*/}
                <span className="dark:text-[#0880AE] text-[10px] flex justify-center mb-8">
                  Copyrights © 2022 Astra Graphia Information Technology. All
                  rights reserved
                </span>
              </div>
            </Card>
          </div>

          {openModal && (
            <ModalError
              isOpen={openModal}
              handleCancel={handleCancel}
              isAlert={true}
            >
              <div className={"flex px-8 py-8"}>
                <ExclamationCircleFilled
                  style={{ fontSize: "24px", color: "#C81912" }}
                  className="my-2"
                />
                <div className="w-full flex-col my-2">
                  <div className="pl-4">
                    <span className="text-xl font-bold  text-[#C81912]">
                      {" "}
                      Oops, login failed...
                    </span>
                  </div>
                  <div className="pl-4 pt-4">
                    <span className={"text-l"}>
                      {" "}
                      Change a few things up and try login again.
                    </span>
                  </div>
                </div>
              </div>
              <div className={"w-full justify-end flex pb-2 pr-2"}>
                <ButtonComponent
                  type={"submit"}
                  onClick={() => setOpenModal(false)}
                >
                  Back
                </ButtonComponent>
              </div>
            </ModalError>
          )}
        </div>
      )}
    </>
  );
};

export default LogIn;
