import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { Spin, Table, Checkbox, Tooltip, Tree } from "antd";
import BreadCrumb from "../../../../components/BreadCrumb";
import BaseContainer from "../../../../components/BaseContainer";
import ButtonComponent from "../../../../components/ButtonComponent";
import {
	DownloadOutlined,
	PlusOutlined,
	UnorderedListOutlined,
	EditOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	ArrowLeftOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import StatusComponent from "../../../../components/StatusComponent";
import SVGIcon from "../../../../assets/Icon/index";
import React, { useEffect, useState } from "react";
import DetailText from "../../../../components/DetailText";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import InputComponent from "../../../../components/InputComponent";

const GroupAccessCreate = () => {
	const [groupAccessName, setGroupAccessName] = useState("");
	const [remark, setRemark] = useState("");
	const [menuAccess, setMenuAccess] = useState([]);
	const [previewGA, setPreviewGA] = useState(false);
	const navigate = useNavigate();

	const previewGroupAccess = () => {
		setPreviewGA(true);
	};
	const clearStateGA = () => {
		setGroupAccessName("");
		setRemark("");
		setMenuAccess([]);
	};
	const saveGroupAccess = () => {
		const body = {
			groupAccessName: groupAccessName,
			remark: remark,
			menuAccess: menuAccess,
		};
		console.log(body);
	};
	const treeData = [
		{
			title: "Main Home",
			key: "0-0",
		},
		{
			title: "Account",
			key: "0-1",
			children: [
				{
					title: "Customer",
					key: "1-1",
				},
				{
					title: "Search",
					key: "1-2",
				},
			],
		},
	];
	console.log(menuAccess);
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb
					pageName={["System Setup", "Group Access", "Create Group Access"]}
				/>
				<NavLink onClick={() => navigate(-1)}>
					<div className="flex align-middle gap-x-2">
						<ArrowLeftOutlined
							style={{ fontSize: "24px", color: "#3C6DB2" }}
						></ArrowLeftOutlined>
						<span className="text-dg-blue">Back</span>
					</div>
				</NavLink>
				<div className="w-full flex gap-x-6">
					<div className="w-2/3">
						<BaseContainer header={"CREATE GROUP ACCESS"}>
							<div className="w-full">
								<div className="grid grid-cols-2 gap-x-10 gap-y-4 w-full">
									<InputComponent
										type="text"
										label={"Name"}
										mandatory
										value={groupAccessName}
										onChange={(e) => setGroupAccessName(e.target.value)}
									></InputComponent>
									<div></div>
									<InputComponent
										type="textarea"
										label={"Remark"}
										mandatory
										value={remark}
										onChange={(e) => setRemark(e.target.value)}
									></InputComponent>
								</div>

								<div className="flex justify-end mt-40">
									<ButtonComponent
										icon={<DeleteOutlined style={{ fontSize: "16px" }} />}
										onClick={() => clearStateGA()}
										type="default"
									>
										Clear
									</ButtonComponent>
									<ButtonComponent
										onClick={() => previewGroupAccess()}
										type="submit"
									>
										Save
									</ButtonComponent>
								</div>
							</div>
						</BaseContainer>
					</div>
					<div className="w-1/3">
						<BaseContainer header={"MENU ACCESS"}>
							<Tree
								checkable
								// defaultExpandedKeys={["0-0-0", "0-0-1"]}
								// onCheck={(x, info) => setMenuAccess(info.checkedNodes)}
								// onSelect={(a, info) => console.log(a)}
								onCheck={(a, info) => {
									const x = info.checkedNodes.filter((e) => !e.children);
									console.log(x);
									setMenuAccess(x);
								}}
								// defaultSelectedKeys={['0-0-0', '0-0-1']}
								// defaultCheckedKeys={['0-0-0', '0-0-1']}
								// onSelect={() => console.log("ON SELECT")}
								// onCheck={() => console.log("ON CHECK")}
								treeData={treeData}
							/>
						</BaseContainer>
					</div>
				</div>
			</Spin>

			<ModalCustom
				isOpen={previewGA}
				handleCancel={() => setPreviewGA(false)}
				header={"CONFIRMATION"}
				width={1000}
			>
				<div className="flex flex-col w-full gap-y-10">
					<div className="grid grid-cols-2 gap-y-2.5">
						<DetailText label={"Name"}>{groupAccessName}</DetailText>
						<DetailText label={"Remark"}>{remark}</DetailText>
					</div>
					<div className="grid grid-cols-1 gap-y-2">
						<p className="text-dg-blue text-sm font-bold">Menu Access</p>
						<Tree treeData={treeData} autoExpandParent />
					</div>
				</div>

				<div className="flex justify-end">
					<ButtonComponent onClick={() => setPreviewGA(false)} type="default">
						Cancel
					</ButtonComponent>
					<ButtonComponent
						// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
						// onClick={() => saveGroupAccess()}
						type="submit"
					>
						Save
					</ButtonComponent>
				</div>
			</ModalCustom>
		</LayoutMenu>
	);
};

export default GroupAccessCreate;
