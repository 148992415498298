import { DatePicker } from "antd";
import React from "react";
import InputLabel from "./InputLabel";
import moment from "moment/moment";
const DateComponent = (props) => {
	const {
		picker,
		label,
		onChange = () => {},
		mandatory,
		value,
		placeholder,
	} = props;
	const wrapper = "flex flex-col";

	return (
		<div className={wrapper}>
			<InputLabel text={label} mandatory={mandatory}></InputLabel>
			<DatePicker onChange={onChange} picker={picker} value={value} />
		</div>
	);
};

export default DateComponent;
