import { ArrowLeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import InputComponent from "../../../../components/InputComponent";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";

const AnnouncementCreate = (props) => {
	const { type } = props;
	const [name, setName] = useState("");
	const navigate = useNavigate();
	useEffect(() => {
		if (type === "update") {
			setName("Raul Athallah");
		}
	}, []);

	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb
					pageName={[
						"System Setup",
						"Announcement",
						type !== "update" ? "Create" : "Update",
					]}
				/>
				<NavLink onClick={() => navigate(-1)}>
					<div className="flex align-middle gap-x-2 mb-4">
						<ArrowLeftOutlined
							style={{ fontSize: "24px", color: "#3C6DB2" }}
						></ArrowLeftOutlined>
						<span className="text-dg-blue">Back</span>
					</div>
				</NavLink>
				<BaseContainer
					header={
						type !== "update" ? "CREATE ANNOUNCEMENT" : "UPDATE ANNOUNCEMENT"
					}
				>
					<div className="grid grid-cols-2 w-full">
						<InputComponent
							type="text"
							value={name}
							label={"Name"}
							onChange={(e) => setName(e.target.value)}
						></InputComponent>
					</div>
				</BaseContainer>
			</Spin>
		</LayoutMenu>
	);
};

export default AnnouncementCreate;
