import DetailPositionHierarchy from "../../app/pages/UserManagement/PositionHierarchy/DetailPositionHierarchy";
import PositionHierarchyForm from "../../app/pages/UserManagement/PositionHierarchy/PositionHierarchyForm";
import PositionHierarchyPage from "../../app/pages/UserManagement/PositionHierarchy/PositionHierarchyPage";
import DetailUser from "../../app/pages/UserManagement/User/DetailUser";
import GeneratePasswordPage from "../../app/pages/UserManagement/User/GeneratePassword/GeneratePasswordPage";
import MaintainUser from "../../app/pages/UserManagement/User/MaintainUser";
import UploadUser from "../../app/pages/UserManagement/User/UploadUser";
import UserForm from "../../app/pages/UserManagement/User/UserForm";
import UserPage from "../../app/pages/UserManagement/User/UserPage";
import Employee from "../../app/pages/UserManagement/Employee/Employee";
import EmployeeDetail from "../../app/pages/UserManagement/Employee/EmployeeDetail";
import EmployeeCreate from "../../app/pages/UserManagement/Employee/EmployeeCreate";
import EmployeeEdit from "../../app/pages/UserManagement/Employee/EmployeeEdit";
import EmployeeUpload from "../../app/pages/UserManagement/Employee/EmployeeUpload";
import DelegationCreate from "../../app/pages/UserManagement/Delegation/DelegationCreate";
import DelegationEdit from "../../app/pages/UserManagement/Delegation/DelegationEdit";
import Delegation from "../../app/pages/UserManagement/Delegation/Delegation";
import ApprovalHierarchyPage from "../../app/pages/SystemSetup/ApprovalHierarchy/ApprovalHierarchyPage";
import ApprovalHierarchyForm from "../../app/pages/SystemSetup/ApprovalHierarchy/ApprovalHierarchyForm";

export const USER_ELEMENTS = {
  VIEW_USER_PAGE: <UserPage />,
  CREATE_USER_PAGE: <UserForm />,
  DETAIL_USER_PAGE: <DetailUser />,
  CHANGE_AUTH_PAGE: <MaintainUser />,
  GENERATE_PASSWORD_PAGE: <GeneratePasswordPage />,
  UPLOAD_USER_PAGE: <UploadUser />,
  UPDATE_USER_PAGE: <UserForm type="update" />,

  // position hierarchy page
  VIEW_POSITION_PAGE: <PositionHierarchyPage />,
  DETAIL_POSITION_PAGE: <DetailPositionHierarchy />,
  CREATE_POSITION_PAGE: <PositionHierarchyForm />,
  UPDATE_POSITION_PAGE: <PositionHierarchyForm type="update" />,

  // employee
  VIEW_EMPLOYEE_PAGE: <Employee />,
  DETAIL_EMPLOYEE_PAGE: <EmployeeDetail />,
  CREATE_EMPLOYEE_PAGE: <EmployeeCreate />,
  UPDATE_EMPLOYEE_PAGE: <EmployeeEdit />,
  UPLOAD_EMPLOYEE_PAGE: <EmployeeUpload />,

  // DELEGATION
  VIEW_DELEGATION_PAGE: <Delegation />,
  CREATE_DELEGATION_PAGE: <DelegationCreate />,
  UPDATE_DELEGATION_PAGE: <DelegationEdit />,

  // approval hierarchy
  VIEW_APPROVAL_PAGE: <ApprovalHierarchyPage />,
  DETAIL_APPROVAL_PAGE: "",
  CREATE_APPROVAL_PAGE: <ApprovalHierarchyForm />,
  UPDATE_APPROVAL_PAGE: <ApprovalHierarchyForm type="update" />,

};
