import { Popover, Table } from 'antd';
import React, { useState } from 'react';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import ButtonComponent from '../../../../components/ButtonComponent';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';
import { DownloadOutlined, PlusCircleOutlined, MoreOutlined, UnorderedListOutlined, EditOutlined, CopyOutlined, CloseCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { tableNumbering } from '../../../../utils';
import { NavLink, useNavigate } from 'react-router-dom';
import ModalCustom from '../../../../components/Modal/ModalCustom';
import { USER_ROUTES } from '../../../../routes/user_management/user_routes';

const PositionHierarchyPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const handleCancel = () => {
        setOpenModal(false)
    }
    const content = (
        <div>
            <NavLink to={""}>
                <ButtonComponent icon={<CopyOutlined /> } >
                    <span className={'text-black'}> Duplicate</span>
                </ButtonComponent>
            </NavLink>
            <NavLink to={USER_ROUTES.DETAIL_POSITION}>
                <ButtonComponent icon={<UnorderedListOutlined />} >
                    <span className={'text-black'}>Detail</span>

                </ButtonComponent>
            </NavLink>
            <NavLink to={USER_ROUTES.UPDATE_POSITION}>
                <ButtonComponent icon={<EditOutlined />} >
                    <span className={'text-black'}>Update</span>

                </ButtonComponent>
            </NavLink>
            <ButtonComponent icon={<CloseCircleOutlined style={{
                color: "#FF7171",
                fontSize: 24,
                justifyItems: "center",
            }} />} onClick={() => setOpenModal(true)}>
                <span className={'text-black'}> Inactive</span>
            </ButtonComponent>
        </div>
    );
    
    const data = [
        {
            name: 'Hierarchy-019201',
            remark: 'Final',
            start_date:'12-Jan-2022 21:00:54',
            end_date: '12-Jan-2022 21:00:54',
            status: 'active',
        },
        {
            name: 'Hierarchy-0192',
            remark: 'Final',
            start_date:'12-Jan-2022 21:00:54',
            end_date: '12-Jan-2022 21:00:54',
            status: 'active',
        },
        {
            name: 'Hierarchy-201',
            remark: 'Draft Awal',
            start_date:'12-Jan-2022 21:00:54',
            end_date: '12-Jan-2022 21:00:54',
            status: 'inactive',
        },
        {
            name: 'Hierarchy-09201',
            remark: 'Draft Awal',
            start_date:'12-Jan-2022 21:00:54',
            end_date: '12-Jan-2022 21:00:54',
            status: 'draft',
        },
    ]
    const columns = [
        // tableNumbering,
        {
            title:  "Name",
            dataIndex: "name",
            key: "name",
            align :'center'
        },
        {
            title:  "Remark",
            dataIndex: "remark",
            key:"remark",
            align: 'center'
        },
        {
            title:  "Start Date",
            dataIndex: "start_date",
            key: "start_date",
            align: 'center'

        },
        {
            title:  "End Date",
            dataIndex: "end_date",
            key: "end_date",
            align: 'center'

        },
        {
            title:  "Status",
            dataIndex: "status",
            key:"status",
            align: 'center'

        },
        {
            title:  "Actions",
            dataIndex: "actions",
            key: "actions",
            align :'center',
            render: () => {
                return (
                    <Popover content={content} trigger={'click'} placement="bottomRight">
                        <ButtonComponent icon={<MoreOutlined />} />
                    </Popover>
                )
            }
        },
    ];
    return (
        <LayoutMenu>
            <BreadCrumb pageName={['User Management', 'View Position Hierarchy']} />
           
            <BaseContainer header={'Position Hierarchy List'}>
                <div className={'w-full flex flex-col'}>
                    <div className={'w-full flex justify-end'}>
                        <ButtonComponent type={'submit'} icon={<DownloadOutlined />}> Download List</ButtonComponent>
                        <NavLink to={USER_ROUTES.CREATE_POSITION}>
                        <ButtonComponent type={'submit'} icon={<PlusCircleOutlined />}> Create New</ButtonComponent>
                        </NavLink>
                    </div>
                    <Table columns={columns} dataSource={data} />
                </div>
            </BaseContainer>
            <ModalCustom header={"INACTIVATE HIERARCHY"} isOpen={openModal} handleCancel={handleCancel}>
                <div className={"flex flex-col px-8 py-4"}>
                    <div className="w-full justify-center flex my-6">
                        <span className={"text-xl text-[#3C6DB2]"}>
                            {" "}
                            Are you sure want to inactive hierarchy?
                        </span>
                    </div>
                    <div className={"w-full justify-center flex"}>
                        <ButtonComponent
                            type={"default"}
                            onClick={() => setOpenModal(false)}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent type={"submit"}>
                            Inactive
                        </ButtonComponent>
                    </div>
                </div>
            </ModalCustom>
        </LayoutMenu>
    );
}

export default PositionHierarchyPage;
