import React, { useRef, useState } from "react";
import { Spin, Checkbox } from "antd";
import BaseContainer from "../../../../../components/BaseContainer";
import BreadCrumb from "../../../../../components/BreadCrumb";
import LayoutMenu from "../../../../../components/SidebarMenu/LayoutMenu";
import ButtonComponent from "../../../../../components/ButtonComponent";
import {
  DownloadOutlined,
  PlusOutlined,
  FilterOutlined,
  EditOutlined,
  CloseCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table, Popconfirm, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { NavLink } from "react-router-dom";
import ModalCustom from "../../../../../components/Modal/ModalCustom";
import StatusComponent from "../../../../../components/StatusComponent";
import SVGIcon from "../../../../../assets/Icon/index";

const CostCenter = () => {
  const searchInput = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalInactive, setModalInactive] = useState(false);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const dataSource = [
    {
      key: "1",
      costCenterName: "Cabang Pembantu Bogor",
      costCenter: 18849,
      parent: "Cabang Jakarta",
      area: "2232 - Jakarta",
      status: "ACTIVE",
    },
    {
      key: "2",
      costCenterName: "Human Capital Management",
      costCenter: 18849,
      parent: "Head Office",
      area: "-",
      status: "INACTIVE",
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      align: "center",
      key: "name",
    },
    {
      title: "COST CENTER NAME",
      dataIndex: "costCenterName",
      align: "center",
      key: "costCenterName",
      ...getColumnSearchProps("costCenterName"),
    },
    {
      title: "COST CENTER",
      dataIndex: "costCenter",
      align: "center",
      key: "costCenter",
      ...getColumnSearchProps("costCenter"),
    },
    {
      title: "PARENT",
      dataIndex: "parent",
      align: "center",
      key: "parent",
      ...getColumnSearchProps("parent"),
    },
    {
      title: "AREA",
      dataIndex: "area",
      align: "center",
      key: "area",
      ...getColumnSearchProps("area"),
    },
    {
      title: "ACTIVATE",
      dataIndex: "address",
      align: "center",
      key: "address",
      ...getColumnSearchProps("address"),
      render: () => {
        return (
          <div className="flex justify-center">
            <Checkbox
            // onChange={onChange}
            ></Checkbox>
          </div>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (status) => (
        <div>
          {status === "ACTIVE" && (
            <StatusComponent color="green">{status}</StatusComponent>
          )}

          {status === "INACTIVE" && (
            <StatusComponent color="red">{status}</StatusComponent>
          )}
        </div>
      ),
    },
    {
      title: "ACTIONS",
      dataIndex: "address",
      render: () => {
        return (
          <div className="flex justify-center gap-4">
            <NavLink to={"/app/system-setup/master-data/cost-center/detail"}>
              <Tooltip title="Detail">
                <UnorderedListOutlined
                  style={{
                    color: "#0880AE",
                    fontSize: 24,
                    justifyItems: "center",
                  }}
                />
              </Tooltip>
            </NavLink>
            <NavLink to={"/app/system-setup/master-data/cost-center/edit"}>
              <Tooltip title="Edit">
                <div>
                  <SVGIcon
                    name="IconEdit"
                    width={24}
                    style={{
                      color: "#0880AE",
                      fontSize: 24,
                      justifyItems: "center",
                    }}
                  />
                </div>
              </Tooltip>
            </NavLink>
            <Tooltip title="Delete">
              <CloseCircleOutlined
                onClick={() => {
                  // info(id);
                  setModalInactive(true);
                  // console.log(id);
                }}
                style={{
                  color: "#FF7171",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
          </div>
        );
      },
      key: "action",
    },
  ];
  return (
    <LayoutMenu>
      <Spin spinning={false} className="w-full top-20" tip="Loading">
        <BreadCrumb
          pageName={["System Setup ", "Master Data", "Cost Center"]}
        />

        <BaseContainer header={"COST CENTER"}>
          <div className="flex flex-col w-full">
            <div className="flex w-full justify-end">
              <NavLink
                to={"/app/system-setup/master-data/cost-center/view-hierarchy"}
              >
                <ButtonComponent
                  type={"submit"}
                  icon={
                    <SVGIcon
                      name="IconHierarchy"
                      width={24}
                      className={"mr-2 p-0"}
                    />
                  }
                >
                  View Hierarchy
                </ButtonComponent>
              </NavLink>

              <ButtonComponent
                icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
                type={"submit"}
              >
                Download List
              </ButtonComponent>

              <NavLink to={"/app/system-setup/master-data/cost-center/create"}>
                <ButtonComponent
                  icon={<PlusOutlined style={{ fontSize: "24px" }} />}
                  type={"submit"}
                >
                  Create New
                </ButtonComponent>
              </NavLink>
            </div>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{
                position: ["bottomCenter"],
              }}
              size="small"
              total={50}
              showSizeChanger
            />
          </div>
        </BaseContainer>

        <ModalCustom
          isOpen={modalInactive}
          handleCancel={() => setModalInactive(false)}
          header={"INACTIVE COST CENTER"}
          width={800}
        >
          <div className="flex flex-col w-full">
            <h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
              Are you sure you want to inactive cost center
            </h1>

            <div className="flex mt-4 w-full justify-center">
              <ButtonComponent
                // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"default"}
                onClick={() => setModalInactive(false)}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                // onClick={() => setModalConfirmation(true)}
                type={"submit"}
              >
                Submit
              </ButtonComponent>
            </div>
          </div>
        </ModalCustom>
      </Spin>
    </LayoutMenu>
  );
};

export default CostCenter;
