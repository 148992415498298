import React from "react";
import { Radio } from "antd";

const RadioTabs = (props) => {
  const {
    onChange = () => {},
    defaultValue,
    children,
    value,
    options,
    optionType,
  } = props;

  return (
    <div className="bg-transparent w-fit rounded-lg px-2.5 py-1.5">
      <Radio.Group
        // defaultValue={defaultValue}
        optionType="button"
        options={options}
        value={value}
        buttonStyle="solid"
        onChange={onChange}
      >
        {children}
      </Radio.Group>
    </div>
  );
};

export default RadioTabs;
