import { NavLink, useNavigate } from "react-router-dom";
import {
	ArrowLeftOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	DownOutlined,
	InboxOutlined,
	MoreOutlined,
	UnorderedListOutlined,
} from "@ant-design/icons";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import {
	Dropdown,
	Menu,
	Select,
	Space,
	Spin,
	Switch,
	Table,
	Tooltip,
	Upload,
	message,
} from "antd";
import BreadCrumb from "../../../../components/BreadCrumb";
import BaseContainer from "../../../../components/BaseContainer";
import SVGIcon from "../../../../assets/Icon/index";

const EmployeeUpload = () => {
	const navigate = useNavigate();
	const { Dragger } = Upload;
	const data = [
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
		{
			no: 1,
			nama: "Raul Athallah",
			startDate: "22-Jan-2022 23:11:09",
			endDate: "22-Jan-2022 23:11:09",
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has s",
			status: "ACTIVE",
		},
	];
	const columns = [
		{
			title: "NO",
			dataIndex: "no",
			width: 40,
		},
		{
			title: "NAME",
			dataIndex: "nama",
		},

		{
			title: "START DATE",
			dataIndex: "startDate",
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
		},
		{
			title: "STATUS",
			dataIndex: "status",
		},
		{
			title: "DESCRIPTION",
			dataIndex: "description",
			width: 400,
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 100,
			render: () => {
				return (
					<div className="flex justify-center gap-4">
						<NavLink
							to={"/app/system-setup/entity/detail"}
							state={{ entityName: "Entity 1" }}
						>
							<Tooltip title="Detail">
								<UnorderedListOutlined
									style={{
										color: "#0880AE",
										fontSize: 24,
										justifyItems: "center",
									}}
								/>
							</Tooltip>
						</NavLink>
						<NavLink to={"/app/system-setup/entity/edit"}>
							<Tooltip title="Edit">
								<div>
									<SVGIcon
										name="IconEdit"
										width={24}
										style={{
											color: "#0880AE",
											fontSize: 24,
											justifyItems: "center",
										}}
									/>
								</div>
							</Tooltip>
						</NavLink>
						<Tooltip title="Delete">
							<CloseCircleOutlined
								onClick={() => {
									// setModalInactive(true);
								}}
								style={{
									color: "#FF7171",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];
	const menu = (
		<Menu>
			<Menu.Item>
				<div className="flex gap-x-2">
					<UnorderedListOutlined
						style={{
							color: "#0880AE",
							fontSize: 16,
							justifyItems: "center",
						}}
					/>
					<p className="text-xs">See Detail</p>
				</div>
			</Menu.Item>

			<Menu.Item>
				<div className="flex gap-x-2">
					<SVGIcon
						name="IconEdit"
						width={16}
						style={{
							color: "#0880AE",
							fontSize: 16,
							justifyItems: "center",
						}}
					/>
					<p className="text-xs">Edit</p>
				</div>
			</Menu.Item>
			<Menu.Item>
				<div className="flex gap-x-2">
					<SVGIcon
						name="IconLogHistory"
						width={16}
						style={{
							color: "#0880AE",
							fontSize: 16,
							justifyItems: "center",
						}}
					/>
					<p className="text-xs">Log History</p>
				</div>
			</Menu.Item>
			<Menu.Item>
				<div className="flex gap-x-2">
					<SVGIcon
						name="IconCheck"
						width={16}
						style={{
							color: "#0880AE",
							fontSize: 16,
							justifyItems: "center",
						}}
					/>
					<p className="text-xs">Request Approval</p>
				</div>
			</Menu.Item>
			<Menu.Item>
				<div className="flex gap-x-2">
					<CloseCircleOutlined
						// onClick={() => {
						// 	setModalInactive(true);
						// }}
						style={{
							color: "#FF7171",
							fontSize: 16,
							justifyItems: "center",
						}}
					/>
					<p className="text-xs">Inactive</p>
				</div>
			</Menu.Item>
		</Menu>
	);
	const columns2 = [
		{
			title: "NO",
			dataIndex: "no",
			width: 40,
		},
		{
			title: "NAME",
			dataIndex: "nama",
		},

		{
			title: "START DATE",
			dataIndex: "startDate",
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
		},
		{
			title: "STATUS",
			dataIndex: "status",
		},
		{
			title: "DESCRIPTION",
			dataIndex: "description",
			width: 400,
			ellipsis: {
				showTitle: false,
			},
			render: (x) => (
				<Tooltip placement="topLeft" title={x}>
					{x}
				</Tooltip>
			),
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 100,
			render: () => (
				<>
					<Dropdown overlay={menu}>
						<a>
							<MoreOutlined
								style={{
									color: "#0880AE",
									fontSize: 24,
									justifyItems: "center",
								}}
							></MoreOutlined>
						</a>
					</Dropdown>
				</>
			),
			// render: () => {
			// 	return (
			// 		<div className="flex justify-center gap-4">
			// 			{/* <Tooltip title="More"> */}
			// 			<Dropdown
			// 				menu={[
			// 					{
			// 						key: "1",
			// 						label: "Action 1",
			// 					},
			// 					{
			// 						key: "2",
			// 						label: "Action 2",
			// 					},
			// 				]}
			// 			>
			// 				<>
			// 					More <DownOutlined />
			// 				</>
			// 			</Dropdown>
			// 			{/* <CloseCircleOutlined
			// 					onClick={() => {
			// 						// setModalInactive(true);
			// 					}}
			// 					style={{
			// 						color: "#FF7171",
			// 						fontSize: 24,
			// 						justifyItems: "center",
			// 					}}
			// 				/> */}
			// 			{/* </Tooltip> */}
			// 		</div>
			// 	);
			// },
			key: "action",
		},
	];
	const onShowSizeChange = (current, pageSize) => {
		console.log(current, pageSize);
	};

	const props = {
		name: "file",
		multiple: false,
		action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
		onChange(info) {
			const { status } = info.file;
			if (status !== "uploading") {
				console.log(info.file, info.fileList);
			}
			if (status === "done") {
				message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === "error") {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb
					pageName={["User Management", "Employee", "Upload Employee"]}
				/>
				<NavLink onClick={() => navigate(-1)}>
					<div className="flex align-middle gap-x-2 mb-4">
						<ArrowLeftOutlined
							style={{ fontSize: "24px", color: "#3C6DB2" }}
						></ArrowLeftOutlined>
						<span className="text-dg-blue">Back</span>
					</div>
				</NavLink>
				{/* <BaseContainer header={"EMPLOYEE INFORMATION"}>
					<div className="w-full gap-y-12">
						<Table
							dataSource={data}
							columns={columns}
							size="small"
							pagination={{
								position: ["topRight"],
								total: data.length,
								// pageSize: 5,
								defaultPageSize: 5,
								defaultCurrent: 1,
								showSizeChanger: true,
								pageSizeOptions: [5, 10, 20, 50],
								// onShowSizeChange: { onShowSizeChange },
							}}
						/>
						<Table
							dataSource={data}
							columns={columns2}
							size="small"
							pagination={{
								position: ["topRight"],
								total: data.length,
								// pageSize: 5,
								defaultPageSize: 5,
								defaultCurrent: 1,
								showSizeChanger: true,
								pageSizeOptions: [5, 10, 20, 50],

								// onShowSizeChange: { onShowSizeChange },
							}}
						/>
					</div>
				</BaseContainer> */}
				<BaseContainer header={"EMPLOYEE INFORMATION"}>
					<Dragger {...props}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">
							Click or drag file to this area to upload
						</p>
						<p className="ant-upload-hint">
							Support for a single or bulk upload. Strictly prohibit from
							uploading company data or other band files
						</p>
					</Dragger>
				</BaseContainer>
			</Spin>
		</LayoutMenu>
	);
};

export default EmployeeUpload;
