import React, { useEffect, useRef, useState } from "react";
import BreadCrumb from "../../../../components/BreadCrumb";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { useSelector, useDispatch } from "react-redux";
import BaseContainer from "../../../../components/BaseContainer";
import {
	Spin,
	Table,
	Tooltip,
	Input,
	Button,
	Space,
	Pagination,
	Popover,
} from "antd";
import ButtonComponent from "../../../../components/ButtonComponent";
import { useLocation, useNavigate } from "react-router-dom";

import {
	UnorderedListOutlined,
	EditOutlined,
	SearchOutlined,
	LeftOutlined,
	MoreOutlined,
} from "@ant-design/icons";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import DetailText from "../../../../components/DetailText";
import StatusComponent from "../../../../components/StatusComponent";
import { SYSTEM_SETUP_ROUTES } from "../../../../routes/system_setup/setup_routes";
import CardComponent from "../../../../components/Card/CardComponent";
import { getDetailEntity } from "../../../../redux/slices/entity";
import { dateFormat } from "../../../../utils";
import moment from "moment";
import SVGIcon from "../../../../assets/Icon/index";

const EntityDetail = () => {
	const dispatch = useDispatch();
	const { data, loading } = useSelector((state) => state.entity);
	const navigate = useNavigate();
	const id = useLocation().state.id;
	const datas = data?.data;

	const [openDetailTI, setOpenDetailTI] = useState(false);
	const [taxRecord, setTaxRecord] = useState({});

	useEffect(() => {
		dispatch(getDetailEntity(id));
	}, []);

	console.log(datas);

	const onChangeTable = (pagination, filters, sorter, extra) => {
		console.log("params", pagination, filters, sorter, extra);
	};
	// const [filteredInfo, setFilteredInfo] = useState({});
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const searchInput = useRef(null);
	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			close,
		}) => (
			<div
				style={{
					padding: 8,
				}}
				onKeyDown={(e) => e.stopPropagation()}
			>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{
						marginBottom: 8,
						display: "block",
					}}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{
							width: 90,
						}}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{
							width: 90,
						}}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({
								closeDropdown: false,
							});
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							close();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined
				style={{
					color: filtered ? "#1890ff" : undefined,
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};
	const handleOpenDetailTI = (r) => {
		setTaxRecord(r);
		setOpenDetailTI(true);
	};
	const columnsTI = [
		{
			title: "NO",
			width: 60,
			align: "center",
			render: (text, object, index) => index + 1,
		},
		{
			title: "TAX IDENTIFIER",
			dataIndex: "taxNumber",
			align: "center",
			// filterSearch: true,
			// filters: [
			// 	{ text: "Joe", value: "Joe" },
			// 	{ text: "Jim", value: "Jim" },
			// ],
			// filteredValue: filteredInfo.name || null,
			sorter: (a, b) => a.taxNumber.length - b.taxNumber.length,
		},
		{
			title: "DESCRIPTION",
			dataIndex: "remark",
			align: "center",
		},
		{
			title: "START DATE",
			dataIndex: "startDate",
			align: "center",
			render: (v) => (v ? moment(v).format(dateFormat) : "-"),
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
			align: "center",
			render: (v) => (v ? moment(v).format(dateFormat) : "-"),
		},
		{
			title: "STATUS",
			dataIndex: "main",
			width: 130,
			align: "center",
			render: (v) => {
				if (v) {
					return <StatusComponent color={"green"}>Primary</StatusComponent>;
				} else {
					return "-";
				}
			},
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 100,
			// fixed: "right",
			render: (v, r) => {
				return (
					<div className="flex justify-center align-middle">
						<Popover
							content={
								<div>
									<ButtonComponent
										icon={<SVGIcon name="IconDetail" width={24} />}
										border={false}
										onClick={() => {
											handleOpenDetailTI(r);
										}}
									>
										<span className={"text-black"}> Detail</span>
									</ButtonComponent>

									<ButtonComponent icon={<EditOutlined />} border={false}>
										<span className={"text-black"}> Update Entity</span>
									</ButtonComponent>
								</div>
							}
							trigger={"click"}
							placement="bottomRight"
						>
							<ButtonComponent icon={<MoreOutlined />} border={false} />
						</Popover>
						<Tooltip title="Delete">
							<ButtonComponent
								border={false}
								icon={<SVGIcon name="IconDelete" width={24} />}
								onClick={() => {
									// setModalInactive(true);
								}}
							></ButtonComponent>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const routes = [
		{
			path: "",
			breadcrumbName: "System Setup",
		},
		{
			path: SYSTEM_SETUP_ROUTES.VIEW_ENTITY,
			breadcrumbName: "Entity",
		},
		{
			path: "",
			breadcrumbName: "Detail Entity",
		},
	];

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const handleChange = (page) => {
		setPage(page);
	};
	const handleChangeSize = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	};

	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb routes={routes} />
				<BaseContainer header={"ENTITY INFORMATION"}>
					<div className="grid grid-cols-4 w-full gap-y-2.5 px-8">
						<DetailText label={"Entity Name"}>{datas?.entityName}</DetailText>
						<DetailText label={"Entity Code"}>{datas?.entityCode}</DetailText>
						<DetailText label={"Entity Email"}>{datas?.email}</DetailText>
						<DetailText label={"Address"}>{datas?.address}</DetailText>
						<DetailText label={"Phone Number"}>{datas?.phone}</DetailText>
						<DetailText label={"Fax Number"}>{datas?.fax}</DetailText>
					</div>
				</BaseContainer>

				<BaseContainer header={"HISTORY LOG INFORMATION"}>
					<div className="grid grid-cols-4 w-full gap-y-2.5 px-8">
						<DetailText label={"Created At"}>{datas?.createdAt}</DetailText>
						<DetailText label={"Created By"}>{datas?.createdBy}</DetailText>
						<DetailText label={"Latest Update"}>
							{datas?.latestUpdate}
						</DetailText>
						<DetailText label={"Update By"}>{datas?.updatedBy}</DetailText>
					</div>
				</BaseContainer>

				<BaseContainer header={"TAX IDENTIFIER"}>
					<div className="w-full">
						<div className={"flex justify-end mb-5"}>
							<Pagination
								total={datas?.taxIdentifierList.length}
								className={"pr-1"}
								showSizeChanger
								current={page}
								pageSize={pageSize}
								onChange={handleChange}
								onShowSizeChange={handleChangeSize}
								showTotal={(total, range) =>
									`Showing ${range[0]} to ${range[1]} of ${total} records`
								}
							/>
						</div>
						<Table
							dataSource={datas?.taxIdentifierList}
							columns={columnsTI}
							scroll={{ x: 0 }}
							onChange={onChangeTable}
							size="small"
							pagination={false}
						/>
					</div>
				</BaseContainer>

				<ButtonComponent
					type={"submit"}
					onClick={() => navigate(-1)}
					icon={
						<LeftOutlined
							style={{
								color: "#fff",
								fontSize: 16,
								justifyItems: "center",
							}}
						></LeftOutlined>
					}
				>
					Back
				</ButtonComponent>
			</Spin>

			<ModalCustom
				width={800}
				isOpen={openDetailTI}
				handleCancel={() => setOpenDetailTI(false)}
				header={"DETAIL TAX IDENTIFIER"}
			>
				<CardComponent header={"TAX IDENTIFIER INFORMATION"} cols={3}>
					<DetailText label={"Tax Identifier"}>
						{taxRecord.taxNumber}
					</DetailText>
					<DetailText label={"Status"}>
						{taxRecord.isMain ? "Main" : "-"}
					</DetailText>
					<DetailText label={"Remark"}> {taxRecord.remark}</DetailText>
					<DetailText label={"Start Date"}>
						{taxRecord.startDate
							? moment(taxRecord.startDate).format(dateFormat)
							: "-"}
					</DetailText>
					<DetailText label={"End Date"}>
						{taxRecord.endDate
							? moment(taxRecord.endDate).format(dateFormat)
							: "-"}
					</DetailText>
				</CardComponent>
				<CardComponent header={"HISTORY LOG INFORMATION"} cols={4}>
					<DetailText label={"Created At"}>
						{taxRecord.createdDate
							? moment(taxRecord.createdDate).format(dateFormat)
							: "-"}
					</DetailText>
					<DetailText label={"Created By"}> {taxRecord.createdBy}</DetailText>
					<DetailText label={"Latest Update"}>
						{taxRecord.updatedDate
							? moment(taxRecord.updatedDate).format(dateFormat)
							: "-"}
					</DetailText>
					<DetailText label={"Updated By"}>{taxRecord.updatedBy}</DetailText>
				</CardComponent>
				<div className="flex justify-end">
					<ButtonComponent
						// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
						onClick={() => setOpenDetailTI(false)}
						type="submit"
					>
						Close
					</ButtonComponent>
				</div>
			</ModalCustom>
		</LayoutMenu>
	);
};

export default EntityDetail;
