import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Radio } from 'antd';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BaseContainer from '../../../../../components/BaseContainer';
import BreadCrumb from '../../../../../components/BreadCrumb';
import ButtonComponent from '../../../../../components/ButtonComponent';
import LayoutMenu from '../../../../../components/SidebarMenu/LayoutMenu';
const GeneratePasswordPage = () => {
    const [disabled, setDisabled] = useState(true);

    const navigate = useNavigate();
    return (
        <LayoutMenu>
            <BreadCrumb pageName={['User Management', 'User', 'Generate Link Password']} />  
            <NavLink onClick={() => navigate(-1)}>
                <div className="flex align-middle gap-x-2 mb-4">
                    <ArrowLeftOutlined
                        style={{ fontSize: "24px", color: "#3C6DB2" }}
                    ></ArrowLeftOutlined>
                    <span className="text-dg-blue">Back</span>
                </div>
            </NavLink>
            <BaseContainer header={'GENERATE LINK PASSWORD'}>
                <div className={'w-full flex flex-col'}>
                    <div className={'w-1/4'}>
                        <Form layout={'vertical'}>
                            <Form.Item label={'Remark'} rules={{require : true}}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label={'Set Expiration Time'}>
                                <Radio.Group>
                                    <div>
                                        <Radio value="1m"> 1 Min </Radio>
                                        <Radio value="5m"> 5 Min </Radio>
                                    </div>
                                    <div>
                                        <Radio value="10m"> 10 Min </Radio>
                                        <Radio value="15m"> 15 Min </Radio>
                                    </div>
                                    <Radio value="custom" onChange={() => setDisabled(false)}> Custome</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                // name={['user', 'age']}
                                // label="Age"
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        max: 99,
                                    },
                                ]}
                            >
                                <InputNumber disabled={disabled} />
                                <span> Min</span>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className={"w-full flex justify-end"}>
                        <ButtonComponent type={'default'} icon={<DeleteOutlined/>}> Clear</ButtonComponent>
                        <ButtonComponent type={'submit'}> Save</ButtonComponent>
                    </div>
                </div>
            </BaseContainer>
        </LayoutMenu>
    );
}

export default GeneratePasswordPage;
