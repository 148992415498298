import React from "react";

const StatusComponent = (props) => {
	const { color, children } = props;
	let bg;
	color === "active"
		? (bg = "status-active")
		: color === "inactive"
		? (bg = "status-inactive")
		: color === "waiting"
		? (bg = "status-waiting")
		: color === "draft"
		? (bg = "status-draft")
		: color === "approved"
		? (bg = "status-approved")
		: color === "expire"
		? (bg = "status-expire")
		: color === "expire30"
		? (bg = "status-expire30")
		: color === "expire10"
		? (bg = "status-expire10")
		: color === "pending"
		? (bg = "status-pending")
		: (bg = "bg-transparent");
	return (
		<p
			// className={`text-white ${bg} mx-8 my-0 p-1 rounded-2xl text-center w-40`}
			className={`text-white ${bg} my-0 py-1 px-2 rounded-2xl text-center w-auto`}
		>
			{children}
		</p>
	);
};
export default StatusComponent;
