import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/BreadCrumb";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { useSelector, useDispatch } from "react-redux";
import BaseContainer from "../../../../components/BaseContainer";
import { getListEntity } from "../../../../redux/slices/entity";
import { Spin, Table, Checkbox, Tooltip } from "antd";
import ButtonComponent from "../../../../components/ButtonComponent";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import {
	DownloadOutlined,
	PlusOutlined,
	UnorderedListOutlined,
	EditOutlined,
	CloseCircleOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import StatusComponent from "../../../../components/StatusComponent";
import SVGIcon from "../../../../assets/Icon/index";
import DetailText from "../../../../components/DetailText";

const EntityPage = () => {
	// const { datas, loading } = useSelector((state) => state.entity);
	// const dispatch = useDispatch();
	// const [openModal, setOpenModal] = useState(false);
	const [modalInactive, setModalInactive] = useState(false);
	const [openDetail, setOpenDetail] = useState(false);
	const [detailRecord, setDetailRecord] = useState({});

	const announcementList = [
		{
			announcementName: "Idul Fitri",
			description: "Untuk perayaan idul fitri",
			startDate: "12-Jan-2022 23:11:09",
			endDate: "12-Jan-2022 23:11:09",
			isDeleted: "N",
			status: "ACTIVE",
			createdDate: "12-Jan-2022 23:11:09",
			createdBy: "Annisa",
			lastUpdated: "12-Jan-2022 23:11:09",
			updateDate: "12-Jan-2022 23:11:09",
			content: "Konten idul fitri nih bos",
		},
		{
			announcementName: "Tahun Baru",
			description: "Untuk perayaan tahun baru",
			startDate: "12-Jan-2022 23:11:09",
			endDate: "12-Jan-2022 23:11:09",
			isDeleted: "N",
			status: "INACTIVE",
			createdDate: "12-Jan-2022 23:11:09",
			createdBy: "Annisa",
			lastUpdated: "12-Jan-2022 23:11:09",
			updateDate: "12-Jan-2022 23:11:09",
			content: "Konten Tahun Baru nih bos",
		},
	];

	const columns = [
		{
			title: "NAME",
			dataIndex: "announcementName",
			width: 100,
		},
		{
			title: "DESCRIPTION",
			dataIndex: "description",
			width: 160,
		},
		{
			title: "START DATE",
			dataIndex: "startDate",
			width: 160,
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
			width: 160,
		},
		{
			title: "ACTIVATE",
			dataIndex: "",
			width: 160,
			render: () => {
				return (
					<div className="flex justify-center">
						<Checkbox
						// onChange={onChange}
						></Checkbox>
					</div>
				);
			},
		},
		{
			title: "STATUS",
			dataIndex: "status",
			width: 160,
			render: (status) => (
				<div>
					{status === "ACTIVE" && (
						<StatusComponent color="green">{status}</StatusComponent>
					)}

					{status === "INACTIVE" && (
						<StatusComponent color="red">{status}</StatusComponent>
					)}
				</div>
			),
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 100,
			render: (v, r, i) => {
				return (
					<div className="flex justify-center gap-4">
						<Tooltip title="Detail">
							<UnorderedListOutlined
								onClick={() => {
									viewDetail(r);
								}}
								style={{
									color: "#0880AE",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
						<NavLink
							to={"/app/system-setup/announcement/update"}
							state={"TEST"}
						>
							<Tooltip title="Edit">
								<div>
									<SVGIcon
										name="IconEdit"
										width={24}
										style={{
											color: "#0880AE",
											fontSize: 24,
											justifyItems: "center",
										}}
									/>
								</div>
							</Tooltip>
						</NavLink>
						<Tooltip title="Delete">
							<CloseCircleOutlined
								onClick={() => {
									setModalInactive(true);
								}}
								style={{
									color: "#FF7171",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];

	const viewDetail = (r) => {
		setDetailRecord(r);
		setOpenDetail(true);
	};
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb pageName={["System Setup", "Announcement"]} />
				<BaseContainer header={"ANNOUNCEMENT LIST"}>
					<div className={"w-full"}>
						<div className="flex w-full justify-end">
							<ButtonComponent
								icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
								type="submit"
							>
								Download List
							</ButtonComponent>
							<NavLink
								to={"/app/system-setup/announcement/create"}
								state={{ x: 1 }}
							>
								<ButtonComponent
									icon={<PlusOutlined style={{ fontSize: "24px" }} />}
									type="submit"
								>
									Create New
								</ButtonComponent>
							</NavLink>
						</div>
						<Table
							dataSource={announcementList}
							columns={columns}
							size="small"
							pagination={{ position: ["bottomCenter"] }}
						/>
					</div>
				</BaseContainer>
			</Spin>

			<ModalCustom
				isOpen={modalInactive}
				handleCancel={() => setModalInactive(false)}
				header={"INACTIVE ENTITY"}
				width={800}
			>
				<div className="flex flex-col w-full">
					<h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
						Are you sure you want to inactive Announcement?
					</h1>

					<div className="flex mt-4 w-full justify-center">
						<ButtonComponent
							// icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
							type={"default"}
							onClick={() => setModalInactive(false)}
						>
							Cancel
						</ButtonComponent>
						<ButtonComponent
							// onClick={() => setModalConfirmation(true)}
							type={"submit"}
						>
							Submit
						</ButtonComponent>
					</div>
				</div>
			</ModalCustom>
			{detailRecord && (
				<ModalCustom
					isOpen={openDetail}
					handleCancel={() => setOpenDetail(false)}
					header={"DETAIL ANNOUNCEMENT"}
					width={1000}
				>
					<div className="flex flex-col w-full">
						<div className="grid grid-cols-2 gap-x-20">
							<div className="grid grid-cols-1 gap-2">
								<DetailText label={"Announcement Name"}>
									{detailRecord.announcementName}
								</DetailText>
								<DetailText label={"Description"}>
									{detailRecord.description}
								</DetailText>
								<DetailText label={"Start Date"}>
									{detailRecord.startDate}
								</DetailText>
								<DetailText label={"End Date"}>
									{detailRecord.endDate}
								</DetailText>
								<DetailText label={"Created By"}>
									{detailRecord.createdBy}
								</DetailText>
								<DetailText label={"Created Date"}>
									{detailRecord.createdDate}
								</DetailText>
								<DetailText label={"Last Updated"}>
									{detailRecord.lastUpdated}
								</DetailText>
								<DetailText label={"Update Date"}>
									{detailRecord.updateDate}
								</DetailText>
							</div>
							<div>
								<p className="text-dg-blue text-xs font-bold">CONTENT</p>
								<>{detailRecord.content}</>
							</div>
						</div>
						<div className="flex mt-4 w-full justify-end">
							<ButtonComponent
								// icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
								type={"submit"}
								onClick={() => setOpenDetail(false)}
							>
								Cancel
							</ButtonComponent>
						</div>
					</div>
				</ModalCustom>
			)}
		</LayoutMenu>
	);
};

export default EntityPage;
