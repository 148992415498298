import {
	DeleteOutlined,
	PlusCircleFilled,
	SaveOutlined,
} from "@ant-design/icons";
import { PageHeader, Radio, Select, Spin, Table } from "antd";
import React from "react";
import { useState } from "react";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import ButtonComponent from "../../../../components/ButtonComponent";
import InputComponent from "../../../../components/InputComponent";
import RadioTabs from "../../../../components/RadioTabs";
import SelectComponent from "../../../../components/SelectComponent";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { PRODUCT_PROMO_ROUTES } from "../../../../routes/product_promo/pp_routes";
const ProductCreate = (props) => {
	const { type } = props;
	const [dataCriteria, setDataCriteria] = useState([]);
	const [dataList, setDataList] = useState([]);
	const [pdList, setPdList] = useState([]);

	const [productName, setProductName] = useState("");
	const [serviceType, setServiceType] = useState("");
	const [productType, setProductType] = useState("");
	const [produtClass, setProductClass] = useState("");
	const [description, setDescription] = useState("");
	const [tab, setTab] = useState(1);

	const [addState, setAddState] = useState(false);
	const [row, setRow] = useState(0);

	const [pdName, setPdName] = useState("");
	const [pdValue, setPdValue] = useState("");
	const [pdUOM, setPdUOM] = useState("");
	const [pdDescription, setPdDescription] = useState("");

	const [defaultCol] = useState([
		{
			title: "START DATE",
			dataIndex: "startDate",
			render: (v) => <InputComponent type="text" value={v}></InputComponent>,
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
			render: (v) => <InputComponent type="text" value={v}></InputComponent>,
		},
	]);
	const [columns, setColumns] = useState(defaultCol);
	const columnsPD = [
		{
			title: "NO.",
			dataIndex: "",
			render: (v, r, i) => `${i + 1}.`,
			align: "center",
			width: 40,
		},
		{
			title: "NAME",
			dataIndex: "pdName",
			width: 180,

			render: (v, r, i) =>
				row === i ? (
					<SelectComponent
						value={pdName}
						placeholder="Please select"
						onChange={(v) => {
							setPdName(v);
						}}
					>
						{listPdName.map((e) => (
							<Select.Option value={e.pdName}>{e.pdName}</Select.Option>
						))}
					</SelectComponent>
				) : (
					v
				),
		},
		{
			title: "VALUE",
			dataIndex: "pdValue",
			width: 180,

			render: (v, r, i) =>
				row === i ? (
					<InputComponent
						type="numeric"
						value={pdValue}
						onChange={(e) => {
							setPdValue(e.value);
						}}
					></InputComponent>
				) : (
					v
				),
		},
		{
			title: "UOM",
			dataIndex: "pdUOM",
			width: 180,

			render: (v, r, i) =>
				row === i ? (
					<SelectComponent
						value={pdUOM}
						onChange={(v) => {
							setPdUOM(v);
						}}
					>
						{listUOM.map((e) => (
							<Select.Option value={e.uomName}>{e.uomName}</Select.Option>
						))}
					</SelectComponent>
				) : (
					v
				),
		},
		{
			title: "DESCRIPTION",
			dataIndex: "pdDescription",
			width: 180,
			render: (v, r, i) =>
				row === i ? (
					<InputComponent
						type="text"
						value={pdDescription}
						onChange={(e) => setPdDescription(e.target.value)}
					></InputComponent>
				) : (
					v
				),
		},
		{
			title: "ACTIONS",
			width: 100,
			render: (v, r, i) => (
				<div className="flex justify-center gap-x-2">
					{addState && (
						<SaveOutlined
							onClick={() => {
								handleSavePD();
							}}
							style={{
								color: "#FF7171",
								fontSize: 24,
								justifyItems: "center",
							}}
						></SaveOutlined>
					)}
					<DeleteOutlined
						style={{
							color: "#FF7171",
							fontSize: 24,
							justifyItems: "center",
						}}
					></DeleteOutlined>
				</div>
			),
		},
	];

	const listCity = [
		{
			cityName: "JAKARTA SELATAN",
			cityCode: "CTY3173",
		},
		{
			cityName: "JAKARTA BARAT",
			cityCode: "CTY3174",
		},
	];
	const listArea = [
		{
			areaName: "JAKARTA",
			areaCode: "011",
		},
	];
	const listAccount = [
		{
			name: "RAUL",
			code: "200321",
		},
	];
	const listPdName = [
		{
			pdName: "Minimum",
			pdCode: "2277",
		},
		{
			pdName: "Maximum",
			pdCode: "2288",
		},
	];
	const listUOM = [
		{
			uomName: "M3",
			uomCode: "29",
		},
		{
			uomName: "MMBtu",
			uomCode: "28",
		},
	];

	const test = () => {
		let a = dataList.map((x, i) =>
			Object.assign(
				{},
				...dataCriteria.map((key) => ({ [key]: dataList[i][key] }))
			)
		);
		console.log(a);
		let cd = [];
		dataList.map((x, i) => {
			const c = {
				c: a[i],
				startDate: x.startDate,
				endDate: x.endDate,
			};
			cd = [...cd, c];
		});
		const body = {
			name: productName,
			productType: productType,
			serviceType: serviceType,
			description: description,
			produtClass: produtClass,
			criteriaList: dataCriteria,
			criteriaData: cd,
		};

		console.log({ body, pdList });
	};
	const addRow = (flag) => {
		if (flag === "PD") {
			const newRecord = {
				pdName: "",
				pdValue: "",
				pdUOM: "",
				pdDescription: "",
			};

			setPdList([...pdList, newRecord]);
			setRow(pdList.length);
			setAddState(true);
		} else {
			const newRecord = {
				account: "",
				city: "",
				area: "",
				startDate: "START DATE",
				endDate: "END DATE",
			};

			setDataList([...dataList, newRecord]);
		}
	};
	const addCriteria = (r) => {
		let sortedRecord = [];
		if (r) {
			sortedRecord = r.sort();
			setDataCriteria(r);
		}
		let col = [];
		if (sortedRecord.length !== 0) {
			sortedRecord.map((s) => {
				const c = {
					title: s ? s.toUpperCase() : s,
					dataIndex: s,
					render: (v, r, i) => (
						<Select
							style={{ width: "100%" }}
							placeholder="Please select"
							onChange={(v) => {
								r ? (r[s] = v) : (r[s] = "");
							}}
						>
							{s === "area" ? (
								listArea.map((e) => (
									<Select.Option value={e.areaCode}>{e.areaName}</Select.Option>
								))
							) : s === "city" ? (
								listCity.map((e) => (
									<Select.Option value={e.cityCode}>{e.cityName}</Select.Option>
								))
							) : s === "account" ? (
								listAccount.map((e) => (
									<Select.Option value={e.code}>{e.name}</Select.Option>
								))
							) : (
								<Select.Option value={""}>-</Select.Option>
							)}
						</Select>
					),
				};
				col.push(c);
				setColumns([...col, ...defaultCol]);
			});
		} else {
			setColumns([...defaultCol]);
		}
	};

	const handleSavePD = () => {
		const r = {
			pdName: pdName,
			pdValue: pdValue,
			pdDescription: pdDescription,
			pdUOM: pdUOM,
		};
		setPdList((pre) => {
			return pre.map((d, ix) => {
				if (row === ix) {
					return r;
				} else {
					return d;
				}
			});
		});

		setPdName("");
		setPdValue("");
		setPdDescription("");
		setPdUOM("");
		setRow(-1);
		setAddState(false);
	};
	const routes = [
		{
			path: "",
			breadcrumbName: "Product & Promo",
		},
		{
			path: PRODUCT_PROMO_ROUTES.VIEW_PRODUCT,
			breadcrumbName: "Product",
		},
		{
			path: "",
			breadcrumbName: type !== "update" ? "Create Product" : "Update Product",
		},
	];
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb routes={routes} />
				{/* <PageHeader breadcrumb={<BreadCrumb routes={routes} />} /> */}

				<BaseContainer header={"PRODUCT INFORMATION"}>
					<div className="w-full flex flex-col gap-y-4">
						<p className="text-dg-blue font-semibold text-xs">
							Product Definition:
						</p>
						<div className="grid grid-cols-3 gap-4">
							<InputComponent
								type="text"
								label={"Name"}
								mandatory
								value={productName}
								onChange={(e) => setProductName(e.target.value)}
							></InputComponent>
							<SelectComponent
								mandatory
								label={"Product Type"}
								onChange={(e) => setProductType(e)}
							>
								<Select.Option value="PT1">Product Type 1</Select.Option>
								<Select.Option value="PT1">Product Type 2</Select.Option>
							</SelectComponent>
							<div></div>
							<SelectComponent
								mandatory
								label={"Service Type"}
								onChange={(e) => setServiceType(e)}
							>
								<Select.Option value="ST1">Service Type 1</Select.Option>
								<Select.Option value="ST2">Service Type 2</Select.Option>
							</SelectComponent>
							<SelectComponent
								mandatory
								label={"Product Class"}
								onChange={(e) => setProductClass(e)}
							>
								<Select.Option value="PC1">Product Class 1</Select.Option>
								<Select.Option value="PC2">Product Class 2</Select.Option>
							</SelectComponent>
							<div></div>
							<InputComponent
								type="textarea"
								label={"Description"}
								mandatory
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							></InputComponent>
						</div>
						<p className="text-dg-blue font-semibold text-xs">
							Product Version:
						</p>
						<RadioTabs
							// defaultValue={2}
							value={tab}
							onChange={(e) => setTab(e.target.value)}
						>
							<Radio.Button value={1}>Product Detail</Radio.Button>
							<Radio.Button value={2}>Target Account Selling</Radio.Button>
						</RadioTabs>

						{/* PRODUCT DETAIL */}
						{tab === 1 && (
							<>
								<div className="flex justify-end">
									<ButtonComponent
										icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
										onClick={() => addRow("PD")}
										type="submit"
									>
										Create Product Detail
									</ButtonComponent>
								</div>
								<Table columns={columnsPD} dataSource={[...pdList]}></Table>
							</>
						)}

						{/* TARGET ACCOUNT SELLING */}
						{tab === 2 && (
							<div className="grid grid-cols-1 gap-y-2">
								<ButtonComponent
									// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
									onClick={() => addRow("TAS")}
									type="submit"
								>
									Add Row
								</ButtonComponent>

								<Select
									mode="multiple"
									allowClear
									placeholder="Select Criteria..."
									onChange={(v) => addCriteria(v)}
								>
									<Select.Option value="account">ACCOUNT</Select.Option>
									<Select.Option value="city">CITY</Select.Option>
									<Select.Option value="area">AREA</Select.Option>
								</Select>
								<Table columns={columns} dataSource={[...dataList]}></Table>
							</div>
						)}

						<ButtonComponent
							// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
							onClick={() => test()}
							type="submit"
						>
							Submit
						</ButtonComponent>
					</div>
				</BaseContainer>
			</Spin>
		</LayoutMenu>
	);
};

export default ProductCreate;
