import { Spin, Table, Input, Checkbox } from "antd";
import React, { useRef, useState } from "react";
import LayoutMenu from "../../../../../components/SidebarMenu/LayoutMenu";
import BreadCrumb from "../../../../../components/BreadCrumb";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, FilterOutlined } from "@ant-design/icons";
import BaseContainer from "../../../../../components/BaseContainer";
import DetailText from "../../../../../components/DetailText";
import Highlighter from "react-highlight-words";
import StatusComponent from "../../../../../components/StatusComponent";

const DetailCostCenter = () => {
  const navigate = useNavigate();
  const searchInput = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const dataSource = [
    {
      key: "1",
      siblings: "SCM - 98988",
      status: "Active",
      createdAt: "31/01/2022",
      createdBy: "Putri",
      updatedAt: "31/01/2022",
      updatedBy: "Annisa",
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      align: "center",
      key: "no",
    },
    {
      title: "SIBLINGS",
      dataIndex: "siblings",
      align: "center",
      key: "siblings",
      ...getColumnSearchProps("siblings"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (status) => (
        <div>
          <StatusComponent color="green">{status}</StatusComponent>
        </div>
      ),
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      align: "center",
      key: "createdAt",
      ...getColumnSearchProps("createdAt"),
    },
    {
      title: "CREATED BY",
      dataIndex: "createdBy",
      align: "center",
      key: "createdBy",
      ...getColumnSearchProps("createdBy"),
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      align: "center",
      key: "updatedAt",
      ...getColumnSearchProps("updatedAt"),
    },
    {
      title: "UPDATED BY",
      dataIndex: "updatedBy",
      align: "center",
      key: "updatedBy",
      ...getColumnSearchProps("updatedBy"),
    },
  ];

  return (
    <LayoutMenu>
      <Spin spinning={false} className="w-full top-20" tip="Loading">
        <BreadCrumb
          pageName={[
            "System Setup",
            "Master Data",
            "Cost Center",
            "Detail Cost Center",
          ]}
        />

        <NavLink onClick={() => navigate(-1)}>
          <div className="flex align-middle gap-x-2 mb-4">
            <ArrowLeftOutlined
              style={{ fontSize: "24px", color: "#3C6DB2" }}
            ></ArrowLeftOutlined>
            <span className="text-[#3C6DB2]">Back</span>
          </div>
        </NavLink>

        <BaseContainer header={"DETAIL COST CENTER"}>
          <div className="flex flex-col w-full gap-y-8">
            <div className="grid grid-cols-3 gap-y-2.5">
              <DetailText label={"Cost Center Name"}></DetailText>
              <DetailText label={"Created At"}></DetailText>
              <DetailText label={"Siblings"}></DetailText>
              <DetailText label={"Code"}></DetailText>
              <DetailText label={"Created By"}></DetailText>
              <DetailText label={"Parent"}></DetailText>
              <DetailText label={"Latest Update"}></DetailText>
              <DetailText label={"Area"}></DetailText>
              <DetailText label={"Update By"}></DetailText>
            </div>

            <h3 className="text-[20px] text-[#3C6DB2] font-bold">Siblings</h3>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{
                position: ["bottomCenter"],
              }}
              size="small"
              total={50}
              showSizeChanger
            />
          </div>
        </BaseContainer>
      </Spin>
    </LayoutMenu>
  );
};

export default DetailCostCenter;
