import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Popover, Spin } from "antd";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BaseContainer from "../../../../../components/BaseContainer";
import BreadCrumb from "../../../../../components/BreadCrumb";
import ButtonComponent from "../../../../../components/ButtonComponent";
import DetailText from "../../../../../components/DetailText";
import InputComponent from "../../../../../components/InputComponent";
import ModalCustom from "../../../../../components/Modal/ModalCustom";
import LayoutMenu from "../../../../../components/SidebarMenu/LayoutMenu";

const JobCreate = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [previewAction, setPreviewAction] = useState(false);

  const navigate = useNavigate();

  const clearStateAction = () => {
    setName("");
    setDescription("");
  };

  const saveAction = () => {
    const body = {
      name: name,
      description: description,
    };
    console.log(body);
  };

  const previewActionConfirm = () => {
    setPreviewAction(true);
  };

  const content = (
    <span className="text-[11px] font-bold  text-[#3C6DB2]">
      Please fill mandatory form
    </span>
  );
  return (
    <LayoutMenu>
      <Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
        <BreadCrumb pageName={["System Setup", "Master Data", "Create Job"]} />
        <NavLink onClick={() => navigate(-1)}>
          <div className="flex align-middle gap-x-2 mb-4">
            <ArrowLeftOutlined
              style={{ fontSize: "24px", color: "#3C6DB2" }}
            ></ArrowLeftOutlined>
            <span className="text-dg-blue">Back</span>
          </div>
        </NavLink>
        <BaseContainer header={"CREATE JOB"}>
          <div className="grid grid-cols-2 w-full gap-10">
            <InputComponent
              type="text"
              label={"Job Name"}
              mandatory
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></InputComponent>
            <div></div>
            <InputComponent
              type="text"
              label={"Description"}
              // mandatory
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></InputComponent>
            <div className="flex justify-end mt-20">
              <ButtonComponent
                icon={<DeleteOutlined style={{ fontSize: "16px" }} />}
                onClick={() => clearStateAction()}
                type="default"
              >
                Clear
              </ButtonComponent>
              {name ? (
                <ButtonComponent
                  onClick={() => previewActionConfirm()}
                  type="submit"
                >
                  Save
                </ButtonComponent>
              ) : (
                <Popover placement="top" content={content} trigger="hover">
                  <div>
                    <ButtonComponent
                      disabled
                      onClick={() => previewActionConfirm()}
                      type="submit"
                    >
                      Save
                    </ButtonComponent>
                  </div>
                </Popover>
              )}
            </div>
          </div>
        </BaseContainer>
      </Spin>

      <ModalCustom
        isOpen={previewAction}
        handleCancel={() => setPreviewAction(false)}
        header={"CONFIRMATION"}
        width={500}
      >
        <div className="flex flex-col w-full gap-y-10">
            <DetailText label={"Job name"}>{name}</DetailText>
            <DetailText label={"Description"}>{description}</DetailText>
        </div>

        <div className="flex justify-end">
          <ButtonComponent
            onClick={() => setPreviewAction(false)}
            type="default"
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
            onClick={() => saveAction()}
            type="submit"
          >
            Submit
          </ButtonComponent>
        </div>
      </ModalCustom>
    </LayoutMenu>
  );
};

export default JobCreate;
