import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import entityService from "../services/entity";
import { setMessage } from "./message";

const initialState = {
	data: [],
	loading: false,
};

export const getListEntity = createAsyncThunk(
	"GET_LIST_ENTITY",
	async (_, thunkAPI) => {
		try {
			const response = await entityService.getEntity();
			// thunkAPI.dispatch(setMessage(response.data.message));
			// console.log(response.data, " entity data");
			return response;
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			thunkAPI.dispatch(setMessage(message));
			return thunkAPI.rejectWithValue();
		}
	}
);
export const getAllEntityPaginate = createAsyncThunk(
	"GET_ALL_ENTITY_PAGINATE",
	async ({ page, pageSize }) => {
		try {
			const response = await entityService.getAllEntityPaginate(page, pageSize);
			console.log(pageSize, " = page slice");
			return response.data;
		} catch (error) {
			console.log(error, " = error slice");
			return error;
		}
	}
);
export const createEntity = createAsyncThunk("CREATE_ENTITY", async (body) => {
	try {
		const response = await entityService.createEntity(body);
		console.log(response, " = success user create slice");
		return response.data;
	} catch (error) {
		console.log(error, " = error create user slice");
		return error;
	}
});
export const inactiveEntity = createAsyncThunk(
	"INACTIVE_ENTITY",
	async (id) => {
		try {
			const response = await entityService.inactiveEntity(id);
			//    console.log(response, ' = blabla');
			return response.data;
		} catch (error) {
			console.log(error, "= error inactive");
			return error;
		}
	}
);
export const getDetailEntity = createAsyncThunk(
	"GET_DETAIL_ENTITY",
	async (id) => {
		try {
			const response = await entityService.getDetailEntity(id);
			//    console.log(response, ' = blabla');
			return response.data;
		} catch (error) {
			console.log(error, "= error inactive");
			return error;
		}
	}
);
export const deleteEntity = createAsyncThunk("DELETE_ENTITY", async (id) => {
	try {
		const response = await entityService.deleteEntity(id);
		//    console.log(response, ' = blabla');
		return response.data;
	} catch (error) {
		console.log(error, "= error inactive");
		return error;
	}
});
export const updateEntity = createAsyncThunk("UPDATE_ENTITY", async (body) => {
	try {
		const response = await entityService.updateEntity(body);
		console.log(response, " = success user create slice");
		return response.data;
	} catch (error) {
		console.log(error, " = error create user slice");
		return error;
	}
});
export const downloadExcel = createAsyncThunk(
	"DOWNLOAD_ENTITY_EXCEL",
	async (_, thunkAPI) => {
		try {
			const response = await entityService.downloadExcel();
			// thunkAPI.dispatch(setMessage(response.data.message));
			// console.log(response.data, " entity data");
			return response;
		} catch (error) {
			console.log(error, " = error donwload slice");
			return error;
		}
	}
);

const entitySlice = createSlice({
	name: "entity",
	initialState,
	extraReducers: {
		// get all entity
		[getListEntity.fulfilled]: (state, action) => {
			state.data = action.payload.result;
			state.loading = false;
		},
		[getListEntity.rejected]: (state, action) => {
			state.data = null;
			state.loading = true;
		},
		[getListEntity.pending]: (state, action) => {
			state.loading = true;
		},
		// get all entity paginate
		[getAllEntityPaginate.pending]: (state, action) => {
			state.loading = true;
			state.data = action.payload;
		},
		[getAllEntityPaginate.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[getAllEntityPaginate.rejected]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		// create entity
		[createEntity.pending]: (state, action) => {
			state.loading = true;
			state.data = action.payload;
		},
		[createEntity.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[createEntity.rejected]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		// inactive entity
		[inactiveEntity.pending]: (state, action) => {
			state.loading = true;
		},
		[inactiveEntity.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[inactiveEntity.rejected]: (state, action) => {
			state.loading = false;
		},
		// detail entity
		[getDetailEntity.pending]: (state, action) => {
			state.data = action.payload;
			state.loading = true;
		},
		[getDetailEntity.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[getDetailEntity.rejected]: (state, action) => {
			state.data = action.payload;
			state.loading = true;
		},
		// delete entity
		[deleteEntity.pending]: (state, action) => {
			state.loading = true;
		},
		[deleteEntity.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[deleteEntity.rejected]: (state, action) => {
			state.loading = false;
		},
		// update entity
		[updateEntity.pending]: (state, action) => {
			state.data = action.payload;
			state.loading = true;
		},
		[updateEntity.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[updateEntity.rejected]: (state, action) => {
			state.data = action.payload;
			state.loading = true;
		},
		// download entity
		[downloadExcel.pending]: (state) => {
			state.loading = true;
		},
		[downloadExcel.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[downloadExcel.rejected]: (state) => {
			state.loading = true;
		},
	},
});

const { reducer } = entitySlice;
export default reducer;
