import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, LinkOutlined, MoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Popover, Spin, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import ButtonComponent from '../../../../components/ButtonComponent';
import DetailText from '../../../../components/DetailText';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';
import SVGIcon from "../../../../assets/Icon/index";
import ModalCustom from '../../../../components/Modal/ModalCustom';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailUser } from '../../../../redux/slices/user';
import { USER_ROUTES } from '../../../../routes/user_management/user_routes';
import moment from 'moment';
import StatusComponent from '../../../../components/StatusComponent';


const DetailUser = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const {data, loading} = useSelector((state) => state.user);
    const location = useLocation()
    const params = useParams()
    const datas = data?.data;
    const dataMenu = datas?.tUserGroupAccess?.gaMenu;
    // console.log(datas?.tUserGroupAccess?.gaMenu, ' =data menu')
    useEffect(() => {
        dispatch(getDetailUser(location.state.id));
    }, []);
    const handleCancel = () => {
        setOpenModal(false)
    }

    const detailText1 = [
        {
            label: "Username",
            text: "adri",
        },
        {
            label: "Employee",
            text: "Adrianus",
        },
        {
            label: "User Level",
            text: "983012312",
        },
        {
            label: "Auth Type",
            text: "9801293123",
        },
        {
            label: "Group Access",
            text: "IT",
        }
    ]
    const dataSourceGenerate = [
        {
            key: "1",
            remark: "Mike",
            link: "https://spontan.uhuy",
            start_date: `${moment().format("HH MMM YYYYY HH:mm:ss")}`,
            end_date: `${moment().format("HH MMM YYYYY HH:mm:ss")}`,
            created_by: "Annisa",
            created_at: `${moment().format("HH MMM YYYYY HH:mm:ss")}`,
        },
        {
            key: "2",
            remark: "Mike",
            link: "https://spontan.uhuy",
            start_date: `${moment().format("HH MMM YYYYY HH:mm:ss")}`,
            end_date: `${moment().format("HH MMM YYYYY HH:mm:ss")}`,
            created_by: "Annisa",
            created_at: `${moment().format("HH MMM YYYYY HH:mm:ss")}`,
        },
    ];

    const coloumnsGA = [
        {
            title: "NO",
            dataIndex: "key",
            key: "key",
            fixed: 'left',
            width: 60,
            align: "center",
            render : (text, object, index) => index + 1
        },
        {
            title: "Group Access",
            dataIndex: "gaId",
            key: "gaId",
            width: 115,
            align: "center",
        },
        {
            title: "Start Date",
            dataIndex: "createdDate",
            key: "createdDate",
            align: "center",
            width: 185,
            render: (index) => (
                <span>{moment(index).format("DD MMM YYYY HH:mm:ss")}</span>
            ),
        },
        {
            title: "End Date",
            dataIndex: "updatedDate",
            key: "updatedDate",
            align: "center",
            width: 185,
            render: (index) => (
                <span>{moment(index).format("DD MMM YYYY HH:mm:ss")}</span>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 70,
            align: "center",
            render: (index) => {
                return (
                    <div className="flex justify-center">
                        <StatusComponent color={index === "ACTIVE" ? "active" : "inactive"}>
                            {index}
                        </StatusComponent>
                    </div>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: 70,
            render: () => {
                return (
                    <div className={"w-full flex justify-center"}>
                        <Popover
                            content={
                                <div>
                                    <Link
                                        // to={USER_ROUTES.DETAIL_USER}
                                        // state={{ id: record?.userId }}
                                    >
                                        <ButtonComponent
                                            icon={<UnorderedListOutlined />}
                                            border={false}
                                        >
                                            <span className={"text-black"}> Detail</span>
                                        </ButtonComponent>
                                    </Link>
                                    <Link
                                        // to={USER_ROUTES.UPDATE_USER}
                                        // state={{ id: record?.userId }}
                                    >
                                        <ButtonComponent icon={<EditOutlined />} border={false}>
                                            <span className={"text-black"}> Update User</span>
                                        </ButtonComponent>
                                    </Link>
                                    {/* <NavLink to={USER_ROUTES.GENERATE_PASSWORD}> */}
                                    <NavLink>
                                        <ButtonComponent icon={<LinkOutlined />} border={false}>
                                            <span className={"text-black"}> Generate Password</span>
                                        </ButtonComponent>
                                    </NavLink>
                                </div>
                            }
                            trigger={"click"}
                            placement="bottomRight"
                        >
                            <ButtonComponent icon={<MoreOutlined />} border={false} />
                        </Popover>
                        <ButtonComponent icon={<DeleteOutlined />}  onClick={() => setOpenModal(true)} border={false}  />
                    </div>
                )
            }
        },
    ];
    const coloumnsGenerate = [
        {
            title: "NO",
            dataIndex: "key",
            key: "key",
            fixed: 'left',
            width: 60,
            align: "center",
        },
        {
            title: "Remark",
            dataIndex: "remark",
            key: "remark",
            align: "center",

        },
        {
            title: "Link",
            dataIndex: "link",
            key: "link",
            align: "center",
        },
        {
            title: "Start Date",
            dataIndex: "start_date",
            key: "start_date",
        },
        {
            title: "End Date",
            dataIndex: "end_date",
            key: "end_date",
        },
        
        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by",
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
        },
    ];

    const routes = [
        {
            path: "",
            breadcrumbName: "User Management"
        },
        {
            path: USER_ROUTES.VIEW_USER,
            breadcrumbName: "List User"
        },
        {
            path: "",
            breadcrumbName: "Detail User"
        },
    ]
    const DetailUser = (
        <>
            <DetailText label={"Username"}>{datas?.username}</DetailText>
            <DetailText label={"Phone Number"}>{datas?.phone}</DetailText>
            <DetailText label={"Start Date"}>{moment(datas?.startDate).format('DD-MM-YYYY HH:mm:ss')}</DetailText>
            <DetailText label={"Employee"}>{datas?.employeeId}</DetailText>
            <DetailText label={"Email"}>{datas?.email}</DetailText>
            <DetailText label={"End Date"}>{moment(datas?.endDate).format('DD-MM-YYYY HH:mm:ss')}</DetailText>
            <DetailText label={"User Level"}>{datas?.userType}</DetailText>
            <DetailText label={"Group Access"}>{datas?.tUserGroupAccess?.gaId}</DetailText>
            <DetailText label={"Created By"}>{datas?.createdBy}</DetailText>
            <DetailText label={"Auth Type"}>{datas?.authType}</DetailText>
            <DetailText label={"Created At"}>{moment(datas?.createdDate).format('DD-MM-YYYY HH:mm:ss')}</DetailText>
            <DetailText label={"Last Updated"}>{moment(datas?.updatedDate).format('DD-MM-YYYY HH:mm:ss')}</DetailText>
        </>
    )
    return (
        <LayoutMenu>
            <div>
                <BreadCrumb routes={routes}/>
                <Spin spinning={loading}>
                    <BaseContainer header={'USER INFORMATION'}>
                        <div className={'w-full h-40 flex flex-wrap flex-col'}>
                            {DetailUser}
                        </div>
                    </BaseContainer>
                    <BaseContainer header={'GROUP ACCESS HISTORY'}>
                        <Table dataSource={dataMenu}
                            columns={coloumnsGA}
                            className={'w-full'}
                            pagination={{
                                showSizeChanger : true,
                                position: ["topRight"],
                                showTotal :(total, range) =>
                                    `Showing ${range[0]} to ${range[1]} of ${total} records`
                                }
                            }
                            scroll={{ y: 120 }} />
                    </BaseContainer>
                    <BaseContainer header={'GENERATE LINK PASSWORD HISTORY'}>
                        <Table dataSource={dataSourceGenerate}
                            columns={coloumnsGenerate}
                            className={'w-full'}
                            pagination={{
                                showSizeChanger: true,
                                position: ["topRight"],
                                showTotal: (total, range) =>
                                    `Showing ${range[0]} to ${range[1]} of ${total} records`
                                }
                            }
                            scroll={{ y: 120 }} />
                    </BaseContainer>
                    {/* <Link to={USER_ROUTES.VIEW_USER} reloadDocument> */}
                    <NavLink onClick={() => navigate(-1)}>
                    <div className="flex align-middle gap-x-2 mb-4">
                        <ButtonComponent icon={<ArrowLeftOutlined/>} type={'submit'}>
                            Back
                        </ButtonComponent>
                    </div>
                    </NavLink>
                    {/* </Link> */}
                </Spin>
            </div>
            <ModalCustom isOpen={openModal} handleCancel={handleCancel} header={"GROUP ACCESS DETAIL"}>
                <div className={'w-full flex flex-col'}>
                    <div className={'w-full flex'}>
                        <div className={'w-full flex flex-col gap-3'}>
                            {detailText1.map((index, key) => {
                                return (
                                    <DetailText label={index.label}>{index.text}</DetailText>
                                )
                            })}
                        </div>
                    </div>
                    <div className={'flex w-full justify-end mt-12'}>
                        <ButtonComponent type={'submit'} onClick={handleCancel}>Close</ButtonComponent>
                    </div>
                </div>
            </ModalCustom>
        </LayoutMenu>
    );
}

export default DetailUser;
