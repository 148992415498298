import {
	CloseCircleOutlined,
	DeleteOutlined,
	DownloadOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
	LinkOutlined,
	MoreOutlined,
	PlusOutlined,
	UnorderedListOutlined,
	UploadOutlined,
	WarningOutlined,
} from "@ant-design/icons";
import { Alert, Checkbox, Pagination, Popover, Select, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Link,
	NavLink,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import ButtonComponent from "../../../../components/ButtonComponent";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import StatusComponent from "../../../../components/StatusComponent";
import { USER_ROUTES } from "../../../../routes/user_management/user_routes";
import {
	donwloadedExcel,
	getAllUserPaginate,
	getListUser,
	inactiveUser,
} from "../../../../redux/slices/user";
import moment from "moment";
import { ExportToExcel } from "../../../../utils/ExportToExcel";
import { ModalConfirm, ModalError, ModalSuccess } from "../../../../components/Modal/ModalPopUp";
import SVGIccon from "../../../../assets/Icon/index";
import Icon from "@ant-design/icons/lib/components/Icon";
const { Option } = Select;
const UserPage = () => {
	const [openModal, setOpenModal] = useState(false);
	const [openModalSuccess, setOpenModalSuccess] = useState(false);
	const [openModalError, setOpenModalError] = useState(false);
	const [typeModal, setTypeModal] = useState('');
	const dispatch = useDispatch();
	const { data, loading } = useSelector((state) => state.user);
	const [userId, setUserId] = useState("");
	const dataSource = data?.result;
	const fileName = "myfile";
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [activate, setActivate] = useState(false);
	useEffect(() => {
		dispatch(getAllUserPaginate({ page, pageSize }));
	}, [dispatch]);

	// console.log(dataSource, ' ini datana');
	// console.log(userId, " ini userId");
	const handleCancel = () => {
		setOpenModal(false);
		setOpenModalSuccess(false);
		setOpenModalError(false);
	};
	const handleInactive = async () => {
		try {
			await dispatch(inactiveUser(userId));
			await dispatch(getAllUserPaginate({ page, pageSize }));
			setOpenModal(false);
			setOpenModalSuccess(true);
		} catch (error) {
			setOpenModalSuccess(false);
			console.log(error, " =error");
			setOpenModalError(true);
		}
	};

	const handleActive = () => { };
	const handleDeleteUser = () => { };
	const columns = [
		{
			title: "NO",
			width: 60,
			align: "center",
			render: (text, object, index) => index + 1,
		},
		{
			title: "USERNAME",
			dataIndex: "username",
			key: "username",
			align: "center",
			width: 120,
		},
		{
			title: "EMPLOYEE",
			dataIndex: "employeeId",
			key: "employee",
			width: 115,
			align: "center",
		},
		{
			title: "EMAIL",
			dataIndex: "email",
			key: "email",
			width: 200,
			align: "center",
		},
		{
			title: "PHONE NUMBER",
			align: "right",
			dataIndex: "phone",
			width: 185,
			key: "phone",
		},
		{
			title: "USER LEVEL",
			dataIndex: "userType",
			width: 120,
			align: "center",
			key: "user_level",
		},
		{
			title: "AUTH TYPE",
			dataIndex: "authType",
			key: "authType",
			width: 120,
			align: "center",
		},
		{
			title: "GROUP ACCESS",
			dataIndex: "gaId",
			key: "group_access",
			width: 140,
			align: "center",
		},
		{
			title: "START DATE",
			dataIndex: "startDate",
			key: "startDate",
			align: "center",
			width: 185,
			render: (index) => (
				<span>{moment(index).format("DD-MM-YYYY HH:mm:ss")}</span>
			),
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
			key: "endDate",
			align: "center",
			width: 185,
			render: (index) => (
				<span>{moment(index).format("DD-MM-YYYY HH:mm:ss")}</span>
			),
		},
		{
			title: "STATUS",
			dataIndex: "status",
			key: "status",
			render: (index) => {
				return (
					<div className="flex justify-center">
						<StatusComponent color={index === "ACTIVE" ? "active" : "inactive"}>
							{index}
						</StatusComponent>
					</div>
				);
			},
			width: 175,
		},
		{
			title: "ACTIVATE",
			dataIndex: "activate",
			key: "activate",
			render: (index, record) => {
				return (
					<div className="flex justify-center">
						<Checkbox
							onClick={() => {
								setOpenModal(true);
								setUserId(record?.userId);
								setActivate(record?.isActive);
							}}
							checked={record?.isActive}
						/>
					</div>
				);
			},
			width: 105,
		},
		{
			title: "ACTION",
			dataIndex: "action",
			key: "action",
			render: (item, record) => {
				return (
					<div className={"w-full flex justify-center"}>
						<Popover
							content={
								<div>
									<Link
										to={USER_ROUTES.DETAIL_USER}
										state={{ id: record?.userId }}
									>
										<ButtonComponent
											icon={<UnorderedListOutlined />}
											border={false}
										>
											<span className={"text-black"}> Detail</span>
										</ButtonComponent>
									</Link>
									<Link
										to={USER_ROUTES.UPDATE_USER}
										state={{ id: record?.userId }}
									>
										<ButtonComponent icon={<EditOutlined />} border={false}>
											<span className={"text-black"}> Update User</span>
										</ButtonComponent>
									</Link>
									<NavLink to={USER_ROUTES.GENERATE_PASSWORD}>
										<ButtonComponent icon={<LinkOutlined />} border={false}>
											<span className={"text-black"}> Generate Password</span>
										</ButtonComponent>
									</NavLink>
								</div>
							}
							trigger={"click"}
							placement="bottomRight"
						>
							<ButtonComponent icon={<MoreOutlined />}  border={false} />
						</Popover>
						<ButtonComponent
							icon={<DeleteOutlined />}
							onClick={() => {
								setOpenModal(true)
								setTypeModal('delete')
							}}
							border={false} />
					</div>
				);
			},
			width: 100,
		},
	];

	const [displayColumn, setDisplayColumn] = useState(columns);
	const [optionSelectedCol, setOptionSelectedCol] = useState([]);
	const handleDisplayColumn = (value) => {
		setDisplayColumn(() =>
			columns.filter((col) => {
				return !value.includes(col.title);
			})
		);
		setOptionSelectedCol(value);
	};
	const handleChange = (page) => {
		setPage(page);
		dispatch(getAllUserPaginate({ page, pageSize }));
	};
	const handleChangeSize = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
		dispatch(getAllUserPaginate({ page, pageSize }));
	};
	const routes = [
		{
			path: "",
			breadcrumbName: "User Management",
		},
		{
			path: USER_ROUTES.VIEW_USER,
			breadcrumbName: "List User",
		},
	];

	return (
		<LayoutMenu>
			<BreadCrumb routes={routes} />
			<div className={"w-full flex gap-2 justify-end"}>
				<NavLink to={USER_ROUTES.CHANGE_AUTH}>
					<ButtonComponent type={"submit"} icon={<PlusOutlined />}>
						{" "}
						Change Auth Type
					</ButtonComponent>
				</NavLink>
				<ExportToExcel apiData={dataSource} fileName={fileName} />
				<NavLink to={USER_ROUTES.UPLOAD_USER}>
					<ButtonComponent type={"submit"} icon={<UploadOutlined />}>
						{" "}
						Upload
					</ButtonComponent>
				</NavLink>
				<NavLink to={USER_ROUTES.CREATE_USER}>
					<ButtonComponent type={"submit"} icon={<PlusOutlined />}>
						{" "}
						Add User
					</ButtonComponent>
				</NavLink>
			</div>
			<Spin spinning={loading}>
				<BaseContainer header={"User List"}>
					<div className={"flex flex-col w-full"}>
						<div className={"w-full flex mb-5 gap-2 justify-between"}>
							<Select
								mode="multiple"
								placeholder="Show All Column"
								className={"w-2/6"}
								maxTagCount={3}
								onChange={handleDisplayColumn}
							>
								{columns.map((col) => (
									<Option
										key={col.title}
										value={col.title}
										disabled={
											optionSelectedCol.length > 3
												? optionSelectedCol.includes(col.title)
													? false
													: true
												: false
										}
									>
										{col.title}
									</Option>
								))}
							</Select>
							<Pagination
								total={data?.page?.totalElements}
								className={"pr-1"}
								showSizeChanger
								current={page}
								pageSize={pageSize}
								onChange={handleChange}
								onShowSizeChange={handleChangeSize}
								showTotal={(total, range) =>
									`Showing ${range[0]} to ${range[1]} of ${total} records`
								}
							/>
						</div>
						<Table
							size="small"
							dataSource={data?.result}
							columns={displayColumn}
							scroll={{ y: 525 }}
							bordered
							onRow={(record, rowIndex) => {
								return {
									onClick: () => {
										setUserId(record.userId);
									},
								};
							}}
							pagination={false}
							className={"w-full"}
						/>
					</div>
				</BaseContainer>
			</Spin>
			<ModalConfirm
				isOpen={openModal}
				handleCancel={handleCancel}
				handleOk={handleInactive}
				// type={}
			>
				<div className={"flex flex-col px-8 py-6"}>
					<div className="w-full justify-center items-center flex my-2">
						<div className={'mr-4 text-[#3C6DB2] text-2xl'}>
							<ExclamationCircleOutlined />
						</div>
						<span className={"text-[1.3rem] text-black text-bold"}>
							{`Are you sure want to ${
								activate === true ? "inactivation" : "activation"
							} user?`}
						</span>
					</div>
					<div className={"w-full justify-center my-4 flex text-sm"}>
						<Alert
							message={activate === true ?
								'If you activate this data, it will be inactivate again.'
								: typeModal === 'delete' ? 'Warning if you inactive this data, it will be permanently.':
									'If you inactive this data, it will be activate again.'}
							type={activate === true ?
								'error'
								: typeModal === 'delete' ? 'error' 
								:'success'}
							icon={<WarningOutlined />}
							 showIcon />
					</div>
				</div>
			</ModalConfirm>
			<ModalSuccess isOpen={openModalSuccess} handleCancel={handleCancel}>
				<div className="w-full justify-center flex mt-6 mb-2 text-[#a4be37]">
					<CloseCircleOutlined className={"text-2xl mr-3"} />
					<span className={"text-xl text-bold text-black"}>
						Inactivation successfull
					</span>
				</div>
				<div className={'w-full justify-center flex'}>
					<span> Your data has been inactive.</span>
				</div>
				<div className={"w-full justify-end flex pr-8 pb-5"}>
					<ButtonComponent
						type={"submit"}
						onClick={() => setOpenModalSuccess(false)}
					>
						Ok
					</ButtonComponent>
				</div>
			</ModalSuccess>
			<ModalError isOpen={openModalError} handleCancel={handleCancel}>
				<div className="w-full justify-center flex mt-6 mb-2">
					<CloseCircleOutlined className={"text-2xl mr-3"} />
					<span className={"text-xl text-bold text-black"}>
						Inactivation successfull
					</span>
				</div>
				<div className={'w-full justify-center flex'}>
					<span> Your data has been inactive.</span>
				</div>
				<div className={"w-full justify-end flex pr-8 pb-5"}>
					<ButtonComponent
						type={"reject"}
						onClick={() => setOpenModalError(false)}
					>
						Ok
					</ButtonComponent>
				</div>
			</ModalError>
		</LayoutMenu>
	);
};

export default UserPage;
