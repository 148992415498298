import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8361_112373)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#4B465C"
      />
      <path
        d="M14.525 23.717C14.7551 23.6724 14.9839 23.621 15.211 23.563C15.5961 23.4553 15.9756 23.3285 16.348 23.183C16.7209 23.0387 17.0864 22.8758 17.443 22.695C17.7997 22.5135 18.1472 22.3142 18.484 22.098C18.8204 21.8817 19.1458 21.6487 19.459 21.4C19.7727 21.1509 20.0738 20.8862 20.361 20.607C20.6478 20.3284 20.9205 20.0357 21.178 19.73C21.4358 19.4239 21.6781 19.1052 21.904 18.775C22.1299 18.4453 22.3391 18.1045 22.531 17.754C22.7229 17.4029 22.8971 17.0425 23.053 16.674C23.209 16.3054 23.3466 15.9293 23.465 15.547C23.5664 15.2123 23.6532 14.8733 23.725 14.531L16.595 7.40198C15.9929 6.79718 15.2771 6.31743 14.4888 5.99032C13.7006 5.66321 12.8554 5.49521 12.002 5.49598C11.1478 5.49504 10.3019 5.66295 9.51277 5.99005C8.72368 6.31715 8.00703 6.79699 7.40401 7.40198C6.79947 8.00518 6.31984 8.72171 5.9926 9.51053C5.66535 10.2993 5.4969 11.145 5.4969 11.999C5.4969 12.853 5.66535 13.6986 5.9926 14.4874C6.31984 15.2762 6.79947 15.9928 7.40401 16.596L14.525 23.716V23.717Z"
        fill="#4B465C"
      />
      <path
        d="M12.001 5.49695C13.663 5.49695 15.325 6.13195 16.597 7.40295C17.2015 8.00616 17.6812 8.72269 18.0084 9.51151C18.3357 10.3003 18.5041 11.1459 18.5041 12C18.5041 12.854 18.3357 13.6996 18.0084 14.4884C17.6812 15.2772 17.2015 15.9937 16.597 16.597C15.9938 17.2015 15.2773 17.6811 14.4884 18.0084C13.6996 18.3356 12.854 18.5041 12 18.5041C11.146 18.5041 10.3004 18.3356 9.51155 18.0084C8.72273 17.6811 8.00621 17.2015 7.403 16.597C6.79846 15.9937 6.31884 15.2772 5.99159 14.4884C5.66434 13.6996 5.4959 12.854 5.4959 12C5.4959 11.1459 5.66434 10.3003 5.99159 9.51151C6.31884 8.72269 6.79846 8.00616 7.403 7.40295C8.00602 6.79796 8.72267 6.31813 9.51176 5.99103C10.3008 5.66393 11.1468 5.49602 12.001 5.49695ZM14.961 9.99895C14.8663 10.008 14.7762 10.0437 14.701 10.102L11.047 12.842L9.354 11.15C8.987 10.768 8.264 11.49 8.647 11.857L10.647 13.857C10.7337 13.9389 10.8461 13.9884 10.9651 13.997C11.0841 14.0055 11.2025 13.9727 11.3 13.904L15.3 10.904C15.636 10.659 15.429 10.006 15.013 9.99995C14.996 9.99908 14.979 9.99908 14.962 9.99995L14.961 9.99895Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8361_112373">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
