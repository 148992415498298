import {
  CloseCircleOutlined,
  DownloadOutlined,
  FilterOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Checkbox, Input, Spin, Table, Tooltip, Radio } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useRef } from "react";
import Highlighter from "react-highlight-words";
import { NavLink } from "react-router-dom";
import SVGIcon from "../../../../assets/Icon/IconEdit";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import ButtonComponent from "../../../../components/ButtonComponent";
import DetailText from "../../../../components/DetailText";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import StatusComponent from "../../../../components/StatusComponent";
import { dateFormat } from "../../../../utils";
import RadioTabs from "../../../../components/RadioTabs";

const Delegation = () => {
  const [modalInactive, setModalInactive] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [detailRecord, setDetailRecord] = useState({});
  const [reqOrApp, setReqOrApp] = useState("Delegation Request");
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const openDelete = (r) => {
    setDetailRecord(r);
    setModalDelete(true);
  };

  const openDetail = (r) => {
    setDetailRecord(r);
    setModalDetail(true);
  };

  const delegationList = [
    {
      reason: "Sick",
      delegateTo: "Raul",
      startDate: "2022-11-14T03:00:00.000+00:00",
      endDate: "2022-11-14T03:00:00.000+00:00",
      status: "WAITING",
      requestDate: "2022-11-14T03:00:00.000+00:00",
      approveRejectDate: null,
      approveRejectRemark: null,
    },
    {
      reason: "Maternity Leave",
      delegateTo: "Dealitha W",
      startDate: "2022-11-14T03:00:00.000+00:00",
      endDate: "2022-11-14T03:00:00.000+00:00",
      status: "REJECTED",
      requestDate: "2022-11-14T03:00:00.000+00:00",
      approveRejectDate: null,
      approveRejectRemark: null,
    },
    {
      reason: "Sick",
      delegateTo: "Raul",
      startDate: "2022-11-14T03:00:00.000+00:00",
      endDate: "2022-11-14T03:00:00.000+00:00",
      status: "APPROVED",
      requestDate: "2022-11-14T03:00:00.000+00:00",
      approveRejectDate: null,
      approveRejectRemark: null,
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columnsReq = [
    // tableNumbering(page),
    {
      title: "REASON",
      dataIndex: "reason",
      width: 121,
      ...getColumnSearchProps("reason"),
    },
    {
      title: "DELEGATE TO",
      dataIndex: "delegateTo",
      width: 121,
      ...getColumnSearchProps("delegationTo"),
    },
    {
      title: "START DATE",
      dataIndex: "startDate",
      width: 121,
      ...getColumnSearchProps("startDate"),
    },
    {
      title: "END DATE",
      dataIndex: "endDate",
      width: 121,
      ...getColumnSearchProps("endDate"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: 100,
      ...getColumnSearchProps("status"),
      render: (status) => (
        <div>
          {status === "APPROVED" && (
            <StatusComponent color="green">{status}</StatusComponent>
          )}

          {status === "REJECTED" && (
            <StatusComponent color="red">{status}</StatusComponent>
          )}

          {status === "WAITING" && (
            <StatusComponent color="yellow">{status}</StatusComponent>
          )}
        </div>
      ),
    },
    {
      title: "ACTIONS",
      align: "center",
      width: 100,
      render: (v, r, i) => {
        return (
          <div className="flex justify-center gap-4">
            <Tooltip title="Detail">
              <UnorderedListOutlined
                onClick={() => {
                  openDetail(r);
                }}
                style={{
                  color: "#0880AE",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
            <NavLink to={"/app/user-management/delegation/update"}>
              <Tooltip title="Edit">
                <div>
                  <SVGIcon
                    name="IconEdit"
                    width={24}
                    style={{
                      color: "#0880AE",
                      fontSize: 24,
                      justifyItems: "center",
                    }}
                  />
                </div>
              </Tooltip>
            </NavLink>
            <Tooltip title="Delete">
              <CloseCircleOutlined
                onClick={() => {
                  openDelete(r);
                }}
                style={{
                  color: "#FF7171",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
          </div>
        );
      },
      key: "action",
    },
  ];
  const columnsApp = [
    // tableNumbering(page),
    {
      title: "REASON",
      dataIndex: "reason",
      width: 121,
      ...getColumnSearchProps("reason"),
    },
    {
      title: "DELEGATE TO",
      dataIndex: "delegateTo",
      width: 121,
      ...getColumnSearchProps("delegationTo"),
    },
    {
      title: "START DATE",
      dataIndex: "startDate",
      width: 121,
      ...getColumnSearchProps("startDate"),
    },
    {
      title: "END DATE",
      dataIndex: "endDate",
      width: 121,
      ...getColumnSearchProps("endDate"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: 100,
      ...getColumnSearchProps("status"),
      render: (status) => (
        <div>
          {status === "APPROVED" && (
            <StatusComponent color="green">{status}</StatusComponent>
          )}

          {status === "REJECTED" && (
            <StatusComponent color="red">{status}</StatusComponent>
          )}

          {status === "WAITING" && (
            <StatusComponent color="yellow">{status}</StatusComponent>
          )}
        </div>
      ),
    },
    {
      title: "ACTIONS",
      align: "center",
      width: 100,
      render: (v, r, i) => {
        return (
          <div className="flex justify-center gap-4">
            <Tooltip title="Detail">
              <UnorderedListOutlined
                onClick={() => {
                  openDetail(r);
                }}
                style={{
                  color: "#0880AE",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <CloseCircleOutlined
                onClick={() => {
                  openDelete(r);
                }}
                style={{
                  color: "#FF7171",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
          </div>
        );
      },
      key: "action",
    },
  ];
  return (
    <LayoutMenu>
      <Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
        <BreadCrumb pageName={["User Management", "Delegation"]} />

        <RadioTabs
          defaultValue="Delegation Request"
          onChange={(e) => setReqOrApp(e.target.value)}
        >
          <Radio.Button value="Delegation Request">
            Delegation Request
          </Radio.Button>
          <Radio.Button value="Delegation Approval">
            Delegation Approval
          </Radio.Button>
        </RadioTabs>

        { reqOrApp === "Delegation Request" ? (
          <BaseContainer header={"VIEW DELEGATION REQUEST LIST"}>
          {" "}
          <div className={"w-full"}>
            <div className="flex w-full justify-end">
              <ButtonComponent
                icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
                type="submit"
              >
                Download List
              </ButtonComponent>
              <NavLink
                to={"/app/user-management/delegation/create"}
                state={{ x: 1 }}
              >
                <ButtonComponent
                  icon={<PlusOutlined style={{ fontSize: "24px" }} />}
                  type="submit"
                >
                  Create New
                </ButtonComponent>
              </NavLink>
            </div>
            <Table
              dataSource={delegationList}
              columns={columnsReq}
              size="small"
              pagination={{ position: ["bottomCenter"] }}
            />
          </div>
        </BaseContainer>
        ) : (
          <BaseContainer header={"VIEW DELEGATION APPROVAL LIST"}>
          {" "}
          <div className={"w-full"}>
            <div className="flex w-full justify-end">
              <ButtonComponent
                icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
                type="submit"
              >
                Download List
              </ButtonComponent>
              <NavLink
                to={"/app/user-management/delegation/create"}
                state={{ x: 1 }}
              >
                <ButtonComponent
                  icon={<PlusOutlined style={{ fontSize: "24px" }} />}
                  type="submit"
                >
                  Create New
                </ButtonComponent>
              </NavLink>
            </div>
            <Table
              dataSource={delegationList}
              columns={columnsApp}
              size="small"
              pagination={{ position: ["bottomCenter"] }}
            />
          </div>
        </BaseContainer>
        )}
        
      </Spin>

      {/* MODAL ACTIVE/INACTIVE */}
      <ModalCustom
        isOpen={modalInactive}
        handleCancel={() => setModalInactive(false)}
        header={"INACTIVE JOB"}
        width={800}
      >
        <div className="flex flex-col w-full">
          <h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
            Are you sure you want to inactive job?
          </h1>

          <div className="flex mt-4 w-full justify-center">
            <ButtonComponent
              // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
              type={"default"}
              onClick={() => setModalInactive(false)}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              // onClick={() => setModalConfirmation(true)}
              type={"submit"}
            >
              Submit
            </ButtonComponent>
          </div>
        </div>
      </ModalCustom>

      {/* MODAL DETAIL */}
      <ModalCustom
        isOpen={modalDetail}
        handleCancel={() => setModalDetail(false)}
        header={"DETAIL JOB"}
        width={1000}
      >
        <div className="flex flex-col w-full gap-y-10">
          <div className="grid grid-cols-2 gap-y-2.5">
            <DetailText label={"Job Name"}>{detailRecord.name}</DetailText>
            <DetailText label={"Created At"}>
              {moment(detailRecord.createdDate).format(dateFormat)}
            </DetailText>
            <DetailText label={"Description"}>
              {detailRecord.description}
            </DetailText>
            <DetailText label={"Created By"}>
              {detailRecord.createdBy}
            </DetailText>
            <div></div>
            <DetailText label={"Latest Update"}>
              {moment(detailRecord.updatedDate).format(dateFormat)}
            </DetailText>
            <div></div>
            <DetailText label={"Updated By"}>
              {detailRecord.updatedBy}
            </DetailText>
          </div>
        </div>

        <div className="flex justify-end mt-8">
          <ButtonComponent
            // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
            onClick={() => setModalDetail(false)}
            type="submit"
          >
            Close
          </ButtonComponent>
        </div>
      </ModalCustom>

      {/* MODAL DELETE */}
      <ModalCustom
        isOpen={modalDelete}
        handleCancel={() => setModalDelete(false)}
        header={"DELETE JOB"}
        width={800}
      >
        <div className="flex flex-col w-full">
          <h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
            Are you sure you want to delete job?
          </h1>

          <div className="flex mt-4 w-full justify-center">
            <ButtonComponent
              // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
              type={"default"}
              onClick={() => setModalDelete(false)}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              // onClick={() => setModalConfirmation(true)}
              type={"submit"}
            >
              Delete
            </ButtonComponent>
          </div>
        </div>
      </ModalCustom>
    </LayoutMenu>
  );
};

export default Delegation;
