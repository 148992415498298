import {
	CloseCircleOutlined,
	DownloadOutlined,
	PlusOutlined,
	UnorderedListOutlined,
} from "@ant-design/icons";
import { Spin, Switch, Table, Tooltip } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import ButtonComponent from "../../../../components/ButtonComponent";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import StatusComponent from "../../../../components/StatusComponent";
import SVGIcon from "../../../../assets/Icon/index";
import { PRODUCT_PROMO_ROUTES } from "../../../../routes/product_promo/pp_routes";

const Product = () => {
	const productList = [
		{
			productName: "Product RT 1",
			description: "Product Rumah Tangga 1",
			productType: "PRODUCT",
			serviceType: "GAS",
			productClass: "GASKITA",
			lockStatus: true,
			lockBy: "DILLA",
			status: "ACTIVE",
			dataAccess: "SCM",
		},
	];

	const columns = [
		{
			title: "PRODUCT NAME",
			dataIndex: "productName",
		},
		{
			title: "DESCRIPTION",
			dataIndex: "description",
		},
		{
			title: "PRODUCT TYPE",
			dataIndex: "productType",
		},
		{
			title: "SERVICE TYPE",
			dataIndex: "serviceType",
		},
		{
			title: "PRODUCT CLASS",
			dataIndex: "productClass",
		},
		{
			title: "LOCK STATUS",
			dataIndex: "lockStatus",
			render: (v) => (
				<>
					<Switch checked={v}></Switch> {v ? "LOCK" : "UNLOCK"}
				</>
			),
		},
		{
			title: "PRODUCT CLASS",
			dataIndex: "productClass",
		},
		{
			title: "STATUS",
			dataIndex: "status",
			width: 160,
			render: (status) => (
				<div>
					{status === "ACTIVE" && (
						<StatusComponent color="green">{status}</StatusComponent>
					)}

					{status === "INACTIVE" && (
						<StatusComponent color="red">{status}</StatusComponent>
					)}
				</div>
			),
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 100,
			render: () => {
				return (
					<div className="flex justify-center gap-4">
						<NavLink
							to={"/app/system-setup/entity/detail"}
							state={{ entityName: "Entity 1" }}
						>
							<Tooltip title="Detail">
								<UnorderedListOutlined
									style={{
										color: "#0880AE",
										fontSize: 24,
										justifyItems: "center",
									}}
								/>
							</Tooltip>
						</NavLink>
						<NavLink to={"/app/system-setup/entity/edit"}>
							<Tooltip title="Edit">
								<div>
									<SVGIcon
										name="IconEdit"
										width={24}
										style={{
											color: "#0880AE",
											fontSize: 24,
											justifyItems: "center",
										}}
									/>
								</div>
							</Tooltip>
						</NavLink>
						<Tooltip title="Delete">
							<CloseCircleOutlined
								onClick={() => {
									// setModalInactive(true);
								}}
								style={{
									color: "#FF7171",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb pageName={["Product & Promo", "Search Prouct"]} />
				<BaseContainer header={"PRODUCT LIST"}>
					<div className={"w-full"}>
						<div className="flex w-full justify-end">
							<ButtonComponent
								icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
								type="submit"
							>
								Download List
							</ButtonComponent>
							<NavLink
								to={PRODUCT_PROMO_ROUTES.CREATE_PRODUCT}
								state={{ x: 1 }}
							>
								<ButtonComponent
									icon={<PlusOutlined style={{ fontSize: "24px" }} />}
									type="submit"
								>
									Create Product
								</ButtonComponent>
							</NavLink>
						</div>
						<Table
							dataSource={productList}
							columns={columns}
							size="small"
							pagination={{ position: ["bottomCenter"] }}
						/>
					</div>
				</BaseContainer>
			</Spin>
		</LayoutMenu>
	);
};

export default Product;
