import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6187 9.38128C10.277 9.03957 9.72299 9.03957 9.38128 9.38128C9.03957 9.72299 9.03957 10.277 9.38128 10.6187L10.6187 9.38128ZM13.3813 14.6187C13.723 14.9604 14.277 14.9604 14.6187 14.6187C14.9604 14.277 14.9604 13.723 14.6187 13.3813L13.3813 14.6187ZM14.6187 10.6187C14.9604 10.277 14.9604 9.72299 14.6187 9.38128C14.277 9.03957 13.723 9.03957 13.3813 9.38128L14.6187 10.6187ZM9.38128 13.3813C9.03957 13.723 9.03957 14.277 9.38128 14.6187C9.72299 14.9604 10.277 14.9604 10.6187 14.6187L9.38128 13.3813ZM9.38128 10.6187L13.3813 14.6187L14.6187 13.3813L10.6187 9.38128L9.38128 10.6187ZM13.3813 9.38128L9.38128 13.3813L10.6187 14.6187L14.6187 10.6187L13.3813 9.38128Z"
      fill="#4B465C"
    />
  </svg>
);

export default SVG;
