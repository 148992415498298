import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import menusService from "../services/menus";
import { setMessage } from "./message";


const initialState = {
    datas:[],
    loading : false
}

export const getListMenus = createAsyncThunk(
    "GET_LIST_MENU",
    async (_, thunkAPI) => {
        try {
            const response = await menusService.getMenus()
            thunkAPI.dispatch(setMessage(response.data.message));
            // console.log(response.data.data, " GET_LIST_MENU");
            return response.data.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    
    });

const menusSlice = createSlice({
    name: 'menus',
    initialState,
    extraReducers: {
        [getListMenus.rejected]: (state, action) => {
            state.datas = action.payload;
            state.loading = false;
        },
        [getListMenus.pending]: (state, action) => {
            state.loading = true;
            // state.datas = action.payload;
        },
        [getListMenus.fulfilled]: (state, action) => {
            // console.log(action.payload, ' uhuy');
            state.datas = action.payload;
            state.loading = false;
        },
    }
})
const { reducer } = menusSlice;
export default reducer;