import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import employeeService from "../services/employee";

const initialState = {
	data: [],
	loading: false,
	message: null,
};

export const getAllEmployeePaginate = createAsyncThunk(
	"GET_ALL_EMPLOYEE_PAGINATE",
	async ({ page, pageSize }) => {
		try {
			const response = await employeeService.getAllEmployeePaginate(
				page,
				pageSize
			);
			console.log(pageSize, " = page slice");
			return response.data;
		} catch (error) {
			console.log(error, " = error slice");
			return error;
		}
	}
);

export const getAllEmployee = createAsyncThunk("GET_ALL_EMPLOYEE", async () => {
	try {
		const response = await employeeService.getlAllEmployee();
		return response.data;
	} catch (error) {
		console.log(error, ' =error slice get all employee');
		return error;
	}
});

const employeeSlice = createSlice({
	name: "employee",
	initialState,
	extraReducers: {
		//get all employee paginate reducer
		[getAllEmployeePaginate.pending]: (state, action) => {
			state.data = action.payload;
			state.loading = true;
		},
		[getAllEmployeePaginate.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[getAllEmployeePaginate.rejected]: (state, action) => {
			state.data = action.payload;
			state.loading = true;
		},
		// get all employee 
		[getAllEmployee.pending]: (state, action) => {
			state.data = action.payload;
			state.loading = true;
		},
		[getAllEmployee.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[getAllEmployee.rejected]: (state, action) => {
			state.data = action.payload;
			state.loading = true;
		},

	},
});

const { reducer } = employeeSlice;
export default reducer;
