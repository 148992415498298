import React, { useState, useEffect } from "react";
import { Form, Input, Card } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { bgLogin, pgnLogo } from "../../../assets/img";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/slices/auth";
import { clearMessage } from "../../../redux/slices/message";
import { ModalSuccess, ModalError } from "../../../components/Modal/ModalPopUp";
import { EyeInvisibleOutlined, EyeTwoTone, CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import ButtonComponent from "../../../components/ButtonComponent";

const NewPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openModalFalse, setOpenModalFalse] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleModal = (formValue) => {
    const { newPassword, confirmPassword } = formValue;
    console.log(newPassword, confirmPassword)
    if (newPassword === confirmPassword) {
      setOpenModal(true);
    } else setOpenModalFalse(true);
    // setLoading(true);
    // dispatch(login({ username, password, remember }))
    //   .unwrap()
    //   .then(() => {
    //     navigate("/app");
    //     window.location.reload();
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //     // successSend();
    //     // <ModalFailed/>
    //   });
  };

  const handleCancel = () => {
    setOpenModal(false);
    setOpenModalFalse(false);
  };

  return (
    <div className="w-screen h-screen grid grid-cols-12 dark:bg-dark-blue">
      <div
        className="col-span-12 sm:flex bg-no-repeat bg-cover w-full justify-center items-center"
        style={{
          backgroundImage: `url(${bgLogin})`,
        }}
      >
        <Card style={{ width: 539, borderRadius: "30px" }}>
          <div className={"w-full"}>
            {/* header card */}
            <div className={"flex flex-col gap-8 "}>
              <img
                className="mx-auto mt-7 h-12 w-auto"
                src={pgnLogo}
                alt="Your Company"
              />
            </div>

            {/* form */}
            <Link to="/" className=" dark:text-[#3C6DB2] cursor-pointer mb-7">
              <ArrowLeftOutlined
                style={{ fontSize: "24px", color: "#3C6DB2" }}
                className="ml-11 mb-8"
                onClick={<Navigate to="/app" />}
              ></ArrowLeftOutlined>
            </Link>

            <h2 className="text-base tracking-tight dark:text-[#3C6DB2] ml-11">
              Forgot Password
            </h2>

            <div
              className={
                "flex flex-col justify-center items-center w-full px-11 mt-8"
              }
            >
              <Form
                name="normal_login"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleModal}
                className={"w-full"}
              >
                <Form.Item
                  label={<span>Enter your new Password</span>}
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                  ]}
                  className={"mt-9"}
                >
                  <Input.Password
                    type="password"
                    size="large"
                    // prefix={<IconsLock />}
                    placeholder="New password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    className="bg-transparent mb-2 !text-base"
                    style={{ borderRadius: "9px" }}
                  />
                </Form.Item>

                <Form.Item
                  label={<span>Confirm your new Password</span>}
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your confirmation new password!",
                    },
                  ]}
                  className={"mt-9"}
                >
                  <Input.Password
                    type="password"
                    size="large"
                    // prefix={<IconsLock />}
                    placeholder="Confirmation password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    className="bg-transparent mb-2 !text-base"
                    style={{ borderRadius: "9px" }}
                  />
                </Form.Item>
                <div
                  className={
                    "w-full flex flex-col items-center justify-center mt-5"
                  }
                >
                  <Form.Item className={"w-full"}>
                    {/* <Link to={"/forgot-password/new-password"}> */}
                      <button
                        type="submit"
                        // onClick={successSend}
                        className={
                          "bg-[#3C6DB2] hover:bg-[#3663a2] text-white text-sm px-4 py-4 border rounded-lg w-full border-none cursor-pointer"
                        }
                      >
                        <span>Set new password</span>
                      </button>
                    {/* </Link> */}
                  </Form.Item>
                </div>
              </Form>
            </div>
            {/* footer card*/}
            <span className="dark:text-[#0880AE] text-[10px] flex justify-center mb-8">
              Copyrights © 2022 Astra Graphia Information Technology. All rights
              reserved
            </span>
          </div>
        </Card>
      </div>

      {openModal && (
        <ModalSuccess
          isOpen={openModal}
          handleCancel={handleCancel}
          isAlert={true}
        >
          <div className={"flex px-8 py-8"}>
            <CheckCircleFilled
              style={{ fontSize: "24px", color: "#09A92A" }}
              className="my-2"
            />
            <div className="w-full flex-col my-2">
              <div className="pl-4">
                <span className="text-xl font-bold  text-[#09A92A]">
                  {" "}
                  Well done!
                </span>
              </div>
              <div className="pl-4 pt-4">
                <span className={"text-l"}>
                  {" "}
                  You succesfully changed the password.
                </span>
              </div>
            </div>
          </div>
          <div className={"w-full justify-end flex pb-2 pr-2"}>
            <Link to={"/"}>
              <ButtonComponent
                type={"submit"}
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Back
              </ButtonComponent>
            </Link>
          </div>
        </ModalSuccess>
      )}

{openModalFalse && (
         <ModalError
         isOpen={openModalFalse}
         handleCancel={handleCancel}
         isAlert={true}
       >
         <div className={"flex px-8 py-8"}>
           <ExclamationCircleFilled style={{ fontSize: "24px", color: "#C81912" }} className="my-2" />
           <div className="w-full flex-col my-2">
             <div className="pl-4">
               <span className="text-xl font-bold  text-[#C81912]">
                 {" "}
                 Oops, change password failed...
               </span>
             </div>
             <div className="pl-4 pt-4">
               <span className={"text-l"}>
                 {" "}
                 New password and confirmation password didn't match!
               </span>
             </div>
           </div>

         </div>
         <div className={"w-full justify-end flex pb-2 pr-2"}>
           <ButtonComponent
             type={"submit"}
             onClick={() => setOpenModalFalse(false)}
           >
             Back
           </ButtonComponent>
         </div>
       </ModalError>
      )}
    </div>
  );
};

export default NewPassword;
