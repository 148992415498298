import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/BreadCrumb";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { useSelector, useDispatch } from "react-redux";
import BaseContainer from "../../../../components/BaseContainer";
import { Spin, Table, Checkbox, Tooltip } from "antd";
import ButtonComponent from "../../../../components/ButtonComponent";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import InputComponent from "../../../../components/InputComponent";
import GridLayout from "../../../../components/GridLayout";
import {
	DownloadOutlined,
	DeleteOutlined,
	PlusOutlined,
	ArrowLeftOutlined,
	UnorderedListOutlined,
	EditOutlined,
	CloseCircleOutlined,
} from "@ant-design/icons";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import DetailText from "../../../../components/DetailText";
import StatusComponent from "../../../../components/StatusComponent";
const EmployeeDetail = () => {
	const [openDetailAH, setOpenDetailAH] = useState(false);
	const [dr, setDr] = useState(false);

	const navigate = useNavigate();
	const x = useLocation().state;
	const detailAH = (r) => {
		setDr(r);
		setOpenDetailAH(true);
	};
	const assignmentList = [
		{
			status: "Main",
			startDate: "12-Jan-2022 23:11:09",
			endDate: "12-Jan-2022 23:11:09",
			createdAt: "12-Jan-2022 23:11:09",
			createdBy: "Annisa",
			latestUpdate: "12-Jan-2022 23:11:09",
			updatedBy: "Annisa",
			job: "Sr. Staff",
			position: "CM Bogor",
		},
	];
	const columns = [
		{
			title: "JOB",
			dataIndex: "job",
			width: 160,
		},
		{
			title: "POSITION",
			dataIndex: "position",
			width: 160,
		},
		{
			title: "STATUS",
			dataIndex: "status",
			width: 160,
		},
		{
			title: "START DATE",
			dataIndex: "startDate",
			width: 160,
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
			width: 160,
		},

		{
			title: "ACTIONS",
			align: "center",
			width: 160,
			render: (v, r, i) => {
				return (
					<div className="flex justify-center gap-4">
						<Tooltip title="Detail">
							<UnorderedListOutlined
								onClick={() => detailAH(r)}
								style={{
									color: "#0880AE",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb
					pageName={["User management", "Employee", "Detail Employee"]}
				/>
				<NavLink onClick={() => navigate(-1)}>
					<div className="flex align-middle gap-x-2 mb-4">
						<ArrowLeftOutlined
							style={{ fontSize: "24px", color: "#3C6DB2" }}
						></ArrowLeftOutlined>
						<span className="text-dg-blue">Back</span>
					</div>
				</NavLink>
				<BaseContainer header={"DETAIL EMPLOYEE"}>
					<div className="flex flex-col w-full gap-y-10">
						<div className="grid grid-cols-3 gap-y-2.5">
							<DetailText label={"Employee Number"}>
								{x?.employeeNumber}
							</DetailText>
							<DetailText label={"Email"}>Johndoe@pgn.com</DetailText>
							<DetailText label={"Created At"}>21-Des-2021 23:11:09</DetailText>
							<DetailText label={"First Name"}>John</DetailText>
							<DetailText label={"Mobile Phone"}>081290909090</DetailText>
							<DetailText label={"Created By"}>Annisa</DetailText>
							<DetailText label={"Last Name"}>Doe</DetailText>
							<DetailText label={"Start Date"}>21-Des-2021 23:11:09</DetailText>
							<DetailText label={"Latest Update"}>
								21-Des-2021 23:11:09
							</DetailText>
							<DetailText label={"Employee Type"}>Permanent</DetailText>
							<DetailText label={"End Date"}>21-Des-2021 23:11:09</DetailText>
							<DetailText label={"Updated By"}>Annisa</DetailText>
							<DetailText label={"Description"}>-</DetailText>
						</div>
						<div className="grid grid-cols-1 gap-y-2">
							<p className="text-dg-blue text-sm font-bold">
								Assignment History
							</p>
							<Table
								dataSource={assignmentList}
								columns={columns}
								size="small"
								pagination={{ position: ["bottomCenter"] }}
							/>
						</div>
					</div>
				</BaseContainer>
			</Spin>

			{dr && (
				<ModalCustom
					isOpen={openDetailAH}
					handleCancel={() => setOpenDetailAH(false)}
					header={"DETAIL ASSIGNMENT"}
				>
					<div className="grid grid-cols-1 gap-y-2.5">
						<DetailText label={"Job"}>{dr.job}</DetailText>
						<DetailText label={"Position"}>{dr.position}</DetailText>
						<DetailText label={"Status"}>{dr.status}</DetailText>
						<DetailText label={"Start Date"}>{dr.startDate}</DetailText>
						<DetailText label={"End Date"}>{dr.endDate}</DetailText>
						<DetailText label={"Created At"}>{dr.createdAt}</DetailText>
						<DetailText label={"Created By"}>{dr.createdBy}</DetailText>
						<DetailText label={"Latest Update"}>{dr.latestUpdate}</DetailText>
						<DetailText label={"Updated By"}>{dr.updatedBy}</DetailText>
					</div>

					<div className="flex justify-end">
						<ButtonComponent
							// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
							onClick={() => setOpenDetailAH(false)}
							type="submit"
						>
							Close
						</ButtonComponent>
					</div>
				</ModalCustom>
			)}
		</LayoutMenu>
	);
};

export default EmployeeDetail;
