import React, { useState, useRef } from "react";
import { Spin, Checkbox } from "antd";
import { NavLink } from "react-router-dom";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import ButtonComponent from "../../../../components/ButtonComponent";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import {
  DownloadOutlined,
  PlusOutlined,
  FilterOutlined,
  EditOutlined,
  CloseCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Input, Space, Table, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import SVGIcon from "../../../../assets/Icon/index";
import StatusComponent from "../../../../components/StatusComponent";
import ModalCustom from "../../../../components/Modal/ModalCustom";

const ViewGlobalType = () => {
  const searchInput = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalInactive, setModalInactive] = useState(false);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const dataSource = [
    {
      key: "1",
      groupName: "AUTH TYPE",
      description: "Annisa Lestari",
      sortBy: "Annisa.Lestari",
      status: "ACTIVE",
    },
    {
      key: "2",
      groupName: "USER LEVEL",
      description: "Putri Worabay",
      sortBy: "Putri.Worabay",
      status: "ACTIVE",
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      align: "center",
      key: "name",
    },
    {
      title: "GROUP NAME",
      dataIndex: "groupName",
      align: "center",
      key: "groupName",
      ...getColumnSearchProps("groupName"),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      align: "center",
      key: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: "SORT BY",
      dataIndex: "sortBy",
      align: "center",
      key: "sortBy",
      ...getColumnSearchProps("sortBy"),
    },
    {
      title: "ACTIVATE",
      dataIndex: "address",
      align: "center",
      key: "address",
      ...getColumnSearchProps("address"),
      render: () => {
        return (
          <div className="flex justify-center">
            <Checkbox
            // onChange={onChange}
            ></Checkbox>
          </div>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (status) => (
        <div>
          {status === "ACTIVE" && (
            <StatusComponent color="green">{status}</StatusComponent>
          )}

          {status === "INACTIVE" && (
            <StatusComponent color="red">{status}</StatusComponent>
          )}
        </div>
      ),
    },
    {
      title: "ACTIONS",
      dataIndex: "address",
      render: () => {
        return (
          <div className="flex justify-center gap-4">
            <NavLink to={"/app/system-setup/global-type/detail"}>
              <Tooltip title="Detail">
                <UnorderedListOutlined
                  style={{
                    color: "#0880AE",
                    fontSize: 24,
                    justifyItems: "center",
                  }}
                />
              </Tooltip>
            </NavLink>
            <NavLink to={"/app/system-setup/global-type/edit"}>
              <Tooltip title="Edit">
                <div>
                  <SVGIcon
                    name="IconEdit"
                    width={24}
                    style={{
                      color: "#0880AE",
                      fontSize: 24,
                      justifyItems: "center",
                    }}
                  />
                </div>
              </Tooltip>
            </NavLink>
            <Tooltip title="Delete">
              <CloseCircleOutlined
                onClick={() => {
                  // info(id);
                  setModalInactive(true);
                  // console.log(id);
                }}
                style={{
                  color: "#FF7171",
                  fontSize: 24,
                  justifyItems: "center",
                }}
              />
            </Tooltip>
          </div>
        );
      },
      key: "action",
    },
  ];

  return (
    <LayoutMenu>
      <Spin spinning={false} className="w-full top-20" tip="Loading">
        <BreadCrumb pageName={["System Setup ", "Global Type"]} />

        <BaseContainer header={"GLOBAL TYPE LIST"}>
          <div className="flex flex-col w-full">
            <div className="flex w-full justify-end">
              <ButtonComponent
                icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
                type={"submit"}
              >
                Download List
              </ButtonComponent>

              <NavLink to={"/app/system-setup/global-type/create"}>
                <ButtonComponent
                  icon={<PlusOutlined style={{ fontSize: "24px" }} />}
                  type={"submit"}
                >
                  Create New
                </ButtonComponent>
              </NavLink>
            </div>

            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{
                position: ["bottomCenter"],
              }}
              size="small"
              total={50}
              showSizeChanger
            />
          </div>
        </BaseContainer>

        <ModalCustom
          isOpen={modalInactive}
          handleCancel={() => setModalInactive(false)}
          header={"INACTIVE GLOBAL TYPE"}
          width={800}
        >
          <div className="flex flex-col w-full">
            <h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
              Are you sure you want to inactive global type
            </h1>

            <div className="flex mt-4 w-full justify-center">
              <ButtonComponent
                // icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
                type={"default"}
                onClick={() => setModalInactive(false)}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                // onClick={() => setModalConfirmation(true)}
                type={"submit"}
              >
                Submit
              </ButtonComponent>
            </div>
          </div>
        </ModalCustom>
      </Spin>
    </LayoutMenu>
  );
};

export default ViewGlobalType;
