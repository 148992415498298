import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import authService from "../services/auth";


const initialState = {
  isLoggedIn: JSON.parse(localStorage.getItem("token")) || JSON.parse(window.sessionStorage.getItem("token")),
  user: {},
  positions: {},
  loading : false
}

export const register = createAsyncThunk(
  "auth/register",
  async ({ username, email, password }, thunkAPI) => {
    try {
      const response = await authService.register(username, email, password);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password, remember }, thunkAPI) => {
    try {
      const data = await authService.login(username, password, remember);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getListPosition = createAsyncThunk(
  "auth/list-position",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await authService.getListPosition(username, password);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});


const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.loading = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.loading = true;

    },
    [register.pending]: (state, action) => {
      state.isLoggedIn = false;
      state.loading = true;

    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = action.payload.status;
      state.user = action.payload;
      state.loading = false;

    },
    [login.pending]: (state, action) => {
      state.isLoggedIn = false;
      state.loading = true;

    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [getListPosition.fulfilled]: (state, action) => {
      // state.isLoggedIn = action.payload.status;
      state.positions = action.payload;
      state.loading = false;

    },
    [getListPosition.pending]: (state, action) => {
      state.positions = null;
      state.loading = true;
    },
    [getListPosition.rejected]: (state, action) => {
      state.loading = false;
      state.positions = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
