import React from "react";
import { Input, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import InputLabel from "./InputLabel";
import { NumberFormatBase, NumericFormat } from "react-number-format";

const InputComponent = (props) => {
	const {
		label,
		mandatory,
		onChange = () => {},
		type,
		value,
		placeholder,
		suffix,
		prefix,
		group,
	} = props;
	const style = {
		width: "100%",
		borderRadius: "5px",
		boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
		border: "1px solid #DFE5EC",
		padding: "4px 12px",
	};

	const wrapper = "flex flex-col w-full";

	return (
		<div className={wrapper}>
			{!group && <InputLabel text={label} mandatory={mandatory}></InputLabel>}
			{type === "textarea" ? (
				<>
					<TextArea
						rows={5}
						style={style}
						onChange={onChange}
						value={value}
						maxLength={255}
						placeholder={placeholder}
					/>
					<span className="text-xs mt-1 text-[#92979D]">
						You have {value ? value.length : 0} of 255 characters remaining
					</span>
				</>
			) : type === "numeric" ? (
				<NumericFormat
					value={value}
					// decimalScale={2}
					// fixedDecimalScale={true}
					prefix={prefix}
					suffix={suffix}
					thousandsGroupStyle={"thousand"}
					thousandSeparator={"."}
					decimalSeparator={","}
					className="text-right"
					style={style}
					onValueChange={onChange}
					placeholder={placeholder}
				></NumericFormat>
			) : type === "number" ? (
				<NumericFormat
					value={value}
					// decimalScale={2}
					prefix={prefix}
					suffix={suffix}
					className="text-right"
					// fixedDecimalScale={true}
					style={style}
					onChange={onChange}
					placeholder={placeholder}
				></NumericFormat>
			) : (
				<Input
					style={style}
					onChange={onChange}
					value={value}
					placeholder={placeholder}
					prefix={prefix}
					suffix={suffix}
				/>
			)}
		</div>
	);
};

export default InputComponent;
