import React, { useCallback, useState } from 'react';
import Tree from 'react-d3-tree';

const HierarchyComponent = (props) => {
    const { data, zoomable, collapse, nodeWidth, nodeHeight, containerWidth, containerHeight, renderCustomNode = () => {}  } = props;

    const [translate, setTranslate] = useState({ x: 0, y: 0 });

    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 2, y: height / 10 });
        }
    }, []);

    // to setting size node box
    const foreignObjectProps = {
        width: nodeWidth,
        height: nodeHeight,
        x: -70,
        y: 8,
    };

    // to setting container canvas
    const container = {
        width: containerWidth,
        height: containerHeight
    }

    return (
        <div style={container} ref={containerRef}>
            <Tree
                data={data}
                collapsible={collapse}
                translate={translate}
                pathFunc="elbow"
                orientation="vertical"
                renderCustomNodeElement={(rd3tProps) =>
                    renderCustomNode({ ...rd3tProps, foreignObjectProps })
                }
                zoomable={zoomable}
            />
        </div>
    );
}

export default HierarchyComponent;
