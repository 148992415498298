import React, { useState } from "react";
import LayoutMenu from "../../../../../components/SidebarMenu/LayoutMenu";
import BreadCrumb from "../../../../../components/BreadCrumb";
import { Select, Spin, Tag, Popover } from "antd";
import BaseContainer from "../../../../../components/BaseContainer";
import { NavLink, useNavigate } from "react-router-dom";
import {
	ArrowLeftOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
} from "@ant-design/icons";
import ButtonComponent from "../../../../../components/ButtonComponent";
import InputComponent from "../../../../../components/InputComponent";
import SelectComponent from "../../../../../components/SelectComponent";
import ModalCustom from "../../../../../components/Modal/ModalCustom";
import DetailText from "../../../../../components/DetailText";
import { ModalSuccess } from "../../../../../components/Modal/ModalPopUp";

const OPTIONS = ["Apples", "Nails", "Bananas", "Helicopters"];

const CreateHierarchy = () => {
	const navigate = useNavigate();

	const [modalConfirmation, setModalConfirmation] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const [ccName, setCCName] = useState("");
	const [code, setCode] = useState("");
	const [parent, setParent] = useState(0);
	const [area, setArea] = useState(0);
	const [siblings, setSiblings] = useState([]);

	const filteredOptions = OPTIONS.filter((o) => !siblings.includes(o));

	const tagRender = (props) => {
		const { label, closable, onClose } = props;
		const onPreventMouseDown = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color="#CEDBEC"
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{
					marginRight: 3,
				}}
				className={"select-multiple"}
			>
				{label}
			</Tag>
		);
	};

	const content = (
		<span className="text-[11px] font-bold  text-[#3C6DB2]">
			Please fill mandatory form
		</span>
	);

	const clearForm = () => {
		setCCName("");
		setCode("");
		setParent(0);
		setArea(0);
		setSiblings([]);
	};

	const submitForm = () => {
		const body = {
			ccName: ccName,
			code: code,
			parent: parent,
			area: area,
			siblings: siblings,
		};
		console.log(body);
		setModalConfirmation(false);
		// setOpenModal(true);
	};

	return (
		<LayoutMenu>
			<Spin spinning={false} className="w-full top-20" tip="Loading">
				<BreadCrumb
					pageName={[
						"System Setup ",
						"Master Data",
						"Cost Center",
						"Create Cost Center",
					]}
				/>

				<NavLink onClick={() => navigate(-1)}>
					<div className="flex align-middle gap-x-2 mb-4">
						<ArrowLeftOutlined
							style={{ fontSize: "24px", color: "#3C6DB2" }}
						></ArrowLeftOutlined>
						<span className="text-[#3C6DB2]">Back</span>
					</div>
				</NavLink>

				<BaseContainer header={"CREATE COST CENTER"}>
					<div className="flex flex-col w-full">
						<div className="flex flex-col w-full gap-8">
							<InputComponent
								type="text"
								label={"Cost Center Name"}
								mandatory
								onChange={(e) => setCCName(e.target.value)}
								value={ccName}
							></InputComponent>
							<InputComponent
								type="text"
								label={"Code"}
								mandatory
								onChange={(e) => setCode(e.target.value)}
								value={code}
							></InputComponent>
							<SelectComponent
								type="number"
								label={"Parent"}
								mandatory
								// options={{label:}}
							></SelectComponent>
							<SelectComponent type="number" label={"Area"}></SelectComponent>
							<SelectComponent
								type="text"
								label={"Add Siblings"}
								mode="multiple"
								value={siblings}
								onChange={setSiblings}
								// tagRender={tagRender}
							>
								{filteredOptions.map((e) => (
									<Select.Option value={e}>{e}</Select.Option>
								))}
							</SelectComponent>
						</div>

						<div className="flex mt-4 w-full justify-end">
							<ButtonComponent
								icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
								type={"default"}
								onClick={() => {
									clearForm();
								}}
							>
								Clear
							</ButtonComponent>
							{ccName && code && parent ? (
								<ButtonComponent
									onClick={() => setModalConfirmation(true)}
									type={"submit"}
								>
									Save
								</ButtonComponent>
							) : (
								<Popover placement="top" content={content} trigger="hover">
									<div>
										<ButtonComponent
											onClick={() => setModalConfirmation(true)}
											type={"submit"}
											disabled
										>
											Save
										</ButtonComponent>
									</div>
								</Popover>
							)}
						</div>
					</div>
				</BaseContainer>

				<ModalCustom
					isOpen={modalConfirmation}
					handleCancel={() => setModalConfirmation(false)}
					header={"CONFIRMATION"}
					width={1200}
				>
					<div className="flex flex-col w-full gap-y-10">
						<div className="grid grid-cols-3 gap-y-2.5">
							<DetailText label={"Cost Center Name"}>{ccName}</DetailText>
							<DetailText label={"Code"}>{code}</DetailText>
							<DetailText label={"Parent"}>{parent}</DetailText>
							<DetailText label={"Area"}>{area}</DetailText>
							<DetailText label={"Siblings"}>
								{siblings.map((a) => (
									<ul className="p-0">{a}</ul>
								))}
							</DetailText>
						</div>

						<div className="flex mt-4 w-full justify-end">
							<ButtonComponent
								// icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
								type={"default"}
								onClick={() => setModalConfirmation(false)}
							>
								Cancel
							</ButtonComponent>
							<ButtonComponent onClick={() => submitForm()} type={"submit"}>
								Submit
							</ButtonComponent>
						</div>
					</div>
				</ModalCustom>

				{openModal && (
					<ModalSuccess
						isOpen={openModal}
						// handleOk={setOpenModal(true)}
						handleCancel={setOpenModal(false)}
						centered
						isAlert={true}
					>
						<div className={"flex px-8 py-8"}>
							<ExclamationCircleFilled
								style={{ fontSize: "24px", color: "#C81912" }}
								className="my-2"
							/>
							<div className="w-full flex-col my-2">
								<div className="pl-4">
									<span className="text-xl font-bold  text-[#C81912]">
										{" "}
										Oops, login failed...
									</span>
								</div>
								<div className="pl-4 pt-4">
									<span className={"text-l"}>
										{" "}
										Change a few things up and try login again.
									</span>
								</div>
							</div>
						</div>
						<div className={"w-full justify-end flex pb-2 pr-2"}>
							<ButtonComponent
								type={"submit"}
								onClick={() => setOpenModal(false)}
							>
								Back
							</ButtonComponent>
						</div>
					</ModalSuccess>
				)}
			</Spin>
		</LayoutMenu>
	);
};

export default CreateHierarchy;
