import React, { useCallback, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';
import { ArrowLeftOutlined } from "@ant-design/icons";
import DetailText from '../../../../components/DetailText';
import Tree from "react-d3-tree";
import ModalCustom from '../../../../components/Modal/ModalCustom';
import ButtonComponent from '../../../../components/ButtonComponent';
import { Table } from 'antd';



const DetailPositionHierarchy = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => { };
    const handleCancel = () => {
        setOpenModal(false)
    }
    const nodeSize = { x: 150, y: 400 };
    const foreignObjectProps = {
        width: nodeSize.x,
        height: nodeSize.y,
        x: -70,
        y: 8,
    };
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 2, y: height / 30 });
        }
    }, []);
    const detailText = [
        {
            label: 'Hierarchy Name',
            text : 'Hierarchy 2023'
        },
        {
            label: 'Status',
            text : 'Draft'
        },
        {
            label: 'Remark',
            text : 'Versi Awal'
        },
        {
            label: 'Start Date',
            text : '1 Januari 2022'
        },
        {
            label: 'End Date',
            text : '1 Januari 2024'
        },
        {
            label: 'Created At',
            text : '20 Desember 2021'
        },
        {
            label: 'Latest Date',
            text : '22 Desember 2021'
        },
        {
            label: 'Updated By',
            text : 'Annisa'
        },
        {
            label: 'Created By',
            text : 'Putri'
        },
    ]
    const datas = [
        {
            name: "Muhammad",
            department: "CEO",
            children: [
                {
                    name: "Budi",
                    department: "Production",
                    children: [
                        {
                            name: "John",
                            department: "Fabrication",
                            children: [
                                {
                                    name: "Binar",
                                    department: "Fabrication",
                                },
                            ],
                        },
                        {
                            name: "Nina",
                            department: "Assembly",
                            children: [
                                {
                                    name: "Johan",
                                    department: "Assembly",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "Ibas",
                    department: "Marketing",
                    children: [
                        {
                            name: "Surya",
                            department: "Marketing",
                            children: [
                                {
                                    name: "Hadi",
                                    department: "Marketing",
                                },
                            ],
                        },
                        {
                            name: "Gayatri",
                            department: "Marketing",
                            children: [
                                {
                                    name: "RIma",
                                    department: "Marketing",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
    const containerStyles = {
        width: "100%",
        height: "80vh",
    };

    const renderForeignObjectNode = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps,
        
    }) => (
        <g onClick={() => setOpenModal(true)}>
            <circle r={5} className={"bg-black"}></circle>
            <foreignObject {...foreignObjectProps}>
                <div className="flex bg-[#CEDBEC] justify-center rounded-lg m-2">
                    <div className="flex-col items-center my-5">
                        <div className={"flex gap-3 justify-center mx-auto w-full"}>
                            {/* <Avatar icon={<UserOutlined />} /> */}
                            <div className="flex-col items-center justify-center">
                                <p className="text-black leading-none text-[12px] text-center">
                                    {nodeDatum.name}
                                </p>
                                <p className="text-gray-400 text-[12px] text-center">
                                    {nodeDatum.department}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </foreignObject>
        </g>
    );
    const data = [
        {
            employee_name :'',
            status :'',
            period :'',
            created_at :'',
            created_by :'',
            latest_update :'',
            update_by :'',
        }
    ]
    const columns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
            align: 'center'
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: 'center'
        },
        {
            title: "Period",
            dataIndex: "period",
            key: "period",
            align: 'center'
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            align: 'center'
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by",
            align: 'center'
        },
        {
            title: "Latest Update",
            dataIndex: "latest_update",
            key: "latest_update",
            align: 'center'
        },
        {
            title: "Update By",
            dataIndex: "update_by",
            key: "update_by",
            align: 'center'
        },
    ]
    return (
        <LayoutMenu>
            <BreadCrumb pageName={['User Management', 'Detail Position Hierarchy']} />
            <NavLink onClick={() => navigate(-1)}>
                <div className="flex align-middle gap-x-2 mb-4">
                    <ArrowLeftOutlined
                        style={{ fontSize: "24px", color: "#3C6DB2" }}
                    ></ArrowLeftOutlined>
                    <span className="text-dg-blue">Back</span>
                </div>
            </NavLink>
            <BaseContainer header={"POSITION HIERARCHY"}>
                <div className={'w-full flex flex-col max-h-screen'}>
                    <div className={' h-1/6 w-full flex flex-col gap-10 flex-wrap mx-5'}>
                        {detailText.map((index, key) => {
                            return (
                                <DetailText label={index.label}>{index.text }</DetailText>
                            )
                        }) }
                    </div>
                    <div>
                        <div style={containerStyles} ref={containerRef}>
                            <Tree
                                data={datas}
                                collapsible={false}
                                translate={translate}
                                pathFunc="elbow"
                                orientation="vertical"
                                renderCustomNodeElement={(rd3tProps) =>
                                    renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                                }
                                zoomable={true}
                            />
                        </div>
                    </div>
                </div>
            </BaseContainer>
            <ModalCustom isOpen={openModal} handleCancel={handleCancel} header={"ASSIGNMENT HISTORY"} width={980}>
                <div className={'w-full flex flex-col'}>
                    <div className={'w-full flex'}>
                        <div className={'w-full flex flex-col gap-3'}>
                            <span className={'text-blue-400 text-xl'}>CM Bogor </span>
                            <Table columns={columns}/>
                        </div>
                    </div>
                    <div className={'flex w-full justify-end mt-12'}>
                        <ButtonComponent type={'submit'} onClick={handleCancel}>Close</ButtonComponent>
                    </div>
                </div>
            </ModalCustom>
        </LayoutMenu>
    );
}

export default DetailPositionHierarchy;
