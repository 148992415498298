import { ArrowLeftOutlined } from "@ant-design/icons";
import { Spin, Table } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import DetailText from "../../../../components/DetailText";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import StatusComponent from "../../../../components/StatusComponent";

const GlobalPropertiesDetail = () => {
	const navigate = useNavigate();
	const piList = [
		{
			propertiesKey: "Host",
			propertiesValue: "144.43.4934.844",
			createdAt: "12-Jan-2022 23:11:09",
			createdBy: "Annisa",
			latestUpdate: "12-Jan-2022 23:11:09",
			updatedBy: "Annisa",
		},
		{
			propertiesKey: "Password",
			propertiesValue: "ENCRYPT",
			createdAt: "12-Jan-2022 23:11:09",
			createdBy: "Putri",
			latestUpdate: "12-Jan-2022 23:11:09",
			updatedBy: "Putri",
		},
	];
	const columns = [
		{
			title: "Key",
			dataIndex: "propertiesKey",
			width: 100,
		},
		{
			title: "Value",
			dataIndex: "propertiesValue",
			render: (v) =>
				v === "ENCRYPT" ? (
					<StatusComponent color="green">ENCRYPTED</StatusComponent>
				) : (
					v
				),
			width: 160,
		},
		{
			title: "Created At",
			dataIndex: "createdAt",
			width: 160,
		},
		{
			title: "Created By",
			dataIndex: "createdBy",
			width: 100,
		},
		{
			title: "Latest Update",
			dataIndex: "latestUpdate",
			width: 100,
		},
		{
			title: "Updated By",
			dataIndex: "updatedBy",
			width: 100,
		},
	];
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb
					pageName={["System Setup", "Global Properties", "Detail"]}
				/>
				<NavLink onClick={() => navigate(-1)}>
					<div className="flex align-middle gap-x-2 mb-4">
						<ArrowLeftOutlined
							style={{ fontSize: "24px", color: "#3C6DB2" }}
						></ArrowLeftOutlined>
						<span className="text-dg-blue">Back</span>
					</div>
				</NavLink>
				<BaseContainer header={"GLOBAL PROPERTIES DETAIL"}>
					<div className="flex flex-col w-full gap-y-10">
						<div className="grid grid-cols-2 gap-x-10 gap-y-2.5">
							<DetailText label={"Type"}>{"INTEGRATION"}</DetailText>
							<DetailText label={"Created By"}>
								{"johndoe@pgn.co.id"}
							</DetailText>
							<DetailText label={"Properties Name"}>
								{"URL E-Faktur"}
							</DetailText>
							<DetailText label={"Created Date"}>
								{"12-Jan-2022 23:11:09"}
							</DetailText>
							<DetailText label={"Description"}>
								{"Config API E-Faktur"}
							</DetailText>
							<DetailText label={"Last Updated"}>
								{"12-Jan-2022 23:11:09"}
							</DetailText>
							<DetailText label={"Status"}>{"Active"}</DetailText>
							<DetailText label={"Updated By"}>
								{"johndoe@pgn.co.id"}
							</DetailText>
						</div>
						<div className="flex flex-col">
							<p className="text-dg-blue text-sm font-bold">Properties Item</p>
							<Table
								dataSource={piList}
								columns={columns}
								size="small"
								pagination={{ position: ["bottomCenter"] }}
							/>
						</div>
					</div>
				</BaseContainer>
			</Spin>
		</LayoutMenu>
	);
};

export default GlobalPropertiesDetail;
