import React from 'react';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';

const ApprovalHierarchyForm = (props) => {
    const { type } = props;
    return (
        <LayoutMenu>
            <BreadCrumb pageName={['System Setup', `${type === 'update' ? ' Update' : 'Create'} Hierarchy`]} />
            <BaseContainer>
                <span>
                    Hello ges!
                </span>
            </BaseContainer>
       </LayoutMenu>
    );
}

export default ApprovalHierarchyForm;
