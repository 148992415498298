import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.90999 20.5617L5.01998 16.3475C4.29998 15.5675 4.31998 14.2892 5.05998 13.4983L12.45 5.49252C12.86 5.04835 13.42 4.83169 13.98 4.88585L17.53 5.25418C18.39 5.34085 19.07 6.07751 19.15 7.00918L19.49 10.855C19.54 11.4617 19.34 12.0683 18.93 12.5125L11.54 20.5183C10.81 21.3092 9.62999 21.3309 8.90999 20.5617Z"
      stroke="#4B465C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7352 8.83581L15.7351 8.83586L15.7434 8.8446C15.7617 8.86399 15.784 8.90477 15.7838 8.96717C15.7835 9.02886 15.7611 9.07705 15.7332 9.10652L15.7208 9.1196L15.7126 9.12899C15.7119 9.12909 15.7112 9.12916 15.7107 9.12919C15.7102 9.12888 15.7095 9.12845 15.7087 9.12784C15.7063 9.12617 15.7015 9.12239 15.6948 9.1151C15.6667 9.08466 15.645 9.03659 15.645 8.97546C15.645 8.91432 15.6667 8.86625 15.6948 8.83581C15.7039 8.8259 15.7109 8.8207 15.715 8.81814C15.7191 8.8207 15.726 8.8259 15.7352 8.83581Z"
      fill="#4B465C"
      stroke="#4B465C"
      stroke-width="2"
    />
  </svg>
);

export default SVG;
