import React from "react";

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  onClick = () => {},
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    className={`cursor-pointer${className}`}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8 12.46C13.1642 12.46 14.27 11.3542 14.27 9.99002C14.27 8.62588 13.1642 7.52002 11.8 7.52002C10.4359 7.52002 9.33002 8.62588 9.33002 9.99002C9.33002 11.3542 10.4359 12.46 11.8 12.46Z"
      stroke="#4B465C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.82001 19.29V17.88C6.82001 16.24 8.15 14.92 9.78 14.92H14.21C15.85 14.92 17.17 16.25 17.17 17.88V19.29"
      stroke="#4B465C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
      stroke="#4B465C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SVG;
