import { Image, Layout, Avatar, Menu, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import SideMenu from "./Sidemenu";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { pgnLogo, pgnLogoKecil } from "../../assets/img/index";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import { clearMessage } from "../../redux/slices/message";
import IconArrowNarrowLeft from "../../assets/Icon/IconArrowNarrowLeft";
import ModalAlert from "../Modal/ModalCustom";
import IdleTimerContainer from "../../utils/idleTimer";
import ModalCustom from "../Modal/ModalCustom";
import ButtonComponent from "../ButtonComponent";

const { Content, Sider, Header } = Layout;

const LayoutMenu = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [isTimedout, setIsTimedout] = useState(false);
  // console.log(isLoggedIn, " s");
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  // handle logout
  const handleLogoutModal = () => {
    setOpenModal(true);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setIsTimedout(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      window.location.reload();
      return navigate("/");
    } catch (error) {
      return console.log("uhuy");
    }
  };

  const menu = (
    <Menu
      items={[
        {
          label: <a href="/profile">Profile</a>,
          key: "0",
        },
        {
          label: <a href="https://www.aliyun.com">2nd menu item</a>,
          key: "1",
        },
        {
          type: "divider",
        },
        {
          label: "3rd menu item",
          key: "3",
        },
      ]}
    />
  );

  return (
    <>
      {/* <IdleTimerContainer
				handleLogout={handleLogout}
				timeout={isTimedout}
				timeoutModal={handleLogoutModal}
				timedoutHandler={(bool) => {
					setIsTimedout(bool);
				}}
			/> */}
      <Layout
        style={{
          height: '100%',
        }}
        className="site-layout"
      >
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={"site-layout-background"}
          style={{
            overflow: 'auto',
            height: '100vh',
          }}
        >
          <div
            className={`logo ${
              collapsed
                ? "my-1 mx-4 justify-center"
                : "my-1 mx-4 justify-center"
            }`}
          >
            <Image
              src={collapsed ? pgnLogoKecil : pgnLogo}
              preview={false}
              className="p-1.5"
            />
          </div>
          <SideMenu isCollapsed={collapsed}/>
        </Sider>
        <Layout className="site-layout2">
          <Header
            className="site-layout-background2"
            style={{
              padding: 0,
            }}
          >
            <div className="flex w-full h-full justify-between">
              <div>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                    style: { fontSize: "24px", color: "#FFFFFF" },
                  }
                )}
              </div>
              <div className="flex justify-end items-center align-middle gap-x-5 mr-5">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a onClick={(e) => e.preventDefault()} className="pt-2.5">
                    <SettingOutlined
                      style={{
                        fontSize: "24px",
                        color: "#FFFFFF",
                      }}
                    />
                  </a>
                </Dropdown>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Avatar size={"middle"} icon={<UserOutlined />} />
                  </a>
                </Dropdown>
                <IconArrowNarrowLeft
                  name={"IconArrowNarrowLeft"}
                  style={{ fontSize: "24px" }}
                  className="flex items-center text-white hover:text-white"
                  onClick={() => {
                    handleLogoutModal();
                  }}
                />
              </div>
            </div>
          </Header>
          <Content
            style={{
              margin: "20px 20px",
            }}
          >
            {children}
          </Content>
          <ModalAlert
            isOpen={openModal}
            handleCancel={handleCancel}
            header={"LOGOUT"}
            handleOk={handleLogout}
            isAlert={true}
          >
            <div className={"flex flex-col px-8 py-4"}>
              <div className="w-full justify-center flex my-6">
                <span className={"text-xl text-[#3C6DB2]"}>
                  {" "}
                  Are you sure want to log out?
                </span>
              </div>
              <div className={"w-full justify-center flex"}>
                <ButtonComponent
                  type={"default"}
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </ButtonComponent>
                <ButtonComponent type={"submit"} onClick={handleLogout}>
                  Logout
                </ButtonComponent>
              </div>
            </div>
          </ModalAlert>
        </Layout>
      </Layout>
    </>
  );
};

export default LayoutMenu;
