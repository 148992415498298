import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userService from "../services/user";


const initialState = {
    data: [],
    loading: false,
    message: null,
}

export const getListUser = createAsyncThunk(
    "GET_LIST_USER",
    async(_, thunkAPI) => {
        try {
            const response = await userService.getUsers();
            // console.log(response, " = result get users slice")   
            return response.data;
        } catch (error) {
            console.log(error, '= error get user slice');
            return error;
        }
    });

export const getDetailUser = createAsyncThunk("GET_DETAIL_USER", async (id, thunkAPI) => { 
    try {
        const response = await userService.getDetailUser(id);
        return response.data;
    } catch (error) {
        console.log(error, '= error get user slice');
        return error;   
    }
});
export const createUser = createAsyncThunk("CREATE_USER", async (body) => {
    try {
        const response = await userService.createUser(body);
        console.log(response, ' = success user create slice')
        return response.data;
    } catch (error) {
        console.log(error, ' = error create user slice')
        return error;
    }
 });
export const updateUser = createAsyncThunk("UPDATE_USER", async (id, body) => {
    try {
        const response = await userService.updateUser(id, body);
        console.log(response, ' = success update user slice');
        return response.data;
    } catch (error) {
        console.log(error, " = error update slice")
        return error;
    }
 });
export const deleteUser = () => { };
export const inactiveUser = createAsyncThunk("INACTIVE_USER", async (id) => {
   try {
       const response = await userService.inactiveUser(id);
    //    console.log(response, ' = blabla');
       return response.data;
   } catch (error) {
       console.log(error, '= error inactive');
       return error;
   } 
});

export const donwloadedExcel = createAsyncThunk("DOWNLOAD_USER_EXCEL", async () => { 
    try {
        const response = await userService.downloadExcel();
        return response.data;
    } catch (error) {
        console.log(error, ' = error donwload slice')
        return error;
    }
});

export const getAllUserPaginate = createAsyncThunk("GET_ALL_USER_PAGINATE", async({page, pageSize}) => {
    try {
        const response = await userService.getAllUserPaginate(page, pageSize);
        // console.log(pageSize, ' = page slice');
        return response.data;
    } catch (error) {
        console.log(error, ' = error slice')
        return error;
    }
})

export const uploadUser = createAsyncThunk("UPLOAD_USER", async({ file }) => {
    try {
        const response = await userService.uploadUser(file);
        console.log(response, " = upload user slice")
    } catch (e) {
        console.log(e, ' = error upload slice');
        return e;
    }
})

const userSlice = createSlice({
    name : 'user',
    initialState,
    extraReducers: {
        // get user reducer
        [getListUser.pending] : (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        [getListUser.fulfilled] : (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [getListUser.rejected] : (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },

        // detail user reducers
        [getDetailUser.pending]: (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        [getDetailUser.fulfilled] : (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [getDetailUser.rejected] : (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        // create user reducer
        [createUser.pending] : (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        [createUser.fulfilled] : (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [createUser.rejected] : (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        // update user reducer
        [updateUser.pending] : (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        [updateUser.fulfilled] : (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [updateUser.rejected] : (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        // delete
        [deleteUser.pending] : (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        [deleteUser.fulfilled] : (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [deleteUser.rejected]: (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        // inactive
        [inactiveUser.pending] : (state) => {
            state.loading = true;
        },
        [inactiveUser.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [inactiveUser.rejected]: (state,action) => {
            state.data = action.payload;
            state.loading = true;
        },
        // download excel
        [donwloadedExcel.pending]: (state) => {
            state.loading = true;
        },
        [donwloadedExcel.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [donwloadedExcel.rejected]: (state) => {
            state.loading = true;
        },
        // get all user paginate
        [getAllUserPaginate.pending]: (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        [getAllUserPaginate.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [getAllUserPaginate.rejected]: (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        // upload user
        [uploadUser.pending]: (state, action) => {
            state.data = action.payload;
            state.loading = true;
        },
        [uploadUser.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [uploadUser.rejected]: (state, action) => {
            state.data = action.payload;
            state.loading = true;
        }
    }
})


const { reducer } = userSlice;
export default reducer;
