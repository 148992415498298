import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/BreadCrumb";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { useSelector, useDispatch } from "react-redux";
import BaseContainer from "../../../../components/BaseContainer";
import {
	Spin,
	Table,
	Checkbox,
	Tooltip,
	Input,
	Button,
	Pagination,
	Popover,
} from "antd";
import ButtonComponent from "../../../../components/ButtonComponent";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import InputComponent from "../../../../components/InputComponent";
import GridLayout from "../../../../components/GridLayout";
import {
	DownloadOutlined,
	DeleteOutlined,
	PlusOutlined,
	ArrowLeftOutlined,
	UnorderedListOutlined,
	EditOutlined,
	CloseCircleOutlined,
	LeftOutlined,
	MoreOutlined,
} from "@ant-design/icons";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import DetailText from "../../../../components/DetailText";
import StatusComponent from "../../../../components/StatusComponent";
import { dateFormat, tableNumbering } from "../../../../utils";
import DateComponent from "../../../../components/DateComponent";
import moment from "moment/moment";
import { SYSTEM_SETUP_ROUTES } from "../../../../routes/system_setup/setup_routes";
import InputGroupComponent from "../../../../components/InputGroupComponent";
import {
	createEntity,
	getDetailEntity,
	updateEntity,
} from "../../../../redux/slices/entity";
import SVGIcon from "../../../../assets/Icon/index";
import CardComponent from "../../../../components/Card/CardComponent";

const EntityForm = (props) => {
	const { type } = props;
	const { data, loading } = useSelector((state) => state.entity);
	const id = useLocation().state?.id;
	const datas = data?.data;

	const [modalRemoveTax, setModalRemoveTax] = useState(false);
	const [openTI, setOpenTI] = useState(false);
	const [openDetailTI, setOpenDetailTI] = useState(false);
	const [previewE, setPreviewE] = useState(false);
	const [add, setAdd] = useState(0);
	const [index, setIndex] = useState(0);

	const [entityName, setEntityName] = useState("");
	const [entityCode, setEntityCode] = useState("");
	const [address, setAddress] = useState("");
	const [fax, setFax] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [description, setDescription] = useState("");
	const [taxList, setTaxList] = useState([]);

	const [taxIdentifier, setTaxIdentifier] = useState("");
	const [remark, setRemark] = useState("");
	const [startDate, setStartDate] = useState(undefined);
	const [endDate, setEndDate] = useState(undefined);
	const [main, setMain] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (type === "update") {
			console.log("UPDATE " + id);
			dispatch(getDetailEntity(id));
		}
	}, []);

	useEffect(() => {
		if (type === "update") {
			setEntityName(datas?.entityName);
			setEntityCode(datas?.entityCode);
			setAddress(datas?.address);
			setFax(datas?.fax);
			setEmail(datas?.email);
			setPhone(datas?.phone);
			setDescription(datas?.description);
			setTaxList(datas?.taxIdentifierList);
		}
	}, [datas]);
	console.log(datas);
	const columnsTI = [
		{
			title: "NO",
			width: 60,
			align: "center",
			render: (text, object, index) => index + 1,
		},
		{
			title: "TAX IDENTIFIER",
			dataIndex: "taxNumber",
			align: "center",
			width: 100,
			// filterSearch: true,
			// filters: [
			// 	{ text: "Joe", value: "Joe" },
			// 	{ text: "Jim", value: "Jim" },
			// ],
			// filteredValue: filteredInfo.name || null,
			sorter: (a, b) => a.taxNumber.length - b.taxNumber.length,
		},
		{
			title: "DESCRIPTION",
			dataIndex: "remark",
			align: "center",
			width: 100,
		},
		{
			title: "START DATE",
			dataIndex: "startDate",
			align: "center",
			width: 100,
			render: (v) => (v ? moment(v).format(dateFormat) : v),
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
			align: "center",
			width: 100,
			render: (v) => (v ? moment(v).format(dateFormat) : v),
		},
		{
			title: "STATUS",
			dataIndex: "main",
			align: "center",
			width: 100,
			render: (v) => {
				if (v) {
					return <StatusComponent color={"approved"}>Primary</StatusComponent>;
				} else {
					return "-";
				}
			},
		},
		{
			title: "ACTION",
			width: 60,
			render: (v, r) => {
				return (
					<div className="flex justify-center align-middle">
						<Popover
							content={
								<div>
									<ButtonComponent
										icon={<SVGIcon name="IconDetail" width={24} />}
										border={false}
										onClick={() => handleDetailTax(r)}
									>
										<span className={"text-black"}> Detail</span>
									</ButtonComponent>

									<ButtonComponent
										icon={<EditOutlined style={{ fontSize: "22px" }} />}
										border={false}
										onClick={() => handleEditTax(r)}
									>
										<span className={"text-black"}> Edit</span>
									</ButtonComponent>
								</div>
							}
							trigger={"click"}
							placement="bottomRight"
						>
							<ButtonComponent icon={<MoreOutlined />} border={false} />
						</Popover>
						<Tooltip title="Delete">
							<ButtonComponent
								border={false}
								onClick={() => handleRemoveTax(r)}
								icon={<SVGIcon name="IconDelete" width={24} />}
							></ButtonComponent>
						</Tooltip>
					</div>
				);
			},
		},
	];
	const columnsPTI = [
		{
			title: "NO",
			width: 60,
			align: "center",
			render: (text, object, index) => index + 1,
		},
		{
			title: "TAX IDENTIFIER",
			dataIndex: "taxNumber",
			align: "center",
			width: 100,
			// filterSearch: true,
			// filters: [
			// 	{ text: "Joe", value: "Joe" },
			// 	{ text: "Jim", value: "Jim" },
			// ],
			// filteredValue: filteredInfo.name || null,
			sorter: (a, b) => a.taxNumber.length - b.taxNumber.length,
		},
		{
			title: "DESCRIPTION",
			dataIndex: "remark",
			align: "center",
			width: 100,
		},
		{
			title: "START DATE",
			dataIndex: "startDate",
			align: "center",
			width: 100,
			render: (v) => (v ? moment(v).format(dateFormat) : v),
		},
		{
			title: "END DATE",
			dataIndex: "endDate",
			align: "center",
			width: 100,
			render: (v) => (v ? moment(v).format(dateFormat) : v),
		},
		{
			title: "STATUS",
			dataIndex: "main",
			align: "center",
			width: 100,
			render: (v) => {
				if (v) {
					return <StatusComponent color={"approved"}>Primary</StatusComponent>;
				} else {
					return "-";
				}
			},
		},
	];
	const previewEntity = () => {
		setPreviewE(true);
	};
	const handleClearEntity = () => {
		setEntityName("");
		setEntityCode("");
		setAddress("");
		setPhone("");
		setFax("");
		setEmail("");
		setTaxList([]);
	};
	const handleClearTI = () => {
		setTaxIdentifier("");
		setRemark("");
		setStartDate("");
		setEndDate("");
		setMain(false);
	};
	const handleAddTax = () => {
		handleClearTI();
		setOpenTI(true);
		setAdd(1);
	};
	const handleSaveTax = () => {
		if (taxList) {
			let keyNumber = taxList.length - 1;
			const ti = {
				key: keyNumber,
				taxNumber: taxIdentifier,
				remark: remark,
				startDate: moment(startDate).format(),
				endDate: moment(endDate).format(),
				main: main,
			};
			taxList.push(ti);

			if (main) {
				taxList.map((e) => {
					if (e.key !== keyNumber) e.main = false;
				});
			}
			console.log({ ti: taxList });
			setOpenTI(false);
		}
	};
	const handleSaveEditTax = () => {
		const newRecord = {
			taxNumber: taxIdentifier,
			remark: remark,
			startDate: moment(startDate).format(),
			endDate: moment(endDate).format(),
			main: main,
		};
		console.log(newRecord);
		setTaxList((pre) => {
			return pre.map((d, i) => {
				if (i === index) {
					return newRecord;
				} else {
					return d;
				}
			});
		});
		setOpenTI(false);
	};
	const handleSaveEntity = async () => {
		taxList.map((e) => {
			delete e.key;
		});
		try {
			// await alert('blabla')
			if (type === "update") {
				const body = {
					entityId: id,
					entityName: entityName,
					entityCode: entityCode,
					addres: address,
					email: email,
					description: description,
					phone: phone,
					// taxList: taxList,
				};
				await dispatch(updateEntity(body));
			} else {
				const body = {
					entityName: entityName,
					entityCode: entityCode,
					addres: address,
					email: email,
					description: description,
					phone: phone,
					taxList: taxList,
				};
				dispatch(createEntity(body));
				navigate(SYSTEM_SETUP_ROUTES.VIEW_ENTITY);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleRemoveTax = (record) => {
		const x = taxList.filter((e) => e !== record);
		setTaxList(x);
	};
	const handleDetailTax = (r) => {
		setTaxIdentifier(r.taxNumber);
		setRemark(r.remark);
		setMain(r.main);
		setStartDate(r.startDate);
		setEndDate(r.endDate);
		setOpenDetailTI(true);
	};
	const handleEditTax = (r) => {
		setTaxIdentifier(r.taxNumber);
		setRemark(r.remark);
		setMain(r.main);
		setStartDate(r.startDate);
		setEndDate(r.endDate);
		setOpenTI(true);
		setAdd(0);
		const index = taxList.findIndex((e) => e === r);
		setIndex(index);
	};

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const handleChange = (page) => {
		setPage(page);
	};
	const handleChangeSize = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	};

	const routes = [
		{
			path: "",
			breadcrumbName: "System Setup",
		},
		{
			path: SYSTEM_SETUP_ROUTES.VIEW_ENTITY,
			breadcrumbName: "Entity",
		},
		{
			path: "",
			breadcrumbName: type === "update" ? "Update Entity" : "Create Entity",
		},
	];
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb routes={routes} />
				<BaseContainer header={"ENTITY INFORMATION"}>
					<div className="flex flex-col w-full gap-10">
						<GridLayout cols={3}>
							<InputComponent
								type="text"
								label={"Entity Name"}
								mandatory
								value={entityName}
								onChange={(e) => setEntityName(e.target.value)}
							></InputComponent>
							<InputComponent
								type="text"
								label={"Entity Code"}
								mandatory
								value={entityCode}
								onChange={(e) => setEntityCode(e.target.value)}
							></InputComponent>
							<InputComponent
								type="text"
								label={"Entity Email"}
								value={email}
								mandatory
								onChange={(e) => setEmail(e.target.value)}
							></InputComponent>
							<InputComponent
								type="text"
								label={"Address"}
								value={address}
								mandatory
								onChange={(e) => setAddress(e.target.value)}
							></InputComponent>
							<InputGroupComponent label={"Phone Number"} mandatory>
								<div
									style={{ width: "10%" }}
									className="bg-[#D9D9D9] bg-opacity-50 p-1"
								>
									+62
								</div>
								<InputComponent
									type="text"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								></InputComponent>
							</InputGroupComponent>
							<InputComponent
								type="text"
								value={fax}
								label={"Fax Number"}
								onChange={(e) => setFax(e.target.value)}
							></InputComponent>
							<div className="col-span-3">
								<InputComponent
									type="textarea"
									value={description}
									label={"Description"}
									onChange={(e) => setDescription(e.target.value)}
								></InputComponent>
							</div>
						</GridLayout>
					</div>
				</BaseContainer>

				<div className="flex justify-end">
					<ButtonComponent
						icon={<PlusOutlined style={{ fontSize: "24px" }} />}
						type="submit"
						onClick={() => handleAddTax()}
					>
						Create
					</ButtonComponent>
				</div>
				<BaseContainer header={"TAX IDENTIFIER"}>
					<div className="w-full">
						<div className={"flex justify-end mb-5"}>
							<Pagination
								total={taxList ? taxList.length : 0}
								className={"pr-1"}
								showSizeChanger
								current={page}
								pageSize={pageSize}
								onChange={handleChange}
								onShowSizeChange={handleChangeSize}
								showTotal={(total, range) =>
									`Showing ${range[0]} to ${range[1]} of ${total} records`
								}
							/>
						</div>
						<Table
							dataSource={taxList ? [...taxList] : []}
							columns={columnsTI}
							size="small"
							scroll={{ x: 0 }}
							pagination={false}
						/>
					</div>
				</BaseContainer>

				<div className="flex justify-between">
					<ButtonComponent
						type={"submit"}
						onClick={() => navigate(-1)}
						icon={
							<LeftOutlined
								style={{
									color: "#fff",
									fontSize: 16,
									justifyItems: "center",
								}}
							></LeftOutlined>
						}
					>
						Back
					</ButtonComponent>
					<div className="flex justify-end gap-x-2">
						<ButtonComponent
							icon={<SVGIcon name="IconButtonClear" width={24} />}
							onClick={() => handleClearEntity()}
							type="submit"
						>
							Clear
						</ButtonComponent>
						<ButtonComponent
							// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
							onClick={() => previewEntity()}
							type="submit"
						>
							Save
						</ButtonComponent>
					</div>
				</div>
			</Spin>

			<ModalCustom
				width={800}
				isOpen={openTI}
				handleCancel={() => setOpenTI(false)}
				header={"CREATE TAX IDENTIFIER"}
			>
				<div className="grid grid-cols-2 gap-4 my-4">
					<div className="col-span-2">
						<InputComponent
							type="text"
							label={"Tax Identifier"}
							mandatory
							value={taxIdentifier}
							onChange={(e) => setTaxIdentifier(e.target.value)}
						></InputComponent>
					</div>
					<div className="col-span-1">
						<DateComponent
							label={"Start Date"}
							value={startDate ? moment(startDate) : undefined}
							onChange={(d, date) => setStartDate(date)}
							mandatory
						></DateComponent>
					</div>
					<div className="col-span-1">
						<DateComponent
							label={"End Date"}
							value={endDate ? moment(endDate) : undefined}
							onChange={(d, date) => setEndDate(date)}
							mandatory
						></DateComponent>
					</div>

					<div className="col-span-2">
						<InputComponent
							type="textarea"
							label={"Description"}
							value={remark}
							mandatory
							onChange={(e) => setRemark(e.target.value)}
						></InputComponent>
					</div>

					<Checkbox onChange={(e) => setMain(e.target.checked)} checked={main}>
						Main Tax Identifier
					</Checkbox>
				</div>

				<div className="flex justify-end gap-x-2 px-4">
					<ButtonComponent
						icon={<SVGIcon name="IconButtonClear" width={24} />}
						onClick={() => handleClearTI()}
						type="submit"
					>
						Clear
					</ButtonComponent>
					<ButtonComponent
						// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
						onClick={() => (add === 1 ? handleSaveTax() : handleSaveEditTax())}
						type="submit"
					>
						Save
					</ButtonComponent>
				</div>
			</ModalCustom>

			<ModalCustom
				width={800}
				isOpen={openDetailTI}
				handleCancel={() => setOpenDetailTI(false)}
				header={"DETAIL TAX IDENTIFIER"}
			>
				<CardComponent header={"TAX IDENTIFIER INFORMATION"} cols={3}>
					<DetailText label={"Tax Identifier"}>{taxIdentifier}</DetailText>
					<DetailText label={"Status"}>{main ? "Primary" : "-"}</DetailText>
					<DetailText label={"Remark"}> {remark}</DetailText>
					<DetailText label={"Start Date"}>
						{startDate ? moment(startDate).format(dateFormat) : "-"}
					</DetailText>
					<DetailText label={"End Date"}>
						{endDate ? moment(endDate).format(dateFormat) : "-"}
					</DetailText>
				</CardComponent>

				<CardComponent header={"HISTORY LOG INFORMATION"} cols={4}>
					<DetailText label={"Created At"}>{"-"}</DetailText>
					<DetailText label={"Created By"}>{"-"}</DetailText>
					<DetailText label={"Latest Update"}>{"-"}</DetailText>
					<DetailText label={"Updated By"}>{"-"}</DetailText>
				</CardComponent>
				<div className="flex justify-end">
					<ButtonComponent onClick={() => setOpenDetailTI(false)} type="submit">
						Back
					</ButtonComponent>
				</div>
			</ModalCustom>

			<ModalCustom
				headerStyle={"confirm"}
				isOpen={previewE}
				handleCancel={() => setPreviewE(false)}
				header={"CONFIRMATION"}
				width={1000}
			>
				<div className="flex flex-col w-full gap-y-10">
					<div className="p-4 m-4">
						<div className="text-primary">ENTITY INFORMATION</div>
						<div className="grid grid-cols-3 gap-y-2.5 p-4">
							<DetailText label={"Entity Name"}>{entityName}</DetailText>
							<DetailText label={"Entity Code"}>{entityCode}</DetailText>
							<DetailText label={"Entity Email"}>{email}</DetailText>
							<DetailText label={"Phone Number"}>{phone}</DetailText>
							<DetailText label={"Fax Number"}>{fax}</DetailText>
							<DetailText label={"Address"}>{address}</DetailText>
							<div className="col-span-3">
								<DetailText label={"Description"}>{description}</DetailText>
							</div>
						</div>

						<hr className="opacity-25"></hr>
						<div className={"w-full flex mb-5 gap-2 justify-end"}>
							<Pagination
								total={taxList ? taxList.length : 0}
								className={"pr-1"}
								showSizeChanger
								current={page}
								pageSize={pageSize}
								onChange={handleChange}
								onShowSizeChange={handleChangeSize}
								showTotal={(total, range) =>
									`Showing ${range[0]} to ${range[1]} of ${total} records`
								}
							/>
						</div>
						<Table
							scroll={{
								x: 1300,
							}}
							dataSource={taxList ? [...taxList] : []}
							columns={columnsPTI}
							size="small"
							pagination={false}
						/>
					</div>
				</div>

				<div className="flex justify-end gap-x-2 p-4">
					<ButtonComponent onClick={() => setPreviewE(false)} type="default">
						Cancel
					</ButtonComponent>
					<ButtonComponent
						// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
						onClick={() => handleSaveEntity()}
						type="submit"
					>
						Confirm
					</ButtonComponent>
				</div>
			</ModalCustom>
		</LayoutMenu>
	);
};

export default EntityForm;
