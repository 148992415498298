import {
	CloseCircleOutlined,
	DownloadOutlined,
	PlusOutlined,
	UnorderedListOutlined,
} from "@ant-design/icons";
import { Checkbox, Spin, Table, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import ButtonComponent from "../../../../components/ButtonComponent";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import StatusComponent from "../../../../components/StatusComponent";
import SVGIcon from "../../../../assets/Icon/index";
import { useState } from "react";
import ModalCustom from "../../../../components/Modal/ModalCustom";

const GlobalProperties = () => {
	const [modalInactive, setModalInactive] = useState(false);
	const gpList = [
		{
			globalPropertiesName: "URL E-Faktur",
			type: "INTEGRATION",
			description: "Config API E-Faktur",
			status: "ACTIVE",
		},
	];
	const columns = [
		// tableNumbering(page),
		{
			title: "GLOBAL PROPERTIES NAME",
			dataIndex: "globalPropertiesName",
			width: 160,
		},
		{
			title: "TYPE",
			dataIndex: "type",
			width: 100,
		},
		{
			title: "DESCRIPTION",
			dataIndex: "description",
			width: 160,
		},
		{
			title: "ACTIVATE",
			dataIndex: "",
			width: 160,
			render: () => {
				return (
					<div className="flex justify-center">
						<Checkbox
						// onChange={onChange}
						></Checkbox>
					</div>
				);
			},
		},
		{
			title: "STATUS",
			dataIndex: "status",
			width: 160,
			render: (status) => (
				<div>
					{status === "ACTIVE" && (
						<StatusComponent color="green">{status}</StatusComponent>
					)}

					{status === "INACTIVE" && (
						<StatusComponent color="red">{status}</StatusComponent>
					)}
				</div>
			),
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 100,
			render: () => {
				return (
					<div className="flex justify-center gap-4">
						<NavLink
							to={"/app/system-setup/global-properties/detail"}
							// state={{ entityName: "Entity 1" }}
						>
							<Tooltip title="Detail">
								<UnorderedListOutlined
									style={{
										color: "#0880AE",
										fontSize: 24,
										justifyItems: "center",
									}}
								/>
							</Tooltip>
						</NavLink>
						<NavLink to={"/app/system-setup/global-properties/edit"}>
							<Tooltip title="Edit">
								<div>
									<SVGIcon
										name="IconEdit"
										width={24}
										style={{
											color: "#0880AE",
											fontSize: 24,
											justifyItems: "center",
										}}
									/>
								</div>
							</Tooltip>
						</NavLink>
						<Tooltip title="Delete">
							<CloseCircleOutlined
								onClick={() => {
									setModalInactive(true);
								}}
								style={{
									color: "#FF7171",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb pageName={["System Setup", "Global Properties"]} />

				<BaseContainer header={"GLOBAL PROPERTIES LIST"}>
					<div className={"w-full"}>
						<div className="flex w-full justify-end">
							<ButtonComponent
								icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
								type="submit"
							>
								Download List
							</ButtonComponent>
							<NavLink
								to={"/app/system-setup/global-properties/create"}
								state={{ x: 1 }}
							>
								<ButtonComponent
									icon={<PlusOutlined style={{ fontSize: "24px" }} />}
									type="submit"
								>
									Create New
								</ButtonComponent>
							</NavLink>
						</div>
						<Table
							dataSource={gpList}
							columns={columns}
							size="small"
							pagination={{ position: ["bottomCenter"] }}
						/>
					</div>
				</BaseContainer>
			</Spin>

			<ModalCustom
				isOpen={modalInactive}
				handleCancel={() => setModalInactive(false)}
				header={"INACTIVE GLOBAL PROPERTIES"}
				width={800}
			>
				<div className="flex flex-col w-full">
					<h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
						Are you sure you want to inactive Global Properties?
					</h1>

					<div className="flex mt-4 w-full justify-center">
						<ButtonComponent
							// icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
							type={"default"}
							onClick={() => setModalInactive(false)}
						>
							Cancel
						</ButtonComponent>
						<ButtonComponent
							// onClick={() => setModalConfirmation(true)}
							type={"submit"}
						>
							Submit
						</ButtonComponent>
					</div>
				</div>
			</ModalCustom>
		</LayoutMenu>
	);
};

export default GlobalProperties;
