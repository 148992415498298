import Dashboard from "../app/pages/dashboard";
import { user_management } from "./user_management";
import { system_setup } from "./system_setup";
import Position from "../app/pages/Authentication/Position";
import { product_promo } from "./product_promo";

// console.log(user_management, " dsad");
export const index = [
	{
		path: "/dashboard",
		element: <Dashboard />,
	},
	{
		path: "/position",
		element: <Position />,
	},
	...product_promo,
	...user_management,
	...system_setup,
];
