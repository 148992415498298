import { Modal } from "antd";
import React from "react";
import { ExclamationCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import ButtonComponent from "../ButtonComponent";


const ModalConfirm = (props) => {
    const { isOpen, handleCancel = () => { }, handleOk = () => { }, children, type} = props;
    return (
        <Modal open={isOpen} onOk={handleOk} onCancel={handleCancel} footer={false} className={'modal-custom'} centered={true}>
            <div className={'flex flex-col w-full'}>
                {/* content section */}
                {children}
                {/* <div className={"w-full justify-center my-4 flex text-sm"}>
                    <Alert
                        message={activate === true ?
                            'If you activate this data, it will be inactivate again.'
                            : 'If you inactive this data, it will be activate again.'}
                        type={activate === true ? 'error' : 'success'}
                        icon={<WarningOutlined />}
                        showIcon />
                </div> */}
                <div className={"w-full justify-end flex gap-2 pr-8 pb-6"}>
                    <ButtonComponent
                        type={"default"}
                        onClick={handleCancel}
                    >
                        Back
                    </ButtonComponent>
                    <ButtonComponent type={"submit"} onClick={handleOk}>
                        Yes
                    </ButtonComponent>
                </div>
            </div>
        </Modal>
    );
}
const ModalAlert = (props) => {
    const { isOpen, handleCancel = () => { }, handleOk = () => { }, header, children, isSuccess } = props;
}
const ModalError = (props) => {
	const { isOpen, handleCancel=()=>{}, handleOk=()=>{}, header, children} = props

    return (
        <Modal open={isOpen} onOk={handleOk} onCancel={handleCancel} footer={false} className={'modal-custom'} centered={true}>
            <div className={'flex flex-col w-full'}>
                {/* content section */}
                {children}
            </div>
        </Modal>
    );
};

const ModalSuccess = (props) => {
  const { isOpen, handleCancel=()=>{}, handleOk=()=>{}, header, children} = props
    return (
        <Modal open={isOpen} onOk={handleOk} onCancel={handleCancel} footer={false} className={'modal-custom'} centered={true}>
            <div className={'flex flex-col w-full'}>
                {/* content section */}
                {children}
            </div>
        </Modal>
    );
}
export { ModalError, ModalSuccess, ModalConfirm};
