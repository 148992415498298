import { ArrowLeftOutlined, DeleteOutlined, DownloadOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Image, message, Upload } from 'antd';
import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { failedUpload, successUpload } from '../../../../assets/img';
import BaseContainer from '../../../../components/BaseContainer';
import BreadCrumb from '../../../../components/BreadCrumb';
import ButtonComponent from '../../../../components/ButtonComponent';
import ModalCustom from '../../../../components/Modal/ModalCustom';
import LayoutMenu from '../../../../components/SidebarMenu/LayoutMenu';

const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: true,
    accept:".xlsx",
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    // action: configApp.USER_MANAGEMENT_SERVICE + '/v1/dbs/api/mu/uploadExcel',
    onChange(info) {
        const { status } = info.file;
        console.log(info.file, ' = info.file')
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            console.log(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};
const UploadUser = () => {
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false);


    const handleCancel =()=>{
        setOpenModal(false)
    }
    return (
        <LayoutMenu>
            <BreadCrumb pageName={['User Management', 'User', 'Upload']} />
            <NavLink onClick={() => navigate(-1)}>
                <div className="flex align-middle gap-x-2 mb-4">
                    <ArrowLeftOutlined
                        style={{ fontSize: "24px", color: "#3C6DB2" }}
                    ></ArrowLeftOutlined>
                    <span className="text-dg-blue">Back</span>
                </div>
            </NavLink>
            <BaseContainer header={'UPLOAD USER'}>
                <div className={'w-full flex flex-col gap-4'}>
                    <div className={'w-full flex justify-center'}>
                        <ButtonComponent  icon={<DownloadOutlined />}> Download</ButtonComponent>
                        <ButtonComponent  icon={<UploadOutlined />}> Upload</ButtonComponent>
                    </div>
                    <div className={'w-full flex justify-center'}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </Dragger>
                    </div>
                    <div className={'w-full flex justify-end'}>
                        <ButtonComponent icon={<DeleteOutlined/>}>Clear</ButtonComponent>
                        <ButtonComponent type={'submit'} onClick={()=>setOpenModal(true) }>Preview</ButtonComponent>
                    </div>
                </div>
            </BaseContainer>
            {/* Modal */}
            <ModalCustom header={"UPLOAD USER"} isOpen={openModal}  width={650} handleCancel={handleCancel}>
                <div className={'w-full flex flex-col items-center'}>
                    <Image src={successUpload} preview={false} width={320} />
                    {/* <Image src={failedUpload} preview={false} width={320} />
                     */}
                    <span className={'text-green-500 text-xl mt-5'}>Success to validating data</span>
                    {/* <span className={'text-red-500 text-xl mt-5'}>Failed to validating data</span> */}
                    <NavLink onClick={()=> navigate(-1)} className={'mt-5'}>
                        <ButtonComponent type={'submit'}>Go Back</ButtonComponent>
                    </NavLink>
                </div>

            </ModalCustom>
        </LayoutMenu>
    );
}

export default UploadUser;
