import Action from "../../app/pages/SystemSetup/Action/Action";
import ActionCreate from "../../app/pages/SystemSetup/Action/ActionCreate";
import ActionEdit from "../../app/pages/SystemSetup/Action/ActionEdit";
import ApprovalHierarchyForm from "../../app/pages/SystemSetup/ApprovalHierarchy/ApprovalHierarchyForm";
import ApprovalHierarchyPage from "../../app/pages/SystemSetup/ApprovalHierarchy/ApprovalHierarchyPage";
import CreateGlobalType from "../../app/pages/SystemSetup/GlobalType/CreateGlobalType";
import DetailGlobalType from "../../app/pages/SystemSetup/GlobalType/DetailGlobalType";
import EditGlobalType from "../../app/pages/SystemSetup/GlobalType/EditGlobalType";
import ViewGlobalType from "../../app/pages/SystemSetup/GlobalType/ViewGlobalType";
import Menu from "../../app/pages/SystemSetup/Menu/Menu";
import MenuCreate from "../../app/pages/SystemSetup/Menu/MenuCreate";
import MenuUpdate from "../../app/pages/SystemSetup/Menu/MenuUpdate";
import Announcement from "../../app/pages/SystemSetup/Announcement/Announcement";
import GlobalProperties from "../../app/pages/SystemSetup/GlobalProperties/GlobalProperties";
import GlobalPropertiesDetail from "../../app/pages/SystemSetup/GlobalProperties/GlobalPropertiesDetail";
import GlobalPropertiesEdit from "../../app/pages/SystemSetup/GlobalProperties/GlobalPropertiesEdit";
import GlobalPropertiesCreate from "../../app/pages/SystemSetup/GlobalProperties/GlobalPropertiesCreate";
import EntityPage from "../../app/pages/SystemSetup/Entity/Entity";
import EntityDetail from "../../app/pages/SystemSetup/Entity/EntityDetail";
import EntityForm from "../../app/pages/SystemSetup/Entity/EntityForm";
import CostCenter from "../../app/pages/SystemSetup/MasterData/CostCenter/CostCenter";
import ViewHierarchy from "../../app/pages/SystemSetup/MasterData/CostCenter/ViewHierarchy";
import CreateHierarchy from "../../app/pages/SystemSetup/MasterData/CostCenter/CreateHierarchy";
import DetailCostCenter from "../../app/pages/SystemSetup/MasterData/CostCenter/DetailCostCenter";
import EditCostCenter from "../../app/pages/SystemSetup/MasterData/CostCenter/EditCostCenter";
import GroupAccess from "../../app/pages/SystemSetup/GroupAccess/GroupAccess";
import GroupAccessCreate from "../../app/pages/SystemSetup/GroupAccess/GroupAccessCreate";
import GroupAccessEdit from "../../app/pages/SystemSetup/GroupAccess/GroupAccessEdit";
import AnnouncementCreate from "../../app/pages/SystemSetup/Announcement/AnnouncementCreate";
import Job from "../../app/pages/SystemSetup/MasterData/Job/Job";
import JobCreate from "../../app/pages/SystemSetup/MasterData/Job/JobCreate";
import JobEdit from "../../app/pages/SystemSetup/MasterData/Job/JobEdit";

export const SYSTEM_SETUP_ELEMENTS = {
	// menu
	VIEW_MENU_PAGE: <Menu />,
	CREATE_MENU_PAGE: <MenuCreate />,
	UPDATE_MENU_PAGE: <MenuUpdate />,

	// global type
	VIEW_GLOBAL_PAGE: <ViewGlobalType />,
	CREATE_GLOBAL_PAGE: <CreateGlobalType />,
	DETAIL_GLOBAL_PAGE: <DetailGlobalType />,
	UPDATE_GLOBAL_PAGE: <EditGlobalType />,

	// action
	VIEW_ACTION_PAGE: <Action />,
	CREATE_ACTION_PAGE: <ActionCreate />,
	UPDATE_ACTION_PAGE: <ActionEdit />,

	// global properties
	VIEW_GLOBAL_PROPERTIES_PAGE: <GlobalProperties />,
	DETAIL_GLOBAL_PROPERTIES_PAGE: <GlobalPropertiesDetail />,
	UPDATE_GLOBAL_PROPERTIES_PAGE: <GlobalPropertiesEdit />,
	CREATE_GLOBAL_PROPERTIES_PAGE: <GlobalPropertiesCreate />,

	// entity
	VIEW_ENTITY_PAGE: <EntityPage />,
	DETAIL_ENTITY_PAGE: <EntityDetail />,
	UPDATE_ENTITY_PAGE: <EntityForm type="update" />,
	CREATE_ENTITY_PAGE: <EntityForm />,

	// cost center
	VIEW_COST_CENTER_PAGE: <CostCenter />,
	CREATE_HIERARCHY_PAGE: <CreateHierarchy />,
	VIEW_HIERARCHY_PAGE: <ViewHierarchy />,
	UPDATE_HIERARCHY_PAGE: <EditCostCenter />,
	DETAIL_HIERARCHY_PAGE: <DetailCostCenter />,

	// group access
	VIEW_GROUP_ACCESS_PAGE: <GroupAccess />,
	CREATE_GROUP_ACCESS_PAGE: <GroupAccessCreate />,
	UPDATE_GROUP_ACCESS_PAGE: <GroupAccessEdit />,

	// announcement
	VIEW_ANNOUNCEMENT_PAGE: <Announcement />,
	CREATE_ANNOUNCEMENT_PAGE: <AnnouncementCreate />,
	UPDATE_ANNOUNCEMENT_PAGE: <AnnouncementCreate type="update" />,

	// JOB
	VIEW_JOB_PAGE: <Job />,
	CREATE_JOB_PAGE: <JobCreate />,
	UPDATE_JOB_PAGE: <JobEdit />,
};
