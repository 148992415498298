import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const intialState = {};
export const getPosition = () => { };

const hierarchySlice = createSlice({
    name: 'HIERARCHY_SLICE',
    intialState,
    extraReducer: {}
});

const { reducer } = hierarchySlice;
export default reducer;

