import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, Input, Popover, Spin } from "antd";
import moment from "moment/moment";
import React from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BaseContainer from "../../../../components/BaseContainer";
import BreadCrumb from "../../../../components/BreadCrumb";
import ButtonComponent from "../../../../components/ButtonComponent";
import DateComponent from "../../../../components/DateComponent";
import DetailText from "../../../../components/DetailText";
import InputComponent from "../../../../components/InputComponent";
import ModalCustom from "../../../../components/Modal/ModalCustom";
import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { dateFormat } from "../../../../utils";

const DelegationEdit = () => {
  const navigate = useNavigate();
  const [reason, setReason] = useState("Sick");
  const [delegateTo, setDelegateTo] = useState("Raul");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [previewDelegation, setPreviewDelegation] = useState(false);

  const clearState = () => {
    setReason("");
    setDelegateTo("");
    setStartDate("");
    setEndDate("");
  };

  const saveDelegation = () => {
    const body = {
      reason: reason,
      delegateTo: delegateTo,
      startDate: startDate,
      endDate: endDate,
    };
    console.log(body);
  };

  const previewConfirm = () => {
    setPreviewDelegation(true);
  };

  const content = (
    <span className="text-[11px] font-bold  text-[#3C6DB2]">
      Please fill mandatory form
    </span>
  );

  return (
    <LayoutMenu>
      <Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
        <BreadCrumb
          pageName={["User Management", "Delegation", "Update Delegation"]}
        />
        <NavLink onClick={() => navigate(-1)}>
          <div className="flex align-middle gap-x-2 mb-4 mt-4">
            <ArrowLeftOutlined
              style={{ fontSize: "24px", color: "#3C6DB2" }}
            ></ArrowLeftOutlined>
            <span className="text-dg-blue">Back</span>
          </div>
        </NavLink>
        <BaseContainer header={"DELEGATION INFORMATION"}>
          <div className="flex flex-col w-full">
            <div className="grid grid-cols-3 w-full">
              <div className="flex flex-col col-span-1 gap-y-8">
                <InputComponent
                  className="mt-4"
                  type="text"
                  label={"Reason"}
                  mandatory
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                ></InputComponent>
                <InputComponent
                  type="text"
                  label={"Delegate To"}
                  mandatory
                  value={delegateTo}
                  onChange={(e) => setDelegateTo(e.target.value)}
                ></InputComponent>
                <DateComponent
                  mandatory
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                  label="Start Date"
                ></DateComponent>
                <DateComponent
                  mandatory
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  label="End Date"
                ></DateComponent>
              </div>
            </div>

            <div className="flex justify-end mt-20">
              <ButtonComponent
                icon={<DeleteOutlined style={{ fontSize: "16px" }} />}
                onClick={() => clearState()}
                type="default"
              >
                Clear
              </ButtonComponent>
              {reason && delegateTo ? (
                <ButtonComponent onClick={() => previewConfirm()} type="submit">
                  Save
                </ButtonComponent>
              ) : (
                <Popover placement="top" content={content} trigger="hover">
                  <div>
                    <ButtonComponent
                      disabled
                      onClick={() => previewConfirm()}
                      type="submit"
                    >
                      Save
                    </ButtonComponent>
                  </div>
                </Popover>
              )}
            </div>
          </div>
        </BaseContainer>
      </Spin>

      <ModalCustom
        isOpen={previewDelegation}
        handleCancel={() => setPreviewDelegation(false)}
        header={"CONFIRMATION"}
        width={1000}
      >
        <div className="flex flex-col w-full gap-y-10">
          <div className="grid grid-cols-2 gap-y-2.5">
            <DetailText label={"Reason"}>{reason}</DetailText>
            <DetailText label={"Start Date"}>
              {moment(startDate).format(dateFormat)}
            </DetailText>
            <DetailText label={"Delegate To"}>{delegateTo}</DetailText>
            <DetailText label={"End Date"}>
              {moment(endDate).format(dateFormat)}
            </DetailText>
          </div>
        </div>

        <div className="flex justify-end">
          <ButtonComponent
            onClick={() => setPreviewDelegation(false)}
            type="default"
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            // icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
            onClick={() => saveDelegation()}
            type="submit"
          >
            Submit
          </ButtonComponent>
        </div>
      </ModalCustom>
    </LayoutMenu>
  );
};

export default DelegationEdit;
