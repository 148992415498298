import axios from "axios";
import { configApp } from "../../constants/configApp";
import { tokenHeader } from "../../utils/tokenHeader";


const getMenus = async () => {
    try {
        const response = await axios.get(configApp.USER_MANAGEMENT_SERVICE + '/v1/dbs/api/ga/sidebar/', { headers: tokenHeader() })
        return response;
    } catch (error) {
        return error;
    }
}


const menusService = {
    getMenus
}

export default menusService;