import LayoutMenu from "../../../../components/SidebarMenu/LayoutMenu";
import { Spin, Table, Checkbox, Tooltip, Tree } from "antd";
import BreadCrumb from "../../../../components/BreadCrumb";
import BaseContainer from "../../../../components/BaseContainer";
import ButtonComponent from "../../../../components/ButtonComponent";
import {
	DownloadOutlined,
	PlusOutlined,
	UnorderedListOutlined,
	EditOutlined,
	CloseCircleOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import StatusComponent from "../../../../components/StatusComponent";
import SVGIcon from "../../../../assets/Icon/index";
import React, { useEffect, useState } from "react";
import DetailText from "../../../../components/DetailText";
import ModalCustom from "../../../../components/Modal/ModalCustom";

const GroupAccess = () => {
	const [modalInactive, setModalInactive] = useState(false);
	const [openDetailGA, setOpenDetailGA] = useState(false);
	const [detailRecord, setDetailRecord] = useState({});

	const groupAccessList = [
		{
			groupAccessName: "CM",
			remark: "Group Access untuk karyawan cm",
			status: "ACTIVE",
			latestUpdate: "28 Desember 2021",
			updatedBy: "Annisa",
			createdBy: "Putri Worabay",
			createdAt: "21 Desember 2021",
			menu: [
				{
					title: "Main Home",
					key: "0-0",
				},
				{
					title: "Account",
					key: "1-0",
					children: [
						{
							title: "Customer",
							key: "1-1",
						},
					],
				},
			],
		},
	];

	const columns = [
		// tableNumbering(page),
		{
			title: "GROUP ACCESS NAME",
			dataIndex: "groupAccessName",
			width: 100,
		},
		{
			title: "REMARK",
			dataIndex: "remark",
			width: 240,
		},
		{
			title: "ACTIVATE",
			dataIndex: "",
			width: 100,
			render: () => {
				return (
					<div className="flex justify-center">
						<Checkbox
						// onChange={onChange}
						></Checkbox>
					</div>
				);
			},
		},
		{
			title: "STATUS",
			dataIndex: "status",
			width: 100,
			render: (status) => (
				<div>
					{status === "ACTIVE" && (
						<StatusComponent color="green">{status}</StatusComponent>
					)}

					{status === "INACTIVE" && (
						<StatusComponent color="red">{status}</StatusComponent>
					)}
				</div>
			),
		},
		{
			title: "ACTIONS",
			align: "center",
			width: 100,
			render: (v, r, i) => {
				return (
					<div className="flex justify-center gap-4">
						<Tooltip title="Detail">
							<UnorderedListOutlined
								onClick={() => {
									openDetail(r);
								}}
								style={{
									color: "#0880AE",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
						<NavLink
							to={"/app/system-setup/group-access/edit"}
							state={{ id: v }}
						>
							<Tooltip title="Edit">
								<div>
									<SVGIcon
										name="IconEdit"
										width={24}
										style={{
											color: "#0880AE",
											fontSize: 24,
											justifyItems: "center",
										}}
									/>
								</div>
							</Tooltip>
						</NavLink>
						<Tooltip title="Delete">
							<CloseCircleOutlined
								onClick={() => {
									setModalInactive(true);
								}}
								style={{
									color: "#FF7171",
									fontSize: 24,
									justifyItems: "center",
								}}
							/>
						</Tooltip>
					</div>
				);
			},
			key: "action",
		},
	];
	const openDetail = (r) => {
		setDetailRecord(r);
		setOpenDetailGA(true);
	};
	return (
		<LayoutMenu>
			<Spin spinning={false} className={"w-full top-20"} tip={"Loading..."}>
				<BreadCrumb
					pageName={["System Setup", "Group Access", "View Group Access"]}
				/>
				<BaseContainer header={"GROUP ACCESS LIST"}>
					<div className={"w-full"}>
						<div className="flex w-full justify-end">
							<ButtonComponent
								icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
								type="submit"
							>
								Download List
							</ButtonComponent>
							<NavLink
								to={"/app/system-setup/group-access/create"}
								state={{ x: 1 }}
							>
								<ButtonComponent
									icon={<PlusOutlined style={{ fontSize: "24px" }} />}
									type="submit"
								>
									Create New
								</ButtonComponent>
							</NavLink>
						</div>
						<Table
							dataSource={groupAccessList}
							columns={columns}
							size="small"
							pagination={{ position: ["bottomCenter"] }}
						/>
					</div>
				</BaseContainer>
			</Spin>

			{detailRecord && (
				<ModalCustom
					isOpen={openDetailGA}
					width={1000}
					handleCancel={() => setOpenDetailGA(false)}
					header={"DETAIL GROUP ACCESS"}
				>
					<div className="grid grid-cols-2 gap-y-2.5">
						<DetailText label={"Name"}>
							{detailRecord.groupAccessName}
						</DetailText>
						<DetailText label={"Latest Update"}>
							{detailRecord.latestUpdate}
						</DetailText>
						<DetailText label={"Status"}>{detailRecord.status}</DetailText>
						<DetailText label={"Updated By"}>
							{detailRecord.updatedBy}
						</DetailText>
						<DetailText label={"Remark"}>{detailRecord.remark}</DetailText>
						<DetailText label={"Created By"}>
							{detailRecord.createdBy}
						</DetailText>
						<div></div>
						<DetailText label={"Created At"}>
							{detailRecord.createdAt}
						</DetailText>
					</div>
					<div className="flex w-full justify-between mt-16 mb-4">
						<p className="text-dg-blue text-sm font-bold">Menu Access</p>
					</div>
					<Tree
						// checkable
						defaultExpandedKeys={["0-0-0", "0-0-1"]}
						// defaultSelectedKeys={['0-0-0', '0-0-1']}
						// defaultCheckedKeys={['0-0-0', '0-0-1']}
						// onSelect={() => console.log("ON SELECT")}
						// onCheck={() => console.log("ON CHECK")}
						treeData={detailRecord.menu}
					/>

					<div className="flex justify-end">
						<ButtonComponent
							// icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
							onClick={() => setOpenDetailGA(false)}
							type="submit"
						>
							Close
						</ButtonComponent>
					</div>
				</ModalCustom>
			)}

			<ModalCustom
				isOpen={modalInactive}
				handleCancel={() => setModalInactive(false)}
				header={"INACTIVE GROUP ACCESS"}
				width={800}
			>
				<div className="flex flex-col w-full">
					<h1 className="text-center text-[20px] text-[#3C6DB2] font-medium">
						Are you sure you want to inactive Group Access?
					</h1>

					<div className="flex mt-4 w-full justify-center">
						<ButtonComponent
							// icon={<DeleteOutlined style={{ fontSize: "24px" }} />}
							type={"default"}
							onClick={() => setModalInactive(false)}
						>
							Cancel
						</ButtonComponent>
						<ButtonComponent
							// onClick={() => setModalConfirmation(true)}
							type={"submit"}
						>
							Submit
						</ButtonComponent>
					</div>
				</div>
			</ModalCustom>
		</LayoutMenu>
	);
};

export default GroupAccess;
